import {
    CustomerResource,
    Token,
    tokenKey,
    transformToCustomer,
} from '../../entities/Api/Webshop';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { authorizedSyliusFetch } from '../../entities/Sylius/SyliusService';
import { ReduxFetchAction } from '../defaults';
import { setCustomer, setError, setIsLoading } from './customerSlice';

export const fetchCustomer: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setCustomer(undefined));

    try {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
        const tokenData = storedToken
            ? JSON.parse(storedToken) as Token
            : undefined;

        const customerId = tokenData?.customerId || '';

        const customerResponse = await authorizedSyliusFetch<CustomerResource>(`/shop/customers/${customerId}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const customer = transformToCustomer(customerResponse.data);

        dispatch(setCustomer(customer));
    } catch (error) {
        console.error('[fetchCustomer]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
