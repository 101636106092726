import { FC, ReactElement } from 'react';

import { Block } from '../../../entities/Block/Block';
import { BlockRenderer } from '../..';

import './CustomerServiceDeliveryPageRenderer.scss';

interface CustomerServiceDeliveryPageRendererProps {
    isLoading: boolean;
    blocks: Block[];
    className?: string;
}

export const CustomerServiceDeliveryPageRenderer: FC<CustomerServiceDeliveryPageRendererProps> = ({
    isLoading,
    blocks,
    className = '',
}): ReactElement => (
    <div className={`customer-service-delivery-page-renderer ${className}`}>
        <BlockRenderer
            isLoading={isLoading}
            blocks={blocks}
            className="customer-service-delivery-page-renderer__block-renderer"
        />
    </div>
);
