/* eslint-disable max-len */
import { combineSlices } from '@reduxjs/toolkit';

// Blog
import blogCategoriesSlice, { BlogCategoriesState } from './@blog/blogCategories/blogCategoriesSlice';
import blogOverviewsSlice, { BlogOverviewsState } from './@blog/blogOverviews/blogOverviewsSlice';
import blogPostSlice, { BlogPostState } from './@blog/blogPost/blogPostSlice';
// Forms
import newsletterFormSlice, { NewsletterFormState } from './@forms/newsletterForm/newsletterFormSlice';
// Pages
import customerServiceAccountPageSlice, { CustomerServiceAccountPageState } from './@pages/customerServiceAccountPage/customerServiceAccountPageSlice';
import customerServiceDeliveryPageSlice, { CustomerServiceDeliveryPageState } from './@pages/customerServiceDeliveryPage/customerServiceDeliveryPageSlice';
import customerServiceFaqPageSlice, { CustomerServiceFaqPageState } from './@pages/customerServiceFaqPage/customerServiceFaqPageSlice';
import customerServiceOrderingPageSlice, { CustomerServiceOrderingPageState } from './@pages/customerServiceOrderingPage/customerServiceOrderingPageSlice';
import customerServicePageSlice, { CustomerServicePageState } from './@pages/customerServicePage/customerServicePageSlice';
import customerServiceReturnsPageSlice, { CustomerServiceReturnsPageState } from './@pages/customerServiceReturnsPage/customerServiceReturnsPageSlice';
import customerServiceTermsPageSlice, { CustomerServiceTermsPageState } from './@pages/customerServiceTermsPage/customerServiceTermsPageSlice';
// Other
import appSlice, { AppState } from './app/appSlice';
import authenticationSlice, { AuthenticationState } from './authentication/authenticationSlice';
import changePasswordSlice, { ChangePasswordState } from './changePassword/changePasswordSlice';
import customerSlice, { CustomerState } from './customer/customerSlice';
import faqSlice, { FaqState } from './faq/faqSlice';
import registrationSlice, { RegistrationState } from './registration/registrationSlice';
import resetPasswordSlice, { ResetPasswordState } from './resetPassword/resetPasswordSlice';
import storeLocatorSlice, { StoreLocatorState } from './storeLocator/storeLocatorSlice';
import toastSlice, { ToastState } from './toast/toastSlice';

export interface Slices {
    // Blog
    blogCategoriesSlice: BlogCategoriesState;
    blogOverviewsSlice: BlogOverviewsState;
    blogPostSlice: BlogPostState;
    // Forms
    newsletterFormSlice: NewsletterFormState;
    // Pages
    customerServiceAccountPageSlice: CustomerServiceAccountPageState;
    customerServiceDeliveryPageSlice: CustomerServiceDeliveryPageState;
    customerServiceFaqPageSlice: CustomerServiceFaqPageState;
    customerServiceOrderingPageSlice: CustomerServiceOrderingPageState;
    customerServicePageSlice: CustomerServicePageState;
    customerServiceReturnsPageSlice: CustomerServiceReturnsPageState;
    customerServiceTermsPageSlice: CustomerServiceTermsPageState;
    // Account/customer
    authenticationSlice: AuthenticationState;
    changePasswordSlice: ChangePasswordState;
    customerSlice: CustomerState;
    // Other
    appSlice: AppState;
    faqSlice: FaqState;
    registrationSlice: RegistrationState;
    resetPasswordSlice: ResetPasswordState;
    storeLocatorSlice: StoreLocatorState;
    toastSlice: ToastState;
}

export default combineSlices(
    // Blog
    blogCategoriesSlice,
    blogOverviewsSlice,
    blogPostSlice,
    // Forms
    newsletterFormSlice,
    // Pages
    customerServiceAccountPageSlice,
    customerServiceDeliveryPageSlice,
    customerServiceFaqPageSlice,
    customerServiceOrderingPageSlice,
    customerServicePageSlice,
    customerServiceReturnsPageSlice,
    customerServiceTermsPageSlice,
    // Account/customer
    authenticationSlice,
    changePasswordSlice,
    customerSlice,
    registrationSlice,
    resetPasswordSlice,
    // Other
    appSlice,
    faqSlice,
    storeLocatorSlice,
    toastSlice,
);
