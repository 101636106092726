import { FC, ReactElement } from 'react';

import { Picture } from '../../components';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { LinkButton } from '../@buttons/LinkButton/LinkButton';

import './RegisterCallToAction.scss';

interface RegisterCallToActionProps {
    className?: string;
}

export const RegisterCallToAction: FC<RegisterCallToActionProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`register-call-to-action ${className}`}>
            <Picture
                className="register-call-to-action__image"
                src="/mock/image/register-call-to-action.webp"
            />

            <div className="register-call-to-action__content">
                <h2 className="register-call-to-action__heading">
                    {trans('compositions.registerCallToAction.heading')}
                </h2>

                <p className="register-call-to-action__text">
                    {trans('compositions.registerCallToAction.text')}
                </p>

                <LinkButton
                    to={trans(appRoutes[AppRoute.registration].path)}
                    text={trans('compositions.registerCallToAction.buttonLabel')}
                    className="register-call-to-action__button"
                />
            </div>
        </div>
    );
};
