import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ResetPasswordState = AsyncReduxState;

const initialState: ResetPasswordState = {
    ...initialAsyncReduxState,
};

const resetPasswordSlice = createSlice({
    name: 'resetPasswordSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ResetPasswordState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ResetPasswordState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ResetPasswordState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = resetPasswordSlice.actions;

export default resetPasswordSlice;
