import {
    FormEvent,
    forwardRef,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { InputProps } from '../../components';
import { TextInput } from '../../compositions';
import { useTrans } from '../../hooks';

import './ProductsSearch.scss';

interface ProductsSearchProps extends InputProps {
    newValue: (value: string) => void;
    onIsTyping: (isTyping: boolean) => void;
    onSubmit: () => void;
    className?: string;
}

export const ProductsSearch = forwardRef<HTMLInputElement, ProductsSearchProps>(({
    newValue,
    onIsTyping,
    onSubmit,
    className = '',
}, ref): ReactElement => {
    const trans = useTrans();

    const [value, setValue] = useState<string>('');

    useEffect((): void => {
        if (value.length < 1) {
            return;
        }

        onIsTyping(true);
        newValue(value);
    }, [value]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (value.length < 1) {
            return;
        }

        onSubmit();

        if (ref && 'current' in ref && ref.current) {
            ref.current.blur();
        }
    };

    return (
        <form
            enterKeyHint="search"
            onSubmit={handleSubmit}
            className={`products-search ${className}`}
        >
            <TextInput
                ref={ref}
                hideLabel
                hasUnderline
                type="search"
                icon="search"
                label={trans('form.whatAreYouLookingFor')}
                placeholder={trans('form.whatAreYouLookingFor')}
                value={value}
                onChange={setValue}
                className="products-search__input"
                fieldClassName="products-search__input-field"
                iconClassName="products-search__input-icon"
            />
        </form>
    );
});
