import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { ErrorLabel, Picture } from '../../../../components';
import {
    Button,
    Checkbox,
    PasswordInput,
    TextInput,
} from '../../../../compositions';
import { RegistrationFormData, RegistrationFormStep } from '../../../../entities/@forms/RegistrationForm/RegistrationForm';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';
import { RegistrationFormErrors } from '../validations';

interface RegistrationFormStepAccountDetailsProps {
    formData: RegistrationFormData;
    setFormData: (formData: RegistrationFormData) => void;
    formErrors: RegistrationFormErrors;
    isLoading?: boolean;
    error?: string;
}

export const RegistrationFormStepAccountDetails: FC<RegistrationFormStepAccountDetailsProps> = ({
    formData,
    setFormData,
    formErrors,
    isLoading,
    error,
}): ReactElement => {
    const trans = useTrans();

    const setEmail = (email: string): void => setFormData({ ...formData, email });

    const setFirstName = (firstName: string): void => setFormData({
        ...formData,
        firstName,
    });
    const setLastName = (lastName: string): void => setFormData({
        ...formData,
        lastName,
    });
    const setPassword = (password: string): void => setFormData({
        ...formData,
        password,
    });
    const setConfirmPassword = (confirmPassword: string): void => setFormData({
        ...formData,
        confirmPassword,
    });
    const setAcceptTermsAndConditions = (acceptTermsAndConditions: boolean): void => setFormData({
        ...formData,
        acceptTermsAndConditions,
    });
    const setSubscribedToNewsletter = (subscribedToNewsletter: boolean): void => setFormData({
        ...formData,
        subscribedToNewsletter,
    });

    return (
        <div className={`registration-form__step registration-form__step--${RegistrationFormStep.accountDetails}`}>

            <div className="registration-form__content">
                <h2 className="registration-form__heading">
                    {trans('containers.registrationForm.personalDetailsStepHeading')}
                </h2>

                <p className="registration-form__description">
                    {trans('containers.registrationForm.personalDetailsStepDescription')}
                </p>

                <p className="registration-form__section-label">
                    {trans('containers.registrationForm.personalDetailsSectionLabel')}
                </p>

                <div className="registration-form__input-row">
                    <TextInput
                        type="text"
                        autoComplete="given-name"
                        autoCapitalize="words"
                        label={trans('containers.registrationForm.firstNameLabel')}
                        placeholder={trans('containers.registrationForm.firstNamePlaceholder')}
                        value={formData.firstName}
                        error={formErrors.firstName}
                        disabled={isLoading}
                        onChange={setFirstName}
                        className="registration-form__text-input"
                    />

                    <TextInput
                        type="text"
                        autoComplete="family-name"
                        autoCapitalize="words"
                        label={trans('containers.registrationForm.lastNameLabel')}
                        placeholder={trans('containers.registrationForm.lastNamePlaceholder')}
                        value={formData.lastName}
                        error={formErrors.lastName}
                        disabled={isLoading}
                        onChange={setLastName}
                        className="registration-form__text-input"
                    />
                </div>

                <TextInput
                    type="email"
                    autoComplete="email"
                    label={trans('containers.registrationForm.emailLabel')}
                    placeholder={trans('containers.registrationForm.emailPlaceholder')}
                    value={formData.email}
                    error={formErrors.email}
                    disabled={isLoading}
                    onChange={setEmail}
                    className="registration-form__text-input"
                />
            </div>

            <div className="registration-form__column">
                <div className="registration-form__content">
                    <p className="registration-form__section-label">
                        {trans('containers.registrationForm.accountSectionLabel')}
                    </p>

                    <PasswordInput
                        autoComplete="new-password"
                        label={trans('containers.registrationForm.passwordLabel')}
                        placeholder={trans('containers.registrationForm.passwordPlaceholder')}
                        value={formData.password}
                        error={formErrors.password}
                        disabled={isLoading}
                        onChange={setPassword}
                        fieldClassName="registration-form__password-input-field"
                        className="registration-form__text-input"
                    />

                    <PasswordInput
                        autoComplete="new-password"
                        label={trans('containers.registrationForm.confirmPasswordLabel')}
                        placeholder={trans('containers.registrationForm.confirmPasswordPlaceholder')}
                        value={formData.confirmPassword}
                        error={formErrors.confirmPassword}
                        disabled={isLoading}
                        onChange={setConfirmPassword}
                        fieldClassName="registration-form__password-input-field"
                        className="registration-form__text-input"
                    />

                    <Checkbox
                        htmlLabel={trans('containers.registrationForm.acceptTermsAndConditions')}
                        disabled={isLoading}
                        checked={formData.acceptTermsAndConditions}
                        error={formErrors.acceptTermsAndConditions}
                        onChange={setAcceptTermsAndConditions}
                        className="registration-form__terms-input"
                    />

                    <Checkbox
                        label={trans('form.newsLetterSubscription')}
                        disabled={isLoading}
                        checked={formData.subscribedToNewsletter}
                        onChange={setSubscribedToNewsletter}
                        className="registration-form__newsletter-subscription-input"
                    />

                    <Button
                        type="submit"
                        text={trans('containers.registrationForm.submitButton')}
                        className="registration-form__submit-button"
                    />

                    {error && (
                        <div className="registration-form__error">
                            <ErrorLabel
                                text={error}
                                className="registration-form__error-label"
                            />
                            {error.includes(trans('containers.registrationForm.emailExistsErrorMessagePattern')) && (
                                <Link
                                    to={trans(appRoutes[AppRoute.login].path)}
                                    className="registration-form__login-button"
                                >
                                    {trans('containers.registrationForm.loginButton')}
                                </Link>
                            )}
                        </div>
                    )}
                </div>

                <div className="registration-form__header-image">
                    <Picture
                        src="/mock/image/registration-step-2.webp"
                        className="registration-form__picture"
                    />
                </div>
            </div>
        </div>
    );
};
