/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconLocation: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M78.7,39c0,15.8-28.7,49.1-28.7,49.1,0,0-28.7-33.3-28.7-49.1s12.8-28.7,28.7-28.7,28.7,12.8,28.7,28.7Z" />
        <circle className="icon__stroke" cx="50" cy="39" r="10.4" />
    </svg>
));
