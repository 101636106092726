import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Picture } from '../../components';
import { Image, isSilentLoop, Video as VideoInterface } from '../../entities/Media/Media';
import {
    Dialog,
    DialogContent,
    PlayButton,
    Video,
} from '..';

import './VideoTheatre.scss';

interface VideoTheatreProps extends VideoInterface {
    playsInline?: boolean;
    poster?: Image;
    className?: string;
}

export const VideoTheatre: FC<VideoTheatreProps> = ({
    playsInline,
    poster,
    className = '',
    ...videoProps
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    useEffect((): void => {
        const toggleVideoPlayState = async (): Promise<void> => {
            if (!videoRef.current) {
                return;
            }

            if (isOpen) {
                videoRef.current.currentTime = 0;

                await videoRef.current.play();
            } else {
                await videoRef.current.pause();
            }
        };

        toggleVideoPlayState();
    }, [isOpen]);

    const videoElementHeight = useMemo((): number => {
        if (!videoRef.current) {
            return 0;
        }

        const { height } = videoRef.current.getBoundingClientRect();

        return height;
    }, [videoRef.current]);

    const videoAspectRatio = useMemo((): number => {
        if (!videoRef.current) {
            return 0;
        }

        const { videoWidth, videoHeight } = videoRef.current;

        return videoWidth / videoHeight;
    }, [videoRef.current]);

    const handleOpenDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.showModal();
        setIsOpen(true);
    };

    const handleCloseDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.close();
        setIsOpen(false);
    };

    const cssVariables = {
        '--video-theatre-width': videoAspectRatio ? `${videoElementHeight * videoAspectRatio}px` : '100%',
        '--video-theatre-height': videoElementHeight ? `${videoElementHeight}px` : '100%',
    } as CSSProperties;

    const closeButtonClassNames = classNames('video-theatre__dialog-close-button', {
        'video-theatre__dialog-close-button--is-faded': !isPlaying,
    });

    return (
        <div style={cssVariables} className={`video-theatre ${className}`}>
            {playsInline && (
                <div className="video-theatre__inline-wrapper">
                    <Video
                        {...videoProps}
                        {...isSilentLoop}
                        className="video-theatre__inline-video"
                        videoClassName="video-theatre__inline-video-element"
                    />
                </div>
            )}

            {!playsInline && (
                <div className="video-theatre__poster-wrapper">
                    {poster ? (
                        <Picture
                            {...poster}
                            className="video-theatre__poster"
                            imageClassName="video-theatre__poster-image"
                        />
                    ) : (
                        <div className="video-theatre__poster-placeholder" />
                    )}

                    <div className="video-theatre__play-button-wrapper">
                        <PlayButton
                            onClick={handleOpenDialog}
                            className="video-theatre__play-button"
                        />
                    </div>
                </div>
            )}

            {!playsInline && (
                <Dialog
                    ref={dialogRef}
                    hasLightBackdrop
                    onClose={handleCloseDialog}
                    className="video-theatre__dialog"
                    closeButtonClassName={closeButtonClassNames}
                >
                    <DialogContent className="video-theatre__dialog-content">
                        <Video
                            {...videoProps}
                            ref={videoRef}
                            controls
                            onPlayStateChange={setIsPlaying}
                            className="video-theatre__video"
                            videoClassName="video-theatre__video-element"
                        />
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};
