import { FC, ReactElement } from 'react';

import { ProductCard } from '../../compositions';
import { Product } from '../../entities/Product/Product';
import { useTrans } from '../../hooks';

import './BrandProducts.scss';

interface BrandContentBlocksProps {
    products: Product[];
    className?: string;
}

export const BrandProducts: FC<BrandContentBlocksProps> = ({
    products,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`brand-products ${className}`}>
            <h2 className="brand-products__heading">
                {trans('pages.brands.shopProducts')}
            </h2>

            <div className="brand-products__products-wrapper">
                {products.map(product => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
        </div>
    );
};
