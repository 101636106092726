import { ChangePasswordFormData } from '../../entities/@forms/ChangePasswordForm/ChangePasswordForm';
import { Token, tokenKey, UpdateCustomerPasswordResponse } from '../../entities/Api/Webshop';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { authorizedSyliusFetch } from '../../entities/Sylius/SyliusService';
import trans from '../../helpers/trans';
import { TypedDispatch } from '../store';
import { addPositiveToast } from '../toast/toastActions';
import { setError, setIsChangedSuccessfully, setIsLoading } from './changePasswordSlice';

export const changePassword = (formData: ChangePasswordFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsChangedSuccessfully(false));

    try {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
        const tokenData = storedToken
            ? JSON.parse(storedToken) as Token
            : undefined;

        const customerId = tokenData?.customerId || '';

        const changePasswordResponse = await authorizedSyliusFetch<UpdateCustomerPasswordResponse>(`/shop/customers/${customerId}/password`, {
            method: 'PUT',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(changePasswordResponse)) {
            dispatch(setError(changePasswordResponse.error));
            return;
        }

        dispatch(setIsChangedSuccessfully(true));

        dispatch(addPositiveToast({
            title: trans('redux.changePassword.successMessageTitle'),
            description: trans('redux.changePassword.successMessageDescription'),
        }));
    } catch (error) {
        console.error('[changePassword]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
