/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconPinterest: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 16 24" className={`icon ${className}`}>
        <path
            d="M15.5 12C15.5 16.2812 12.0312 19.75 7.75 19.75C6.9375 19.75 6.15625 19.6562 5.4375 19.4062C5.75 18.9062 6.21875 18.0625 6.40625 17.375C6.5 17.0312 6.875 15.5312 6.875 15.5312C7.125 16.0312 7.875 16.4375 8.65625 16.4375C11 16.4375 12.6875 14.2812 12.6875 11.625C12.6875 9.0625 10.5938 7.125 7.90625 7.125C4.5625 7.125 2.78125 9.375 2.78125 11.8125C2.78125 12.9688 3.375 14.375 4.34375 14.8438C4.5 14.9062 4.59375 14.875 4.625 14.7188C4.625 14.625 4.78125 14.0938 4.84375 13.8438C4.84375 13.7812 4.84375 13.6875 4.78125 13.625C4.46875 13.25 4.21875 12.5312 4.21875 11.8438C4.21875 10.1562 5.5 8.5 7.71875 8.5C9.59375 8.5 10.9375 9.78125 10.9375 11.6562C10.9375 13.75 9.875 15.1875 8.5 15.1875C7.75 15.1875 7.1875 14.5625 7.34375 13.8125C7.5625 12.875 8 11.875 8 11.2188C8 10.625 7.6875 10.125 7.03125 10.125C6.25 10.125 5.625 10.9375 5.625 12C5.625 12.6875 5.84375 13.1562 5.84375 13.1562C5.84375 13.1562 5.09375 16.4062 4.9375 17C4.78125 17.6875 4.84375 18.625 4.90625 19.2188C2.03125 18.0938 0 15.3125 0 12C0 7.71875 3.46875 4.25 7.75 4.25C12.0312 4.25 15.5 7.71875 15.5 12Z"
        />
    </svg>
));
