import { MOCK_BRANDS } from '../../../mock/mock-data';
import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { BrandCarouselInterface, BrandCarouselResource } from './BrandCarousel';

export const transformToBrandCarousel = (resource: BrandCarouselResource): BrandCarouselInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    // TODO: Transform data from Sylius to 'logos'
    const brandLogos = MOCK_BRANDS;

    return {
        ...defaultBlock,
        type: BlockType.brandCarousel,
        title: resource.title,
        logos: brandLogos,
    };
};
