import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedBlogPostRenderer } from '../../connectors';

import './BlogPostPage.scss';

interface BlogPostPageProps {
    className?: string;
}

export const BlogPostPage: FC<BlogPostPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`blog-post-page ${className}`}>
        <ConnectedBlogPostRenderer />
    </Page>
);
