import { FC, PropsWithChildren, ReactElement } from 'react';

import { Wrapper } from '../../components';

import './FormWrapper.scss';

interface FormWrapperProps extends PropsWithChildren {
    title: string;
    className?: string;
}

export const FormWrapper: FC<FormWrapperProps> = ({
    title,
    className = '',
    children,
}): ReactElement => (
    <section className={`form-wrapper ${className}`}>
        <Wrapper>
            {title && (
                <h2 className="form-wrapper__title">
                    {title}
                </h2>
            )}

            {children}
        </Wrapper>
    </section>
);
