import { FC, ReactElement } from 'react';

import { Icon, IconName } from '../../../../components';

import './IconExamples.scss';

interface IconExamplesProps {
    className?: string;
}

export const IconExamples: FC<IconExamplesProps> = ({
    className = '',
}): ReactElement => {
    const icons: IconName[] = [
        'arrow-left',
        'arrow-right',
        'arrow-short-left',
        'arrow-short-right',
        'audio-off',
        'audio-on',
        'box-check',
        'box-help',
        'box-warning',
        'cart',
        'cart-bag',
        'cart-truck',
        'cart-wagon',
        'check',
        'chevron-down',
        'chevron-left',
        'chevron-right',
        'chevron-up',
        'conversation',
        'cross',
        'dashboard',
        'delivery',
        'edit',
        'facebook',
        'filter',
        'grid',
        'hamburger',
        'happy',
        'heart',
        'instagram',
        'lamp',
        'linkedin',
        'location',
        'lock',
        'logout',
        'loods-location',
        'map',
        'minus',
        'orders',
        'pause',
        'phone',
        'pinterest',
        'play',
        'plus',
        'search',
        'smartphone',
        'square',
        'star',
        'tiktok',
        'user',
        'visibility-off',
        'visibility-on',
        'wayfinding-cta',
        'wayfinding-download',
        'wayfinding-enter',
        'wayfinding-left',
        'wayfinding-right',
        'wayfinding-up',
        'youtube',
    ];

    return (
        <div className={`icon-examples ${className}`}>
            <ul className="icon-examples__grid">
                {icons.map(icon => (
                    <li key={icon} className="icon-examples__item">
                        <Icon
                            name={icon}
                            className="icon-examples__icon"
                            accentClassName="icon-examples__icon-accent"
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
