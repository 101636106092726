import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { RootPortal } from '../../components';
import { Button } from '../../compositions';
import { useTrans } from '../../hooks';
import { clearCaches, isNewerVersion } from './helpers';

import './CacheBuster.scss';

interface CacheBusterProps {
    currentVersion: string;
    latestVersion: string;
}

export const CacheBuster: FC<CacheBusterProps> = ({
    currentVersion,
    latestVersion,
}): ReactElement => {
    const trans = useTrans();
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

    useEffect((): void => {
        if (currentVersion && latestVersion) {
            const updateAvailable = isNewerVersion(latestVersion, currentVersion);

            setShouldUpdate(updateAvailable);
        }
    }, [currentVersion, latestVersion]);

    const cacheBusterClassNames = classNames('cache-buster', {
        'cache-buster--is-revealed': shouldUpdate,
    });

    return (
        <RootPortal>
            <section className={cacheBusterClassNames}>
                {shouldUpdate && (
                    <div className="cache-buster__card">
                        <p className="cache-buster__description">
                            {trans('containers.cacheBuster.updateAvailable')}
                        </p>

                        <Button
                            text={trans('containers.cacheBuster.reload')}
                            onClick={clearCaches}
                            className="cache-buster__button"
                        />
                    </div>
                )}
            </section>
        </RootPortal>
    );
};
