import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import './SeoProductsCategoryBlock.scss';

interface SeoProductsCategoryBlockProps {
    productsDescription: string;
    className?: string;
}

export const SeoProductsCategoryBlock: FC<SeoProductsCategoryBlockProps> = ({
    productsDescription,
    className = '',
}): ReactElement => (
    <div className={`seo-products-category-block ${className}`}>
        <Helmet>
            <meta name="description" content={productsDescription} />
            <meta property="og:description" content={productsDescription} />
        </Helmet>

        <p>{productsDescription}</p>
    </div>
);
