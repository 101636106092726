import { FC, ReactElement } from 'react';

import { UserAddress } from '../../entities/User/User';
import { AddressCard } from '../AddressCard/AddressCard';

import './AddressList.scss';

interface AddressesListProps {
    addresses: UserAddress[];
    className?: string;
}

export const AddressList: FC<AddressesListProps> = ({
    addresses,
    className = '',
}): ReactElement => (
    <div className={`address-list ${className}`}>
        {addresses.map(address => (
            <AddressCard
                key={address.type}
                address={address}
            />
        ))}
    </div>
);
