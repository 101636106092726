import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServiceDeliveryPageRenderer } from '../../../connectors';

import './CustomerServiceDeliveryPage.scss';

export const CustomerServiceDeliveryPage: FC = (): ReactElement => (
    <Page className="customer-service-delivery-page">
        <ConnectedCustomerServiceDeliveryPageRenderer />
    </Page>
);
