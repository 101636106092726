import { FC, ReactElement } from 'react';

import { CustomerServiceTermsPageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServiceTermsPage } from '../../../redux/@pages/customerServiceTermsPage/customerServiceTermsPageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServiceTermsPageRendererProps {
    className?: string;
}

export const ConnectedCustomerServiceTermsPageRenderer: FC<ConnectedCustomerServiceTermsPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.customerServiceTermsPageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServiceTermsPageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchCustomerServiceTermsPage());
    }, []);

    return (
        <CustomerServiceTermsPageRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
