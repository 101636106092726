import { FC, ReactElement } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { Accordion, LinkButton } from '../../compositions';
import { ConnectedNewsletterCallToAction } from '../../connectors';
import { UspSection } from '../../containers';
import { AccountNavigationSidebar } from '../../containers/AccountNavigationSidebar/AccountNavigationSidebar';
import { AccountPages, AccountParams } from '../../entities/Account/Account';
import { useRouteByTranslatedPath, useTrans } from '../../hooks';
import { MOCK_ACCORDION_DATA } from '../../mock/mock-data/accordion';
import { AccountChangePasswordPage } from '../AccountChangePasswordPage/AccountChangePasswordPage';
import { AccountDashboardPage } from '../AccountDashboardPage/AccountDashboardPage';
import { AccountDataPage } from '../AccountDataPage/AccountDataPage';
import { AccountOrderDetailPage } from '../AccountOrderDetailPage/AccountOrderDetailPage';
import { AccountOrdersPage } from '../AccountOrdersPage/AccountOrdersPage';
import { AccountWishlistPage } from '../AccountWishlistPage/AccountWishlistPage';

import './AccountPage.scss';

interface AccountPageProps {
    page?: string;
}

export const AccountPage: FC<AccountPageProps> = (): ReactElement => {
    const { pathname } = useLocation();
    const { id } = useParams<AccountParams>();

    const routeByTranslatedPath = useRouteByTranslatedPath();
    const translatedTab = routeByTranslatedPath(pathname, { id });
    const trans = useTrans();

    return (
        <Page className="account-page">
            <Wrapper className="account-page__wrapper">
                <div className="account-page__first-wrapper">
                    <div className="account-page__sidebar">
                        <AccountNavigationSidebar />
                    </div>

                    <div className="account-page__contents">
                        {translatedTab === AccountPages.dashboard && (
                            <AccountDashboardPage />
                        )}

                        {translatedTab === AccountPages.data && (
                            <AccountDataPage />
                        )}

                        {translatedTab === AccountPages.orders && (
                            <AccountOrdersPage />
                        )}

                        {translatedTab === AccountPages.orderDetail && (
                            <AccountOrderDetailPage />
                        )}

                        {translatedTab === AccountPages.accountWishlist && (
                            <AccountWishlistPage />
                        )}

                        {translatedTab === AccountPages.changePassword && (
                            <AccountChangePasswordPage />
                        )}
                    </div>
                </div>
            </Wrapper>

            {translatedTab === AccountPages.orderDetail && (
                <section className="account-order-detail__questions">
                    <Wrapper className="account-order-detail__questions-wrapper">
                        <div className="account-order-detail__questions-container">
                            <h1 className="account-order-detail__questions-title">
                                {trans('pages.accountOrderDetail.faqTitle')}
                            </h1>

                            {MOCK_ACCORDION_DATA.map(item => (
                                <Accordion
                                    hasBorder={item.hasBorder}
                                    header={item.header}
                                    htmlContent={item.htmlContent}
                                    headingClassName={item.headingClassName}
                                    className="account-order-detail__questions-accordion"
                                />
                            ))}

                            <LinkButton
                                to="/faq"
                                text="Bekijk alle veelgestelde vragen"
                                className="account-order-detail__questions-link"
                            />
                        </div>
                    </Wrapper>
                </section>
            )}

            <UspSection
                items={[
                    {
                        id: 0,
                        text: 'Online besteld? Gratis verzonden',
                        icon: 'cart-truck',
                    },
                    {
                        id: 1,
                        text: 'Keuze uit meer dan 300 meubelmerken',
                        icon: 'lamp',
                    },
                    {
                        id: 2,
                        text: 'Niet tevreden? Geld terug!',
                        icon: 'happy',
                    },
                ]}
                className="account-page__block"
            />

            {/* TODO: Service & Contact block */}

            <ConnectedNewsletterCallToAction
                title="Wekelijks inspiratie in je inbox?"
                description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                textColor="#000000"
                className="account-page__block"
            />
        </Page>
    );
};
