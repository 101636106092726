import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ConnectedCart } from '../../connectors';

import './CartPage.scss';

export const CartPage: FC = (): ReactElement => (
    <Page className="cart-page">
        <Wrapper className="cart-page__wrapper">
            <ConnectedCart />
        </Wrapper>
    </Page>
);
