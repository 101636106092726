import { FC, ReactElement } from 'react';

import { Breadcrumbs, Page, Wrapper } from '../../components';

import './StoreDetailPage.scss';

export const StoreDetailPage: FC = (): ReactElement => (
    <Page className="store-detail-page">
        <Wrapper>
            <Breadcrumbs
                breadcrumbs={[
                    { title: 'Home', url: '/' },
                    { title: 'winkels', url: '/winkels' },
                    { title: 'maastricht', url: '' },
                ]}
            />
        </Wrapper>

        <div className="store-detail-page__header">
            {/* TODO all the data should come from the api */}
            <img
                className="store-detail-page__image"
                src="https://cdn.loods5.nl/dynamic/00378/000006C7A6.original"
                alt="Shop"
            />

            <div className="store-detail-page__info">
                <h4 className="store-detail-page__info-title">Maastricht</h4>

                <a
                    href="http://maps.google.com/?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003"
                    target="_blank"
                    rel="noreferrer"
                >
                    Sphinxcour 5, 6211 XZ Maastricht
                </a>

                <h4 className="store-detail-page__info-is-open">
                    Nu geopend ⋅
                    <span className="store-detail-page__info-date">Vrijdag 10:00 - 18:00 uur</span>
                </h4>

                <div className="store-detail-page__info-opening-hours">
                    <div>
                        <p>Maandag 10:00 - 18:00 uur</p>
                        <p>Dinsdag 10:00 - 18:00 uur</p>
                        <p>Woensdag 10:00 - 18:00 uur</p>
                        <p>Donderdag 10:00 - 18:00 uur</p>
                    </div>

                    <div>
                        <p>Vrijdag 10:00 - 18:00 uur</p>
                        <p>Zaterdag 10:00 - 18:00 uur</p>
                        <p>Zondag 12:00 - 17:00 uur</p>
                    </div>
                </div>
            </div>
        </div>
    </Page>
);
