import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { ServiceSection, StoreList } from '../../containers';
import trans from '../../helpers/trans';
import { MOCK_STORES } from '../../mock/mock-data';
import { MOCK_SERVICE_CONTACT_DETAILS } from '../../mock/mock-data/contact';

import './StoreOverviewPage.scss';

export const StoreOverviewPage: FC = (): ReactElement => (
    <Page>
        <Wrapper>
            <h2 className="store-overview-page__title">
                {trans('pages.storeOverview.title')}
            </h2>

            <StoreList storeListItems={MOCK_STORES} />
        </Wrapper>

        {/* TODO all the data should come from the api */}
        <section className="store-overview-page__info-section">
            <Wrapper>
                <h3 className="store-overview-page__info-section-subtitle">Woonwinkels van Loods 5</h3>

                <p>
                    Het uitzoeken van je nieuwe meubels en woonaccessoires doe je bij ons gemakkelijk online. Maar we
                    begrijpen dat, voordat je een beslissing neemt, je soms liever de meubels persoonlijk wilt bekijken
                    of uittesten. Daarvoor ben je welkom in een van de vijf winkels van Loods 5. Deze bevinden zich op
                    diverse locaties in Nederland, zodat er altijd een winkel bij jou in de buurt is.
                </p>

                <h3 className="store-overview-page__info-section-subtitle">Alle meubels onder een dak</h3>
                <p>
                    Voor onze complete meubelcollectie ga je naar onze winkels in
                    <Link to="/winkels/winkel-naam">Amersfoort</Link>
                    ,
                    {' '}
                    <Link to="/winkels/winkel-naam">Duiven</Link>
                    ,
                    {' '}
                    <Link to="/winkels/winkel-naam">Maastricht</Link>
                    ,
                    {' '}
                    <Link to="/winkels/winkel-naam">Sliedrecht</Link>
                    {' '}
                    of
                    <Link to="/winkels/winkel-naam">Zaandam</Link>
                    . De ruim opgezette winkels hebben stijlkamers zodat je ter plaatse inspiratie
                    opdoet voor je eigen inrichting. Ontdek op je gemak ons uitgebreide assortiment aan banken, kasten,
                    eetkamer meubels en bedden van 300 interieurmerken. Maak vervolgens je inrichting compleet met
                    bijpassende accessoires als lampen, gordijnen, beddengoed en kunst.

                    Ben je op zoek naar meubels met korting? Breng dan een bezoek aan
                    <Link to="/winkels/winkel-naam">Garage Zaandam (outlet)</Link>
                    .
                </p>

                <h3 className="store-overview-page__info-section-subtitle">Een complete winkelervaring</h3>
                <p>
                    Voor het uitzoeken van je nieuwe meubels neem je uiteraard de tijd. We maken je het zo gemakkelijk
                    mogelijk als je je nieuwe interieur bij ons komt uitzoeken. Zo parkeer je bij alle winkels voor de
                    deur. Ook hebben de winkels meerdere verdiepingen die allemaal te bereiken zijn met de roltrap en de
                    lift. En heb je tussendoor zin in een kop koffie of een lichte lunch, dan ben je van harte welkom in
                    het restaurant. Deze faciliteiten zijn alleen niet beschikbaar bij de outlet Garage in Zaandam.
                </p>

                <h3 className="store-overview-page__info-section-subtitle">Algemene openingstijden</h3>
                <p>
                    Al onze winkels zijn alle dagen van de week doorlopend geopend. Op bepaalde feestdagen zijn
                    afwijkende openingstijden van toepassing. Voor de rest van het jaar gelden de standaard
                    openingstijden.
                </p>
            </Wrapper>
        </section>

        <div className="store-overview-page__service-section">
            <Wrapper>
                <ServiceSection
                    title="Service & contact"
                    serviceContactDetails={MOCK_SERVICE_CONTACT_DETAILS}
                />
            </Wrapper>
        </div>
    </Page>
);
