import { FC, ReactElement } from 'react';

import { CartSidebar } from '../../containers';
import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { MOCK_CART } from '../../mock/mock-data/cart';

export const ConnectedCartSideBar: FC = (): ReactElement => {
    // TODO: Fetch cart data from API/Local Storage
    const cartData = MOCK_CART;

    const mockUspItems: UspItem[] = [
        { id: 0, text: 'Online besteld? Gratis verzonden', icon: 'cart-truck' },
        { id: 1, text: 'Keuze uit meer dan 300 meubelmerken', icon: 'lamp' },
        { id: 2, text: 'Niet tevreden? Geld terug!', icon: 'happy' },
    ];

    const totalPrice = cartData.reduce((acc, item) => acc + item.product.price * item.quantity, 0);

    const handleUpdateCartItemQuantity = (productId: string, quantity: number) => {
        console.log('handleUpdateCartItemQuantity', productId, quantity);

        cartData.forEach((item) => {
            if (item.product.id === productId) {
                // eslint-disable-next-line no-param-reassign
                item.quantity = quantity;
            }
        });

        // TODO: Update cart with new quantity of cart item (state (LS/API)
    };

    const handleRemoveItemFromCart = (productId: string) => {
        console.log('handleRemoveItemFromCart', productId);

        // TODO: Add query to remove product to cart
    };

    const handleCartItemIsInWishlist = (productId: string) => {
        console.log('handleCartItemIsInWishlist', productId);

        // TODO: Add query to add to wishlist
    };

    // TODO: Add query to add product to cart

    return (
        <CartSidebar
            cartData={cartData}
            totalPrice={totalPrice}
            uspItems={mockUspItems}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItemQuantity={handleUpdateCartItemQuantity}
            onUpdateCartItemIsInWishlist={handleCartItemIsInWishlist}
        />
    );
};
