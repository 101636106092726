import { FC, ReactElement } from 'react';

import { Tab } from '../../entities/Tab/Tab';
import { TabItem } from './subcomponents';

import './TabList.scss';

interface TabListProps {
    tabs: Tab[];
    activeTab: string;
    onTabSelect: (value: string) => void;
    className?: string;
    tabItemLabelClassName?: string;
}

export const TabList: FC<TabListProps> = ({
    tabs,
    activeTab,
    onTabSelect,
    className = '',
    tabItemLabelClassName = '',
}): ReactElement => (
    <ul className={`tab-list ${className}`}>
        {tabs.map(tab => (
            <TabItem
                {...tab}
                key={tab.value}
                isActive={tab.value === activeTab}
                onClick={onTabSelect}
                labelClassName={tabItemLabelClassName}
            />
        ))}
    </ul>
);
