import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServiceReturnsPageRenderer } from '../../../connectors';

import './CustomerServiceReturnsPage.scss';

export const CustomerServiceReturnsPage: FC = (): ReactElement => (
    <Page className="customer-service-returns-page">
        <ConnectedCustomerServiceReturnsPageRenderer />
    </Page>
);
