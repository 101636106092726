import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { RegisterCallToAction } from '../../compositions';
import { ConnectedLoginForm, ConnectedNewsletterCallToAction } from '../../connectors';
import { ServiceSection, UspSection } from '../../containers';
import { MOCK_SERVICE_CONTACT_DETAILS } from '../../mock/mock-data/contact';

import './LoginPage.scss';

interface LoginPageProps {
    className?: string;
}

export const LoginPage: FC<LoginPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`login-page ${className}`}>
        <Wrapper className="login-page__wrapper">
            <RegisterCallToAction className="login-page__register-call-to-action" />

            <ConnectedLoginForm
                className="login-page__login-form"
            />
        </Wrapper>

        <UspSection
            items={[
                {
                    id: 0,
                    text: 'Online besteld? Gratis verzonden',
                    icon: 'cart-truck',
                },
                {
                    id: 1,
                    text: 'Keuze uit meer dan 300 meubelmerken',
                    icon: 'lamp',
                },
                {
                    id: 2,
                    text: 'Niet tevreden? Geld terug!',
                    icon: 'happy',
                },
            ]}
            className="login-page__section"
        />

        <div className="login-page__section login-page__service-section">
            <Wrapper className="contact-page__wrapper">
                <ServiceSection
                    title="Service & contact"
                    serviceContactDetails={MOCK_SERVICE_CONTACT_DETAILS}
                />
            </Wrapper>
        </div>

        <ConnectedNewsletterCallToAction
            title="Wekelijks inspiratie in je inbox?"
            description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
            textColor="#000000"
            className="login-page__section login-page__sub-footer"
        />
    </Page>
);
