import { FC, ReactElement } from 'react';

import { ConnectedAccountDashboardConfig, ConnectedAccountDashboardOrders } from '../../connectors';
import { useTrans } from '../../hooks';

import './AccountDashboardPage.scss';

export const AccountDashboardPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-dashboard-page">
            <div className="account-dashboard-page__header">
                <h5 className="account-dashboard-page__heading">
                    {trans('pages.accountDashboard.dashboard')}
                </h5>

                <p className="account-dashboard-page__description">
                    {trans('pages.accountDashboard.description')}
                </p>
            </div>

            <div className="account-dashboard-page__content">
                <ConnectedAccountDashboardOrders className="account-dashboard-page__orders" />

                <ConnectedAccountDashboardConfig />
            </div>
        </div>
    );
};
