import { SearchableOption } from '../Form/Form';
import { OpenStreetMapFeatureResource, osmDisplayNameSplitCharacter, osmGeometrySplitCharacter } from './OpenStreetMap';

export const transformOpenStreetMapFeatureToSearchableOption = (resource: OpenStreetMapFeatureResource): SearchableOption => {
    const displayName = resource.properties.display_name;
    const [longitude, latitude] = resource.geometry.coordinates;

    const label = displayName.split(osmDisplayNameSplitCharacter).join('');
    const value = latitude + osmGeometrySplitCharacter + longitude;

    return { label, value };
};
