import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import classNames from 'classnames';

import {
    Icon,
    IconName,
    Input,
    InputProps,
} from '../../../components';
import { InputLabelProps } from '../InputLabel/InputLabel';
import { InputLabelWrapper } from '../InputLabelWrapper/InputLabelWrapper';

import './SearchInput.scss';

export interface SearchInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    hasSearchResults?: boolean;
    hasMultipleValues?: boolean;
    hasError?: boolean;
    icon?: IconName;
    hideIcon?: boolean;
    onChange: (value: string) => void;
    className?: string;
    inputWrapperClassName?: string;
    iconWrapperClassname?: string;
    iconClassname?: string;
}

export const SearchInput: ForwardRefExoticComponent<SearchInputProps> = forwardRef(({
    hasSearchResults,
    hasMultipleValues,
    hasError,
    label,
    hideLabel,
    icon = 'search',
    hideIcon,
    required,
    error,
    onChange,
    className = '',
    inputWrapperClassName = '',
    iconWrapperClassname = '',
    iconClassname = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const searchInputWrapperClassNames = classNames('search-input__wrapper', {
        'search-input__wrapper--has-error': !!error || hasError,
        'search-input__wrapper--has-search-results': hasSearchResults,
        'search-input__wrapper--has-multiple-values': hasMultipleValues,
    }, inputWrapperClassName);

    const searchInputClassNames = classNames('search-input__input', {
        'search-input__input--has-icon': !hideIcon,
    });

    return (
        <label aria-label={label} className={`search-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                error={error}
            >
                <div className={searchInputWrapperClassNames}>
                    {!hideIcon && (
                        <div className={`search-input__icon-wrapper ${iconWrapperClassname}`}>
                            <Icon name={icon} className={`search-input__icon ${iconClassname}`} />
                        </div>
                    )}

                    <Input
                        {...inputProps}
                        ref={ref}
                        type="search"
                        onChange={handleChange}
                        className={searchInputClassNames}
                    />
                </div>
            </InputLabelWrapper>
        </label>
    );
});
