import { FC } from 'react';

import { ProductSidebar } from '../../../compositions';
import { Product } from '../../../entities/Product/Product';
import { ProductMediaPresentation } from './subcomponents';

import './ProductDetail.scss';

interface ProductDetailProps {
    product: Product;
    className?: string;
}

export const ProductDetail: FC<ProductDetailProps> = ({
    product,
    className = '',
}) => (
    <section className={`product-detail ${className}`}>
        <div className="product-detail__main">
            <ProductMediaPresentation
                mediaItems={product.mediaItems}
                className="product-detail__media-presentation"
            />
        </div>

        <ProductSidebar
            product={product}
            className="product-detail__sidebar"
        />
    </section>
);
