import { FC, ReactElement } from 'react';

import { useToggle } from 'react-use';

import {
    Button,
    FilterButton,
    IconButton,
    LinkButton,
    LinkIconButton,
    PlayButton,
    WishlistButton,
} from '../../../../compositions';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';

import './ButtonExamples.scss';

interface ButtonExamplesProps {
    className?: string;
}

export const ButtonExamples: FC<ButtonExamplesProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [isInWishlist, toggleWishlistStatus] = useToggle(false);
    const [isPlaying, toggleIsPlaying] = useToggle(false);

    return (
        <div className={`button-examples ${className}`}>
            <div className="button-examples__grid">
                <div className="button-examples__column">
                    <FilterButton
                        text="Filter button"
                        className="button-examples__button"
                    />

                    <FilterButton
                        text="Filter button"
                        className="button-examples__button button-examples__button--no-background"
                    />

                    <FilterButton
                        text="Filter button (disabled)"
                        disabled
                        className="button-examples__button"
                    />

                    <IconButton
                        icon="lamp"
                        text="Icon button"
                        className="button-examples__button"
                    />

                    <IconButton
                        icon="lamp"
                        text="Icon button"
                        className="button-examples__button button-examples__button--is-outline"
                    />

                    <IconButton
                        icon="lamp"
                        text="Icon button (disabled)"
                        disabled
                        className="button-examples__button"
                    />
                </div>

                <div className="button-examples__column">
                    <IconButton
                        hasAnimation
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        text="Bekijk animatie"
                        className="button-examples__button"
                    />

                    <IconButton
                        isSmall
                        hasAnimation
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.left}
                        text="Bekijk animatie"
                        className="button-examples__button button-examples__button--is-outline"
                    />

                    <IconButton
                        isSmall
                        hasAnimation
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.left}
                        text="Bekijk animatie (disabled)"
                        disabled
                        className="button-examples__button"
                    />

                    <Button
                        hasAnimation
                        text="Alternatieve animatie"
                        className="button-examples__button"
                    />

                    <Button
                        isSmall
                        hasAnimation
                        text="Alternatieve animatie"
                        className="button-examples__button button-examples__button--is-outline"
                    />

                    <Button
                        isSmall
                        hasAnimation
                        text="Alternatieve animatie (disabled)"
                        disabled
                        className="button-examples__button"
                    />
                </div>

                <div className="button-examples__column">
                    <IconButton
                        hasAnimation
                        hideLabel
                        icon="arrow-right"
                        text="Icoon animatie"
                        className="button-examples__button"
                    />

                    <IconButton
                        hasAnimation
                        isSmall
                        hideLabel
                        icon="arrow-right"
                        text="Icoon animatie"
                        className="button-examples__button button-examples__button--is-outline"
                    />

                    <IconButton
                        hasAnimation
                        isSmall
                        hideLabel
                        icon="arrow-right"
                        text="Icoon animatie (disabled)"
                        disabled
                        className="button-examples__button"
                    />

                    <PlayButton
                        isPlaying={isPlaying}
                        text="Play button"
                        onClick={toggleIsPlaying}
                        className="button-examples__button"
                    />

                    <WishlistButton
                        isActive={isInWishlist}
                        text={trans('common.addToWishlist')}
                        onClick={toggleWishlistStatus}
                        className="button-examples__button"
                    />
                </div>

                <div className="button-examples__column">
                    <LinkButton
                        hasAnimation
                        to={trans(appRoutes[AppRoute.home].path)}
                        text="Link button"
                        className="button-examples__button"
                    />

                    <LinkButton
                        isSmall
                        to={trans(appRoutes[AppRoute.home].path)}
                        text="Link button"
                        className="button-examples__button button-examples__button--is-outline"
                    />

                    <LinkButton
                        isSmall
                        to={trans(appRoutes[AppRoute.home].path)}
                        text="Link button (disabled)"
                        disabled
                        className="button-examples__button"
                    />

                    <LinkIconButton
                        hasAnimation
                        to={trans(appRoutes[AppRoute.home].path)}
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        text="Link icoon button"
                        className="button-examples__button"
                    />

                    <LinkIconButton
                        isSmall
                        hasAnimation
                        to={trans(appRoutes[AppRoute.home].path)}
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.left}
                        text="Link icoon button"
                        hideLabel
                        className="button-examples__button button-examples__button--is-outline"
                    />

                    <LinkIconButton
                        isSmall
                        hasAnimation
                        to={trans(appRoutes[AppRoute.home].path)}
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.left}
                        text="Link icoon button (disabled)"
                        disabled
                        className="button-examples__button"
                    />
                </div>
            </div>
        </div>
    );
};
