import { Params } from 'react-router-dom';

import { AppRoute } from '../entities/Routing/Routing';
import { replaceUrlValuesWithParamKeys } from '../helpers/url';
import { RouteData } from '../LocalizedRoutes';
import { useTrans } from './useTrans';

export const useRouteByTranslatedPath = () => {
    const trans = useTrans();
    const translatedRoutes = trans<Record<keyof typeof AppRoute, RouteData<AppRoute>>>('routes');

    return (path: string, params: Params): string => {
        const routeEntries = Object.entries(translatedRoutes);

        const [translatedRouteKey = ''] = routeEntries.find(([, route]) => {
            const parameterPath = replaceUrlValuesWithParamKeys(path, params);

            return route.path === parameterPath;
        }) || [];

        return translatedRouteKey;
    };
};
