import qs from 'qs';

import { BlockTypeResource } from '../Block/Block';
import { PageType } from './Page';

export const generatePageQuery = (type: PageType): string => {
    const filters = {
        fixedType: {
            $eq: type,
        },
    };

    const populate = {
        elements: {
            on: {
                [BlockTypeResource.blogOverview]: {
                    populate: ['blogCategory'],
                },
                [BlockTypeResource.brandCarousel]: {
                    populate: '*',
                },
                [BlockTypeResource.contentColumns]: {
                    populate: ['columns', 'columns.media'],
                },
                [BlockTypeResource.carousel]: {
                    populate: ['images'],
                },
                [BlockTypeResource.form]: {
                    populate: '*',
                },
                [BlockTypeResource.header]: {
                    populate: ['autoplayMedia', 'autoplayMedia.media'],
                },
                [BlockTypeResource.highlightedBlogPost]: {
                    populate: ['blog'],
                },
                [BlockTypeResource.iconColumns]: {
                    populate: ['columns', 'columns.icon'],
                },
                [BlockTypeResource.faq]: {
                    populate: ['faqs'],
                },
                [BlockTypeResource.featuredProducts]: {
                    populate: [
                        'product_selection',
                        'button',
                        'button.link',
                        'button.icon',
                    ],
                },
                [BlockTypeResource.mediaGrid]: {
                    populate: ['mediaItems', 'mediaItems.media'],
                },
                [BlockTypeResource.newsletterCallToAction]: {
                    populate: ['image'],
                },
                [BlockTypeResource.service]: {
                    populate: '*',
                },
                [BlockTypeResource.uspSection]: {
                    populate: ['uniqueSellingPoint', 'uniqueSellingPoint.icon'],
                },
            },
        },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
