import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { FaqItemInterface } from '../../../../../entities/@blocks/Faq/Faq';

interface FaqHelmetProps {
    faqItems: FaqItemInterface[];
}

export const FaqHelmet: FC<FaqHelmetProps> = ({
    faqItems,
}): ReactElement => (
    <Helmet>
        <script type="application/ld+json">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: faqItems.map(faqItem => ({
                    '@type': 'Question',
                    name: faqItem.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: faqItem.answer,
                    },
                })),
            })}
        </script>
    </Helmet>
);
