import { FC, ReactElement } from 'react';

import { Picture, SafeHtml } from '../../../../../components';
import { VideoTheatre } from '../../../../../compositions';
import { ContentColumnContent } from '../../../../../entities/@blocks/ContentColumns/ContentColumns';

import './ContentColumn.scss';

interface ContentColumnProps extends ContentColumnContent {
    className?: string;
}

export const ContentColumn: FC<ContentColumnProps> = ({
    titleHtml,
    paragraphHtml,
    image,
    video,
    className = '',
}): ReactElement => (
    <div className={`content-column ${className}`}>
        {titleHtml && (
            <SafeHtml
                html={titleHtml}
                className="content-column__title-html"
            />
        )}

        {paragraphHtml && (
            <SafeHtml
                html={paragraphHtml}
                className="content-column__paragraph-html"
            />
        )}

        {image && !video && (
            <Picture
                {...image}
                className="content-column__image"
            />
        )}

        {video && (
            <VideoTheatre
                {...video}
                poster={image}
                className="content-column__video"
            />
        )}
    </div>
);
