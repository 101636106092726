import { DefaultBlock } from '../../Block/Block';
import { Link } from '../../Link/Link';
import { Product } from '../../Product/Product';

export enum FeaturedProductsType {
    alternatingSizes = 'alternating-sizes',
    verticalTitle = 'vertical-title',
}

export interface FeaturedProductsInterface extends DefaultBlock {
    layoutType: FeaturedProductsType;
    title: string;
    products: Product[];
    link?: Link;
}
