import { FC, ReactElement } from 'react';

import { Order } from '../../../../entities/Order/Order';
import { useTrans } from '../../../../hooks';
import { OrderItem } from '../OrderItem/OrderItem';

import './OrderItems.scss';

interface OrderItemsProps {
    orders: Order[];
    className?: string;
}

export const OrderItems: FC<OrderItemsProps> = ({
    orders,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`order-items ${className}`}>
            {orders.length === 0 && (
                <p>
                    {trans('containers.accountDashboardOrders.noOrders')}
                </p>
            )}

            {orders.map(order => (
                <OrderItem
                    key={order.id}
                    order={order}
                />
            ))}
        </div>
    );
};
