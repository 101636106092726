import { FC, ReactElement } from 'react';

import { Icon, SafeHtml } from '../../../../../components';
import { IconColumnContent } from '../../../../../entities/@blocks/IconColumns/IconColumns';

import './IconColumn.scss';

interface IconColumnProps extends IconColumnContent {
    className?: string;
}

export const IconColumn: FC<IconColumnProps> = ({
    title,
    paragraphHtml,
    icon,
    className = '',
}): ReactElement => (
    <div className={`icon-column ${className}`}>
        {icon && (
            <Icon name={icon} className="icon-column__icon" />
        )}

        <div className="icon-column__content">
            <h3 className="icon-column__title">
                {title}
            </h3>

            {paragraphHtml && (
                <SafeHtml
                    html={paragraphHtml}
                    className="icon-column__paragraph-html"
                />
            )}
        </div>
    </div>
);
