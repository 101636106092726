/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconBoxWarning: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect className={accentClassName} x="16.7" y="16.7" width="66.7" height="66.7" />
        <path className="icon__box-stroke" d="M29.2,31.2l41.7,37.5M70.8,31.2l-41.7,37.5" />
    </svg>
));
