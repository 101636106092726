import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { PageResponse, PageType } from '../../../entities/Page/Page';
import { generatePageQuery } from '../../../entities/Page/PageRequests';
import { transformToPage } from '../../../entities/Page/PageTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlocks,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './customerServiceTermsPageSlice';

export const fetchCustomerServiceTermsPage: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generatePageQuery(PageType.customerServiceTerms);

        const customerServiceTermsPageResponse = await getStrapiApiCall<PageResponse>(`/pages?${query}`);

        if (!isFetchResultSuccessful(customerServiceTermsPageResponse)) {
            dispatch(setError(customerServiceTermsPageResponse.error));
            return;
        }

        const { data: customerServiceTermsPageData } = customerServiceTermsPageResponse.data;

        const customerServiceTermsPageResource = customerServiceTermsPageData
            ? customerServiceTermsPageData[0]
            : undefined;

        const customerServiceTermsPage = customerServiceTermsPageResource
            ? transformToPage(customerServiceTermsPageResource)
            : undefined;

        const blocks = customerServiceTermsPage?.blocks || [];

        dispatch(setBlocks(blocks));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomerServiceTermsPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
