import { FC, ReactElement } from 'react';

import { MOCK_USER } from '../../mock/mock-data/user';
import {
    AccountDashboardOrderItemInfo,
    AccountDashboardOrderItemProduct,
    MyData,
    OrderItemStatus,
} from './subcomponents';

import './AccountDashboardOrderDetail.scss';

export const AccountDashboardOrderDetail: FC = (): ReactElement => (
    <div className="account-order-dashboard__content-wrapper">
        <AccountDashboardOrderItemProduct
            product={{
                id: 1,
                name: 'Product name',
                brand: 'Brand name',
                price: 100,
                image: 'https://via.placeholder.com/150',
            }}
        />

        <div className="account-order-dashboard__order-info">
            <AccountDashboardOrderItemInfo
                receipt={{
                    orderNmr: 123456,
                    orderDate: 'Vrijdag 03 mei 2024',
                    productPrice: 44.95,
                    productQuantity: 1,
                    totalOnInvoice: 44.95,
                    totalRefund: 44.95,
                    total: 44.95,
                }}
            />

            <OrderItemStatus
                paymentProviderIcon="../../mock/brand-logos/ideal.png"
                invoiceLink="/invoice"
                paymentMethod="iDeal"
                deliveryDate="Vrijdag 03 mei 2024"
                canceled={false}
            />

            <MyData user={MOCK_USER} />
        </div>
    </div>
);
