import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Wrapper } from '../../../components';
import { LinkIconButton } from '../../../compositions';
import { HighlightedBlogPostInterface } from '../../../entities/@blocks/HighlightedBlogPost/HighlightedBlogPost';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import { useFormatDate, useTrans } from '../../../hooks';

import './HighlightedBlogPost.scss';

interface HighlightedBlogPostProps extends HighlightedBlogPostInterface {
    className?: string;
}

export const HighlightedBlogPost: FC<HighlightedBlogPostProps> = ({
    blogPost,
    imagePosition,
    className = '',
}): ReactElement | null => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    if (!blogPost) {
        return null;
    }

    const formattedPublishedDate = formatDate(blogPost.publishDate, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });

    const blogPostPath = trans(appRoutes[AppRoute.blogPost].path);
    const blogPostUrl = replaceUrlParamKeysWithValues(blogPostPath, { slug: blogPost.slug });

    const imageTextBlockWrapperClassNames = classNames('highlighted-blog-post__wrapper', {
        [`highlighted-blog-post__wrapper--image-${imagePosition}`]: imagePosition,
    });

    return (
        <section className={`highlighted-blog-post ${className}`}>
            <Wrapper className="highlighted-blog-post__wrapper">
                <div className={imageTextBlockWrapperClassNames}>
                    <div className="highlighted-blog-post__text-wrapper">
                        <p className="highlighted-blog-post__meta-wrapper">
                            <span className="highlighted-blog-post__category">
                                {blogPost.categories.length > 0 ? (
                                    blogPost.categories[0].title
                                ) : (
                                    trans('common.defaultBlogCategory')
                                )}
                            </span>

                            <span className="highlighted-blog-post__publish-date">
                                {formattedPublishedDate}
                            </span>
                        </p>

                        <div className="highlighted-blog-post__title-wrapper">
                            <h2 className="highlighted-blog-post__title">
                                {blogPost.title}
                            </h2>
                        </div>

                        <Picture
                            {...blogPost.image}
                            className="highlighted-blog-post__mobile-picture"
                            imageClassName="highlighted-blog-post__mobile-image"
                        />

                        {blogPost.intro && (
                            <p className="highlighted-blog-post__description">
                                {blogPost.intro}
                            </p>
                        )}

                        <LinkIconButton
                            to={blogPostUrl}
                            icon="arrow-short-right"
                            iconPos={HorizontalAlignment.right}
                            text={trans('common.readMore')}
                            className="highlighted-blog-post__button"
                        />
                    </div>

                    <div className="highlighted-blog-post__image-wrapper">
                        <Picture
                            {...blogPost.image}
                            className="highlighted-blog-post__picture"
                            imageClassName="highlighted-blog-post__image"
                        />
                    </div>
                </div>
            </Wrapper>
        </section>
    );
};
