interface OpenStreetMapGeometryResource {
    type: string;
    coordinates: [number, number];
}

interface OpenStreetMapAddressPropertiesResource {
    addresstype: string;
    category: string;
    display_name: string;
    importance: number;
    name: string;
    osm_id: number;
    osm_type: string;
    place_id: number;
    place_rank: number;
    type: string;
}

export interface OpenStreetMapFeatureResource {
    bbox: [number, number, number, number];
    geometry: OpenStreetMapGeometryResource;
    properties: OpenStreetMapAddressPropertiesResource;
}

export interface OpenStreetMapResource {
    type: string;
    licence: string;
    features: OpenStreetMapFeatureResource[];
}

export const osmDisplayNameSplitCharacter = ', Nederland';
export const osmGeometrySplitCharacter = ', ';
