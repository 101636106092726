import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { IconColumnsInterface } from '../../../entities/@blocks/IconColumns/IconColumns';
import { IconColumn } from './subcomponents';

import './IconColumns.scss';

interface IconColumnsProps extends IconColumnsInterface {
    className?: string;
}

export const IconColumns: FC<IconColumnsProps> = ({
    title,
    columns,
    className = '',
}): ReactElement => (
    <section className={`icon-columns ${className}`}>
        <Wrapper className="icon-columns__wrapper">
            {title && (
                <h2 className="icon-columns__title">
                    {title}
                </h2>
            )}

            <div className="icon-columns__columns">
                {columns.map(column => (
                    <IconColumn
                        {...column.content}
                        key={column.id}
                        className="icon-columns__column"
                    />
                ))}
            </div>
        </Wrapper>
    </section>
);
