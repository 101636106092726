import { CartItem } from '../../entities/Cart/Cart';
import { MOCK_PRODUCT_1, MOCK_PRODUCT_2 } from './product';

export const MOCK_CART: CartItem[] = [
    {
        product: MOCK_PRODUCT_1,
        quantity: 2,
    },
    {
        product: MOCK_PRODUCT_2,
        quantity: 1,
    },
];
