/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconFilter: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <line className="icon__stroke" x1="9.4" y1="34.4" x2="90.6" y2="34.4" />
        <line className="icon__stroke" x1="25" y1="53.1" x2="75" y2="53.1" />
        <line className="icon__stroke" x1="40.6" y1="71.9" x2="59.4" y2="71.9" />
    </svg>
));
