import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ProductCard } from '../../compositions';
import { ConnectedNewsletterCallToAction } from '../../connectors';
import {
    BrandCarousel,
    FeaturedProducts,
    MediaBanner,
    ProductCollection,
    SliderSection,
    TextMedia,
} from '../../containers';
import { FeaturedProductsType } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { LinkTarget } from '../../entities/Link/Link';
import {
    MOCK_BRANDS,
    MOCK_IMAGE_BANNER_1,
    MOCK_PRODUCT_4,
    MOCK_PRODUCT_5,
    MOCK_PRODUCT_6,
    MOCK_PRODUCT_7,
    MOCK_PRODUCT_COLLECTION,
    MOCK_PRODUCTS,
    MOCK_VIDEO,
} from '../../mock/mock-data';

import './HomePage.scss';

export const HomePage: FC = (): ReactElement => (
    <Page className="home-page">
        <MediaBanner
            media={{
                id: '1',
                video: MOCK_VIDEO,
            }}
            title="Bold & Bijzonder"
            titleColor="#E8CD4B"
            link={{
                label: 'Bekijk trend',
                href: '/',
                target: LinkTarget.self,
            }}
            linkBackgroundColor="#828474"
            className="home-page__media-banner-1"
        />

        <FeaturedProducts
            layoutType={FeaturedProductsType.verticalTitle}
            title="Featured Products"
            products={MOCK_PRODUCTS}
            className="home-page__featured-products-1"
        />

        <TextMedia
            media={{
                id: '1',
                video: MOCK_VIDEO,
            }}
            title="Loods 5 heeft alles in huis"
            descriptionHtml="<q>Op zoek naar prachtige interieurbasics en trendy items? Loods 5 heeft een gigantisch aanbod!</q>"
            link={{
                label: 'Bekijk onze producten',
                href: '/',
                target: LinkTarget.self,
            }}
            className="home-page__section home-page__text-media"
        />

        <ProductCollection
            productCollection={MOCK_PRODUCT_COLLECTION}
            products={MOCK_PRODUCTS}
            title="Loods 5 Buitencollectie"
            titleColor="#bbb660"
            footerLink={{
                label: 'Bekijk alle producten',
                href: '/producten',
            }}
            className="home-page__section home-page__collection"
        />

        <FeaturedProducts
            layoutType={FeaturedProductsType.alternatingSizes}
            title="Featured Products"
            products={[MOCK_PRODUCT_4, MOCK_PRODUCT_5, MOCK_PRODUCT_6, MOCK_PRODUCT_7]}
            link={{
                label: 'Bekijk alle producten',
                href: '/producten',
            }}
            className="home-page__section home-page__featured-products-2"
        />

        <SliderSection
            id="new-example"
            title="Nieuw bij Loods 5"
            items={[...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS].map(product => (
                <ProductCard
                    product={product}
                    className="home-page__slider-product"
                />
            ))}
            className="home-page__section home-page__slider-1"
        />

        <MediaBanner
            media={{
                id: '1',
                image: MOCK_IMAGE_BANNER_1,
            }}
            link={{
                label: 'Bekijk onze producten',
                href: '/',
                target: LinkTarget.self,
            }}
            className="home-page__section home-page__media-banner-2"
        />

        <SliderSection
            id="sale-example"
            title="Sale"
            items={[...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS].map((product => (
                <ProductCard
                    product={product}
                    className="home-page__slider-product"
                />
            )))}
            className="home-page__section home-page__slider-2"
        />

        <BrandCarousel
            title="Merken shoppen"
            logos={MOCK_BRANDS}
            className="home-page__section home-page__brands"
        />

        <ConnectedNewsletterCallToAction
            showStoreLocator
            title="Wekelijks inspiratie in je inbox?"
            description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
            textColor="#000000"
            className="home-page__section home-page__sub-footer"
        />
    </Page>
);
