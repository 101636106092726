export const registerServiceWorker = async (options?: RegistrationOptions): Promise<void> => {
    if (!navigator.serviceWorker) {
        return;
    }

    await navigator.serviceWorker.register('/service-worker.js', options);

    // Purge expired cache files that follow the Cache first strategy
    // Configure in service-worker, or remove entirely if cache should have no expiration date
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(JSON.stringify({
            action: 'purge-cache',
        }));
    }
};
