import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Breadcrumbs, Page, Wrapper } from '../../components';
import { ProductInformation } from '../../compositions';
import { ProductDetail } from '../../containers/@blocks/ProductDetail/ProductDetail';
import { MOCK_PRODUCTS } from '../../mock/mock-data';

import './ProductDetailPage.scss';

export const ProductDetailPage: FC = (): ReactElement => {
    const { productId, slug } = useParams();

    // @TODO: Get from API
    const product = MOCK_PRODUCTS.find((p) => p.id === productId)
        || MOCK_PRODUCTS.find((p) => p.slug === slug)
        || MOCK_PRODUCTS[0];

    return (
        <Page className="product-detail-page">
            <Wrapper>
                <Breadcrumbs
                    breadcrumbs={[
                        { title: 'Home', url: '/' },
                        { title: 'Verlichting', url: '/verlichting' },
                        { title: 'Tafel- & Bureaulampen', url: '' },
                    ]}
                />
            </Wrapper>

            <Wrapper className="product-detail-page__detail-wrapper">
                <ProductDetail
                    product={product}
                    className="product-detail-page__block"
                />
            </Wrapper>

            <Wrapper>
                <ProductInformation
                    product={product}
                    className="product-detail-page__block"
                />
            </Wrapper>
        </Page>
    );
};
