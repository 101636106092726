import { useState } from 'react';

import { UserAddress } from '../User/User';
import { Address, AddressFieldState } from './Address';

export const transformAddresses = (addresses: UserAddress[]): AddressFieldState[] => addresses.map((address) => {
    const [state, setStateInternal] = useState<Address>({
        street: address.street || '',
        postalCode: address.postalCode || '',
        houseNumber: address.houseNumber || '',
        city: address.city || '',
        country: address.country || '',
    });

    const setState = (field: keyof Address, value: string) => {
        setStateInternal((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    return {
        type: address.type,
        state,
        setState,
    };
});
