import { FC, ReactElement, useState } from 'react';

import { TabList } from '../../compositions';
import { AccountDashboardOrderTab } from '../../entities/Account/Account';
import { Order } from '../../entities/Order/Order';
import { useTrans } from '../../hooks';
import { OrderItems } from './subcomponents';

import './AccountDashboardOrders.scss';

interface AccountDashboardOrdersProps {
    onlineOrders: Order[];
    inStoreOrders: Order[];
    className?: string;
}

export const AccountDashboardOrders: FC<AccountDashboardOrdersProps> = ({
    onlineOrders,
    inStoreOrders,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [activeTab, setActiveTab] = useState<AccountDashboardOrderTab>(AccountDashboardOrderTab.onlinePurchase);

    const tabs = [
        { label: trans('containers.accountDashboardOrders.onlinePurchase'), value: AccountDashboardOrderTab.onlinePurchase },
        { label: trans('containers.accountDashboardOrders.storePurchase'), value: AccountDashboardOrderTab.storePurchase },
    ];

    const handleOrdersTabSelect = (value: string): void => {
        const selectedTab = value as AccountDashboardOrderTab;

        setActiveTab(selectedTab);
    };

    return (
        <section className={`account-dashboard-orders ${className}`}>
            <header className="account-dashboard-orders__header">
                <h2 className="account-dashboard-orders__heading">
                    {trans('pages.accountDashboard.myLastOrders')}
                </h2>
            </header>

            <TabList
                tabs={tabs}
                activeTab={activeTab}
                onTabSelect={handleOrdersTabSelect}
                className="account-dashboard-orders__tabs"
                tabItemLabelClassName="account-dashboard-orders__tabs-item-label"
            />

            {activeTab === AccountDashboardOrderTab.onlinePurchase && (
                <OrderItems orders={onlineOrders} />
            )}

            {activeTab === AccountDashboardOrderTab.storePurchase && (
                <OrderItems orders={inStoreOrders} />
            )}
        </section>
    );
};
