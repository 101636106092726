/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconArrowLeft: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polyline className="icon__stroke" points="35.9 80.4 6.3 50 35.9 19.6" />
        <line className="icon__stroke" x1="98" y1="50" x2="6.3" y2="50" />
    </svg>
));
