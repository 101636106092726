/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconPhone: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M13,17.5l22.1-4.1,8,20.1-9.5,9.7s1.1,4.2,9.2,12.7,14.2,10.4,14.2,10.4l9.4-8.8,20.8,7.7-3.5,22.5c-17.4-1.5-35.7-3.7-54.5-24.1S13,32.5,13,17.5Z" />
    </svg>
));
