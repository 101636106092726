/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconYouTube: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 18 24" className={`icon ${className}`}>
        <path
            d="M17.1562 7.90625C17.5312 9.21875 17.5312 12.0312 17.5312 12.0312C17.5312 12.0312 17.5312 14.8125 17.1562 16.1562C16.9688 16.9062 16.375 17.4688 15.6562 17.6562C14.3125 18 9 18 9 18C9 18 3.65625 18 2.3125 17.6562C1.59375 17.4688 1 16.9062 0.8125 16.1562C0.4375 14.8125 0.4375 12.0312 0.4375 12.0312C0.4375 12.0312 0.4375 9.21875 0.8125 7.90625C1 7.15625 1.59375 6.5625 2.3125 6.375C3.65625 6 9 6 9 6C9 6 14.3125 6 15.6562 6.375C16.375 6.5625 16.9688 7.15625 17.1562 7.90625ZM7.25 14.5625L11.6875 12.0312L7.25 9.5V14.5625Z"
        />
    </svg>
));
