import { FC, PropsWithChildren, ReactElement } from 'react';

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { InstantSearch } from 'react-instantsearch';

const meiliSearchClient = {
    hostUrl: process.env.REACT_APP_MEILISEARCH_HOST_URL || 'missing-host-url',
    apiKey: process.env.REACT_APP_MEILISEARCH_API_KEY,
};

const { searchClient } = instantMeiliSearch(meiliSearchClient.hostUrl, meiliSearchClient.apiKey);

const indexName: string = 'variants_nl';

export const ConnectedInstantSearchInstance: FC<PropsWithChildren> = ({ children }): ReactElement => (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
        {children}
    </InstantSearch>
);
