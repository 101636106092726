import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServiceFaqPageRenderer } from '../../../connectors';

import './CustomerServiceFaqPage.scss';

export const CustomerServiceFaqPage: FC = (): ReactElement => (
    <Page className="customer-service-faq-page">
        <ConnectedCustomerServiceFaqPageRenderer />
    </Page>
);
