import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import {
    ButtonExamples,
    ColourExamples,
    IconExamples,
    InputExamples,
    LogoExamples,
    TypographyExamples,
    WayfindingExamples,
} from './subcomponents';

import './StyleguidePage.scss';

export const StyleguidePage: FC = (): ReactElement => (
    <Page className="styleguide-page">
        <Wrapper>
            <section className="styleguide-page__section">
                <TypographyExamples className="styleguide-page__section-examples" />
            </section>

            <section className="styleguide-page__section">
                <h2 className="styleguide-page__section-title">
                    Colours
                </h2>

                <ColourExamples className="styleguide-page__section-examples" />
            </section>

            <section className="styleguide-page__section">
                <h2 className="styleguide-page__section-title">
                    Logo
                </h2>

                <LogoExamples className="styleguide-page__section-examples" />
            </section>

            <section className="styleguide-page__section">
                <h2 className="styleguide-page__section-title">
                    Icons
                </h2>

                <IconExamples className="styleguide-page__section-examples" />
            </section>

            <section className="styleguide-page__section">
                <h2 className="styleguide-page__section-title">
                    Buttons
                </h2>

                <ButtonExamples className="styleguide-page__section-examples" />
            </section>

            <section className="styleguide-page__section">
                <h2 className="styleguide-page__section-title">
                    Inputs
                </h2>

                <InputExamples className="styleguide-page__section-examples" />
            </section>

            <section className="styleguide-page__section">
                <h2 className="styleguide-page__section-title">
                    Wayfinding
                </h2>

                <WayfindingExamples className="styleguide-page__section-examples" />
            </section>
        </Wrapper>
    </Page>
);
