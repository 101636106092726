import { FC, ReactElement } from 'react';

import { RegistrationForm } from '../../../containers';
import { RegistrationFormData } from '../../../entities/@forms/RegistrationForm/RegistrationForm';
import { registerUser } from '../../../redux/registration/registrationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedRegistrationFormProps {
    className?: string;
}

export const ConnectedRegistrationForm: FC<ConnectedRegistrationFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.registrationSlice.isLoading);
    const error = useTypedSelector(state => state.registrationSlice.error);

    const handleSubmit = (formData: RegistrationFormData): void => {
        dispatch(registerUser(formData));
    };

    return (
        <RegistrationForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
