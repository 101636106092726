import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import { useRefinementList } from 'react-instantsearch';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Checkbox } from '../../../../compositions';
import { FormOption } from '../../../../entities/Form/Form';
import { ProductFilter } from '../../../../entities/ProductFilters/ProductFilters';

import './InstantSearchRefinementList.scss';

interface InstantSearchRefinementListProps extends ProductFilter {
    className?: string;
}

export const InstantSearchRefinementList: FC<InstantSearchRefinementListProps> = ({
    attribute,
    label,
    operator,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isFirstRender = useRef(true);

    const { refine, items } = useRefinementList({
        attribute,
        operator,
        sortBy: ['name:asc'],
    });

    const getRefinementValuesFromUrl = (): string[] => searchParams.get(label.toLowerCase())?.split(',') ?? [];

    const initialiseRefinementFromUrl = (): void => {
        getRefinementValuesFromUrl().forEach(refine);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            initialiseRefinementFromUrl();
            isFirstRender.current = false;
        }
    }, []);

    const updateUrlWithRefinement = (optionValue: string, isChecked: boolean): void => {
        const currentValues = getRefinementValuesFromUrl();

        const updatedFilters = isChecked
            ? [...currentValues, optionValue]
            : currentValues.filter(value => value !== optionValue);

        const newParams = new URLSearchParams(searchParams);
        const paramKey = label.toLowerCase();

        if (updatedFilters.length === 0) {
            newParams.delete(paramKey);
        } else {
            newParams.set(paramKey, updatedFilters.join(','));
        }

        navigate({ search: newParams.toString() });
    };

    const toggleRefinement = (option: FormOption, isChecked: boolean): void => {
        refine(option.value);
        updateUrlWithRefinement(option.value, isChecked);
    };

    return (
        <div className={`instant-search-refinement-list ${className}`}>
            {items.map(option => {
                const isItemChecked = getRefinementValuesFromUrl().includes(option.value);

                const handleOnChange = (isChecked: boolean): void => toggleRefinement(option, isChecked);

                return (
                    <Checkbox
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        checked={isItemChecked}
                        onChange={handleOnChange}
                    />
                );
            })}
        </div>
    );
};
