import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { InteractiveProductHeader } from '../../containers';
import {
    MOCK_INTERACTIVE_PRODUCT_HEADER_IMAGE_1,
    MOCK_PRODUCT_1,
    MOCK_PRODUCT_2,
    MOCK_PRODUCT_3,
} from '../../mock/mock-data';

export const HomeWithInteractiveProductHeaderPage: FC = (): ReactElement => (
    <Page className="home-with-interactive-product-header-page">
        <InteractiveProductHeader
            image={MOCK_INTERACTIVE_PRODUCT_HEADER_IMAGE_1}
            products={[
                {
                    id: '1',
                    product: MOCK_PRODUCT_1,
                    position: { x: 72, y: 70 },
                    mobilePosition: { x: 85, y: 72 },
                },
                {
                    id: '2',
                    product: MOCK_PRODUCT_2,
                    position: { x: 60, y: 45 },
                    mobilePosition: { x: 64, y: 50 },
                },
                {
                    id: '3',
                    product: MOCK_PRODUCT_3,
                    position: { x: 30, y: 80 },
                    mobilePosition: { x: 12, y: 70 },
                },
            ]}
        />
    </Page>
);
