import { transformToBlock } from '../Block/BlockTransformers';
import { PageInterface, PageResource } from './Page';

export const transformToPage = (resource: PageResource): PageInterface => {
    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    return {
        id: resource.documentId,
        title: resource.title || '',
        description: resource.description || '',
        blocks,
    };
};
