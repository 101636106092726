import { FC, ReactElement, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { BlogOverview } from '../../../containers';
import { BlogOverviewBlockInterface } from '../../../entities/@blocks/BlogOverview/BlogOverview';
import { StrapiQuerySortDirection } from '../../../entities/Strapi/Strapi';
import { generateStoreKey } from '../../../helpers/store';
import { useClientEffect } from '../../../hooks';
import { fetchBlogOverview } from '../../../redux/@blog/blogOverviews/blogOverviewsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedBlogOverviewProps extends BlogOverviewBlockInterface {
    className?: string;
}

export const ConnectedBlogOverview: FC<ConnectedBlogOverviewProps> = ({
    categories,
    amount = 12,
    className = '',
    ...blogOverviewProps
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.blogOverviewsSlice.isLoading);
    const blogOverviews = useTypedSelector(state => state.blogOverviewsSlice.overviews);

    const initialActivePage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const [activePage, setActivePage] = useState<number>(initialActivePage);

    const key = generateStoreKey(categories);

    useClientEffect((): void => {
        setSearchParams({
            page: activePage.toString(),
        }, {
            replace: true,
        });

        dispatch(fetchBlogOverview({
            key,
            categories,
            sort: {
                date: StrapiQuerySortDirection.desc,
            },
            pagination: {
                pageSize: amount,
                page: activePage,
            },
        }));
    }, [activePage, categories]);

    return (
        <BlogOverview
            {...blogOverviewProps}
            isLoading={isLoading}
            blogPosts={blogOverviews[key]?.blogPosts || []}
            onPaginationChange={setActivePage}
            className={className}
        />
    );
};
