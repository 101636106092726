import { FC, ReactElement, useState } from 'react';

import { Page } from '../../components';
import { ConnectedBlogCategories, ConnectedBlogOverview } from '../../connectors';

import './InspirationPage.scss';

export const InspirationPage: FC = (): ReactElement => {
    const [activeCategory, setActiveCategory] = useState<string>('');

    return (
        <Page className="inspiration-page">
            <ConnectedBlogCategories
                onChange={setActiveCategory}
                className="inspiration-page__categories"
            />

            <ConnectedBlogOverview
                showPagination
                categories={[activeCategory]}
                className="inspiration-page__blog-overview"
            />
        </Page>
    );
};
