import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServiceOrderingPageRenderer } from '../../../connectors';

import './CustomerServiceOrderingPage.scss';

export const CustomerServiceOrderingPage: FC = (): ReactElement => (
    <Page className="customer-service-ordering-page">
        <ConnectedCustomerServiceOrderingPageRenderer />
    </Page>
);
