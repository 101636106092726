import { FC, ReactElement, useState } from 'react';

import { Cart } from '../../containers';
import { MOCK_CART } from '../../mock/mock-data/cart';

export const ConnectedCart: FC = (): ReactElement => {
    // TODO: Fetch cart data from API/Local Storage
    const cartData = MOCK_CART;

    const [removingCartItemId, setRemovingCartItemId] = useState<string | null>(null);

    const handleUpdateCartItemQuantity = (productId: string, quantity: number) => {
        console.log('handleUpdateCartItemQuantity', productId, quantity);

        cartData.forEach((item) => {
            if (item.product.id === productId) {
                // eslint-disable-next-line no-param-reassign
                item.quantity = quantity;
            }
        });

        // TODO: Update cart with new quantity of cart item (state (LS/API)
    };

    const handleRemoveItemFromCart = (productId: string) => {
        console.log('handleRemoveItemFromCart', productId);

        setRemovingCartItemId(null);

        // TODO: Add query to remove product to cart
    };

    const handleCartItemIsInWishlist = (productId: string) => {
        console.log('handleCartItemIsInWishlist', productId);

        // TODO: Add query to add to wishlist
    };

    return (
        <Cart
            cartData={cartData}
            totalPrice={124.95}
            removingCartItemId={removingCartItemId}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onUpdateCartItemIsInWishlist={handleCartItemIsInWishlist}
        />
    );
};
