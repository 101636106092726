import { FC, ReactElement } from 'react';

import { Block } from '../../entities/Block/Block';
import { BlockRenderer } from '..';

import './BrandRenderer.scss';

interface BrandRendererProps {
    isLoading?: boolean;
    blocks?: Block[];
    className?: string;
}

export const BrandRenderer: FC<BrandRendererProps> = ({
    isLoading,
    blocks,
    className = '',
}): ReactElement => (
    <div className={`brand-renderer ${className}`}>
        <BlockRenderer
            isLoading={isLoading}
            blocks={blocks}
            className="brand-renderer__block-renderer"
        />
    </div>
);
