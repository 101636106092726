import {
    FC,
    PropsWithChildren,
    ReactPortal,
    useEffect,
    useRef,
    useState,
} from 'react';

import { createPortal } from 'react-dom';

export const RootPortal: FC<PropsWithChildren> = ({ children }): ReactPortal | null => {
    const portalRef = useRef<HTMLDivElement>(null);
    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect((): void => {
        // @ts-ignore
        portalRef.current = document.getElementById('portal-root');
        setIsMounted(true);
    }, []);

    if (!isMounted || !portalRef.current) {
        return null;
    }

    return createPortal(children, portalRef.current);
};
