import { FC, ReactElement } from 'react';

import { CustomerServiceFaqPageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServiceFaqPage } from '../../../redux/@pages/customerServiceFaqPage/customerServiceFaqPageActions';
import { fetchFaqItems } from '../../../redux/faq/faqActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServiceFaqPageRendererProps {
    className?: string;
}

export const ConnectedCustomerServiceFaqPageRenderer: FC<ConnectedCustomerServiceFaqPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoadingFaqItems = useTypedSelector(state => state.faqSlice.isLoading);
    const faqItems = useTypedSelector(state => state.faqSlice.faqItems);

    const isLoadingBlocks = useTypedSelector(state => state.customerServiceFaqPageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServiceFaqPageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchFaqItems());
        dispatch(fetchCustomerServiceFaqPage());
    }, []);

    return (
        <CustomerServiceFaqPageRenderer
            isLoadingFaqItems={isLoadingFaqItems}
            isLoadingBlocks={isLoadingBlocks}
            faqItems={faqItems}
            blocks={blocks}
            className={className}
        />
    );
};
