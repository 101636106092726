/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconGrid: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect className="icon__stroke" x="59.1" y="11.1" width="29.9" height="29.9" />
        <rect className="icon__stroke" x="11.1" y="11.1" width="29.9" height="29.9" />
        <rect className="icon__stroke" x="59.1" y="59.1" width="29.9" height="29.9" />
        <rect className="icon__stroke" x="11.1" y="59.1" width="29.9" height="29.9" />
    </svg>
));
