import { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { StoreWithWorkingHours } from '../../../../entities/Store/Store';
import { getOpenStatusInfo } from '../../../../helpers/workingHours';
import { useDeviceWidth, useTrans } from '../../../../hooks';

import './Stores.scss';

interface StoresProps {
    locations: StoreWithWorkingHours[];
    onClickSeeAll: () => void;
    className?: string;
}

export const Stores: FC<StoresProps> = ({
    locations,
    onClickSeeAll,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const locationsWithOpenStatus = locations.map(location => ({
        ...location,
        ...getOpenStatusInfo(location.workingHours, true),
    }));

    return (
        <div className={`stores ${className}`}>
            <p className="stores__label">
                {trans('containers.searchPopup.openingHours')}
            </p>

            <ul>
                {locationsWithOpenStatus.map(location => (
                    <li
                        key={location.id}
                        className="stores__list-item"
                    >
                        <p className="stores__location-name">
                            {location.name}
                        </p>

                        <p className="stores__location-open-status">
                            {isMobile ? location.shortOpenStatus : location.openStatus}
                        </p>

                        <LinkIconButton
                            isSmall
                            text={trans('containers.searchPopup.route')}
                            iconPos={HorizontalAlignment.right}
                            to={location.link.href}
                            icon="arrow-short-right"
                            target={location.link.target}
                            className="stores__link"
                            iconClassName="stores__link-icon"
                        />
                    </li>
                ))}
            </ul>

            <LinkIconButton
                isSmall
                icon="arrow-short-right"
                text={trans('containers.searchPopup.allOpeningHours')}
                iconPos={HorizontalAlignment.right}
                to={trans(appRoutes[AppRoute.storeOverview].path)}
                onClick={onClickSeeAll}
                className="stores__show-all-link"
                labelClassName="stores__show-all-link-label"
                iconClassName="stores__link-icon"
            />
        </div>
    );
};

