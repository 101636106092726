import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { OptionalWorkingHours } from '../../entities/@blocks/ServiceSection/ServiceSection';
import { LinkTarget } from '../../entities/Link/Link';
import { getOpenStatusInfo } from '../../helpers/workingHours';
import { useTrans } from '../../hooks';

import './ContactOption.scss';

interface OpenStatusProps {
    linkText: string;
    linkUrl: string;
    workingHours: OptionalWorkingHours[];
    className?: string;
}

export const ContactOption: FC<OpenStatusProps> = ({
    linkText,
    linkUrl,
    workingHours,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const { shortOpenStatus, openStatus } = getOpenStatusInfo(workingHours);

    const isOpen = shortOpenStatus === trans('common.open');

    const statusClassNames = classNames('contact-option__status', {
        'contact-option__status--is-open': isOpen,
        'contact-option__status--is-closed': !isOpen,
    });

    return (
        <div className={`contact-option ${className}`}>
            <Link
                to={linkUrl}
                target={linkUrl.startsWith('http') ? LinkTarget.blank : LinkTarget.top}
                rel={linkUrl.startsWith('http') ? 'noreferrer' : 'opener'}
                className="contact-option__link"
            >
                {linkText}
            </Link>

            <div className={statusClassNames}>
                <span className="contact-option__status-indicator" />

                <span className="contact-option__status-text">
                    {openStatus}
                </span>
            </div>
        </div>
    );
};
