import { FC, ReactElement, useState } from 'react';

import { TabList } from '../../compositions';
import { AccountDashboardConfigTab } from '../../entities/Account/Account';
import { User } from '../../entities/User/User';
import { useTrans } from '../../hooks';
import { Giftcard, MyAddresses, MyData } from './subcomponents';

import './AccountDashboardConfig.scss';

interface AccountDashboardConfigProps {
    user: User;
    className?: string;
}

export const AccountDashboardConfig: FC<AccountDashboardConfigProps> = ({
    user,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [activeTab, setActiveTab] = useState<AccountDashboardConfigTab>(AccountDashboardConfigTab.myData);

    const configTabs = [
        { label: trans('pages.accountDashboard.myData'), value: AccountDashboardConfigTab.myData },
        { label: trans('pages.accountDashboard.myAddresses'), value: AccountDashboardConfigTab.myAddresses },
        { label: trans('containers.accountDashboardConfig.giftcard.giftcard'), value: AccountDashboardConfigTab.giftcard },
    ];

    const handleConfigTabSelect = (value: string): void => {
        const selectedTab = value as AccountDashboardConfigTab;

        setActiveTab(selectedTab);
    };

    return (
        <div className={`account-dashboard-config ${className}`}>
            <TabList
                tabs={configTabs}
                activeTab={activeTab}
                onTabSelect={handleConfigTabSelect}
                className="account-dashboard-config__tabs"
                tabItemLabelClassName="account-dashboard-config__tabs-item-label"
            />

            {activeTab === AccountDashboardConfigTab.myData && (
                <MyData
                    user={user}
                    onSubmit={console.log}
                />
            )}

            {activeTab === AccountDashboardConfigTab.myAddresses && (
                <MyAddresses
                    addresses={user.addresses}
                    onSubmit={console.log}
                />
            )}

            {activeTab === AccountDashboardConfigTab.giftcard && (
                <Giftcard />
            )}
        </div>
    );
};
