import { FC, ReactElement } from 'react';

import { AddressList } from '../../../../compositions';
import { User } from '../../../../entities/User/User';

import './MyData.scss';

interface MyDataProps {
    user: User;
    className?: string;
}

export const MyData: FC<MyDataProps> = ({
    user,
    className = '',
}): ReactElement => (
    <div className={`my-data ${className}`}>
        <AddressList
            addresses={user.addresses}
            className="my-data__addresses"
        />
    </div>
);
