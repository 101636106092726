export interface TokenResource {
    readonly token: string;
    readonly customer: string;
}

export interface UnauthorizedTokenResource {
    readonly code: number;
    readonly message: string;
}

export const isUnauthorizedResponse = (response: TokenResource | UnauthorizedTokenResource): response is UnauthorizedTokenResource => (
    (<UnauthorizedTokenResource>response).code !== undefined
);

export const tokenKey = 'syliusToken';

export interface Token {
    customerId: string;
    token: string;
}
