/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconWayfindingRight: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={`icon__stroke ${pathClassName}`} d="M65.4,56.6H9.6v-12.5h57.2l-.7-.7-21.5-21.5,8.8-8.8,37.1,37.1-37.1,37.1-8.8-8.8,21.1-21.1.7-.7h-1Z" />
    </svg>
));
