export enum AddressType {
    billing = 'billing',
    shipping = 'shipping',
}

export interface Address {
    name?: string;
    street: string;
    postalCode: string;
    houseNumber: string;
    city: string;
    country: string;
}

export interface AddressFieldState {
    type: AddressType;
    state: Address;
    setState: (field: keyof Address, value: string) => void;
}
