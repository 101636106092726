import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ConnectedNewsletterCallToAction, ConnectedRegistrationForm } from '../../connectors';
import { ServiceSection, UspSection } from '../../containers';
import { MOCK_SERVICE_CONTACT_DETAILS } from '../../mock/mock-data/contact';

import './RegistrationPage.scss';

interface RegistrationPageProps {
    className?: string;
}

export const RegistrationPage: FC<RegistrationPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`registration-page ${className}`}>
        <Wrapper className="registration-page__wrapper">
            <ConnectedRegistrationForm
                className="registration-page__registration-form"
            />
        </Wrapper>

        <UspSection
            items={[
                {
                    id: 0,
                    text: 'Online besteld? Gratis verzonden',
                    icon: 'cart-truck',
                },
                {
                    id: 1,
                    text: 'Keuze uit meer dan 300 meubelmerken',
                    icon: 'lamp',
                },
                {
                    id: 2,
                    text: 'Niet tevreden? Geld terug!',
                    icon: 'happy',
                },
            ]}
            className="registration-page__section"
        />

        <div className="registration-page__section registration-page__service-section">
            <Wrapper className="contact-page__wrapper">
                <ServiceSection
                    title="Service & contact"
                    serviceContactDetails={MOCK_SERVICE_CONTACT_DETAILS}
                />
            </Wrapper>
        </div>

        <ConnectedNewsletterCallToAction
            title="Wekelijks inspiratie in je inbox?"
            description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
            textColor="#000000"
            className="registration-page__section registration-page__sub-footer"
        />
    </Page>
);
