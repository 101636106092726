import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Tab } from '../../../../entities/Tab/Tab';
import { Button } from '../../..';

import './TabItem.scss';

interface TabItemProps extends Tab {
    isActive: boolean;
    onClick: (value: string) => void;
    className?: string;
    labelClassName?: string;
}

export const TabItem: FC<TabItemProps> = ({
    isActive,
    value,
    label,
    onClick,
    className = '',
    labelClassName = '',
}): ReactElement => {
    const tabItemClassNames = classNames('tab-item', {
        'tab-item--is-active': isActive,
    }, className);

    const handleClick = (): void => onClick(value);

    return (
        <li className={tabItemClassNames}>
            <Button
                text={label}
                onClick={handleClick}
                className="tab-item__button"
                labelClassName={`tab-item__button-label ${labelClassName}`}
            />
        </li>
    );
};
