import { FC, ReactElement, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { useLocale } from './context';
import { AppRoute } from './entities/Routing/Routing';
import { useTrans } from './hooks';
import {
    AccountPage,
    BlogPostPage,
    BrandDetailPage,
    CartPage,
    ContentBlocksPage,
    CustomerServiceAccountPage,
    CustomerServiceDeliveryPage,
    CustomerServiceFaqPage,
    CustomerServiceOrderingPage,
    CustomerServicePage,
    CustomerServiceReturnsPage,
    CustomerServiceTermsPage,
    ForgotPasswordPage,
    HomePage,
    HomeWithInteractiveProductHeaderPage,
    IndexPage,
    InspirationPage,
    LoginPage,
    NotFoundPage,
    ProductCategoryPage,
    ProductDetailPage,
    RegistrationPage,
    SearchPage,
    StoreDetailPage,
    StoreOverviewPage,
    StyleguidePage,
    WishlistPage,
} from './pages';

export interface RouteData<T = string> {
    key: T;
    label: string;
    path: string;
}

export type RouteParams = Record<string, string | undefined>;

const routes = [
    // Account
    {
        key: AppRoute.account,
        element: <AccountPage />,
        children: (
            <Route path=":tab" element={<AccountPage />}>
                <Route path=":id" element={<AccountPage />} />
            </Route>
        ),
    },
    // Content
    { key: AppRoute.customerService, element: <CustomerServicePage /> },
    { key: AppRoute.customerServiceAccount, element: <CustomerServiceAccountPage /> },
    { key: AppRoute.customerServiceDelivery, element: <CustomerServiceDeliveryPage /> },
    { key: AppRoute.customerServiceFaq, element: <CustomerServiceFaqPage /> },
    { key: AppRoute.customerServiceOrdering, element: <CustomerServiceOrderingPage /> },
    { key: AppRoute.customerServiceReturns, element: <CustomerServiceReturnsPage /> },
    { key: AppRoute.customerServiceTerms, element: <CustomerServiceTermsPage /> },
    // Other
    { key: AppRoute.blogPost, element: <BlogPostPage /> },
    { key: AppRoute.brandDetail, element: <BrandDetailPage /> },
    { key: AppRoute.cart, element: <CartPage /> },
    { key: AppRoute.contentBlocks, element: <ContentBlocksPage /> },
    { key: AppRoute.forgotPassword, element: <ForgotPasswordPage /> },
    { key: AppRoute.home, element: <HomePage /> },
    { key: AppRoute.homeWithInteractiveProductHeader, element: <HomeWithInteractiveProductHeaderPage /> },
    { key: AppRoute.index, element: <IndexPage /> },
    { key: AppRoute.inspiration, element: <InspirationPage /> },
    { key: AppRoute.login, element: <LoginPage /> },
    { key: AppRoute.notFound, element: <NotFoundPage /> },
    { key: AppRoute.productCategory, element: <ProductCategoryPage /> },
    { key: AppRoute.productDetail, element: <ProductDetailPage /> },
    { key: AppRoute.registration, element: <RegistrationPage /> },
    { key: AppRoute.search, element: <SearchPage /> },
    { key: AppRoute.storeOverview, element: <StoreOverviewPage /> },
    { key: AppRoute.storeDetail, element: <StoreDetailPage /> },
    { key: AppRoute.styleguide, element: <StyleguidePage /> },
    { key: AppRoute.wishlist, element: <WishlistPage /> },
];

export const LocalizedRoutes: FC = (): ReactElement => {
    const { language } = useLocale();
    const trans = useTrans();

    // Get route paths and labels from translations, based on active language
    const localizedRoutes = useMemo(() => routes.map(route => {
        const routeData = {
            path: route.key !== AppRoute.notFound
                ? trans(`${route.key}.path`)
                : '*',
            label: trans(`${route.key}.label`),
        };

        return {
            ...route,
            ...routeData,
        };
    }), [language]);

    return (
        <Routes>
            {localizedRoutes.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};
