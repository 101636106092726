import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../../../components';

import './InstantSearchPagesList.scss';

interface InstantSearchPagesListProps {
    className?: string;
}

export const InstantSearchPagesList: FC<InstantSearchPagesListProps> = ({
    className = '',
}): ReactElement => {
    // TODO: Get pages data from MeiliSearch API (L5W-151)
    const pages = [
        {
            id: '1',
            title: 'Bestellen & betalen',
            category: 'Klantenservice',
            slug: 'bestellen-betalen',
            description: 'Is je online shopper tot de nok gevuld?',
        },
        {
            id: '2',
            title: 'Bezorgen & afhalen',
            category: 'Klantenservice',
            slug: 'bezorgen-afhalen',
            description: 'Is je online shopper tot de nok gevuld?',
        },
        {
            id: '3',
            title: 'Retourneren & ruilen',
            category: 'Nieuws',
            slug: 'retourneren-ruilen',
            description: 'Is je online shopper tot de nok gevuld?',
        },
    ];

    return (
        <ul className={`instant-search-pages-list ${className}`}>
            {pages.map(page => (
                <li key={page.id}>
                    <Link
                        to={`/${page.slug}`}
                        className="instant-search-pages-list__item"
                    >
                        <div>
                            <p className="instant-search-pages-list__label">
                                {page.category}
                            </p>

                            <p className="instant-search-pages-list__title">
                                {page.title}
                            </p>

                            <p className="instant-search-pages-list__description">
                                {page.description}
                            </p>
                        </div>

                        <Icon
                            name="arrow-short-right"
                            className="instant-search-pages-list__icon"
                        />
                    </Link>
                </li>
            ))}
        </ul>
    );
};
