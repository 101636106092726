/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconStar: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon
            className={`icon__stroke ${pathClassName}`}
            points="37.9 37.7 12.2 42.2 30.5 60.9 26.7 86.7 50.1 75.3 73.5 86.7 69.7 60.9 88 42.2 62.2 37.7 50.1 14.5 37.9 37.7"
        />
    </svg>
));
