import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchableOption } from '../../entities/Form/Form';
import { StoreLocation } from '../../entities/Map/Map';
import { MOCK_SHOP_LOCATIONS } from '../../mock/mock-data';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type StoreLocatorState = AsyncReduxState<{
    searchResults: SearchableOption[];
    storeLocations: StoreLocation[];
}>;

const initialState: StoreLocatorState = {
    ...initialAsyncReduxState,
    searchResults: [],
    storeLocations: MOCK_SHOP_LOCATIONS, // TODO: Turn into '[]' and fetch locations from API, once available
};

const storeLocatorSlice = createSlice({
    name: 'storeLocatorSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): StoreLocatorState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): StoreLocatorState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setStoreLocations(state, action: PayloadAction<StoreLocation[]>): StoreLocatorState {
            return {
                ...state,
                storeLocations: action.payload,
            };
        },
        setSearchResults(state, action: PayloadAction<SearchableOption[]>): StoreLocatorState {
            return {
                ...state,
                searchResults: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setStoreLocations,
    setSearchResults,
} = storeLocatorSlice.actions;

export default storeLocatorSlice;
