import { FC, ReactElement } from 'react';

import { UserAddress } from '../../entities/User/User';
import { useTrans } from '../../hooks';

import './AddressCard.scss';

interface AddressesCardProps {
    address: UserAddress;
    typeLabelClassName?: string;
    className?: string;
}

export const AddressCard: FC<AddressesCardProps> = ({
    address,
    typeLabelClassName = '',
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`address-card ${className}`}>
            <b className={`address-card__type ${typeLabelClassName}`}>
                {trans(`compositions.addressCard.${address.type}`)}
            </b>

            {address.name && (
                <p>
                    {address.name}
                </p>
            )}

            <p>
                {`${address.street} `}
                <span>
                    {address.houseNumber}
                </span>
            </p>

            <p>
                {`${address.postalCode}, `}
                <span>
                    {address.city}
                </span>
            </p>

            <p>
                {address.country}
            </p>
        </div>
    );
};
