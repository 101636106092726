/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconDashboard: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M93.8,50l-10.4-8.9M6.2,50l10.4-8.9M16.7,41.1L50,12.5l33.3,28.6M16.7,41.1v48.5h66.7v-48.5M48.9,48.4l1.1,1,1-1c1.7-1.5,3.8-2.5,6-2.5h.3c4.6,0,8.4,3.8,8.4,8.4s-1,4.4-2.7,5.9l-11.4,11.2-1.5,1.5-1.5-1.5-11.6-11.2c-1.7-1.5-2.5-3.6-2.5-5.9,0-4.6,3.6-8.4,8.2-8.4h.3c2.2,0,4.3,1,6,2.5Z" />
    </svg>
));
