import { LinkTarget } from '../Link/Link';
import { FooterNavigation, FooterNavigationItem } from './Footer';

const transformToFooterNavigationItem = (item: FooterNavigationItem): FooterNavigationItem => {
    const target = item.target as LinkTarget || LinkTarget.self;

    const subNavigation = item.subNavigation?.map(listItem => transformToFooterNavigationItem(listItem)) || [];
    const sortedSubNavigation = subNavigation.sort((listItem, comparisonListItem) => listItem.order - comparisonListItem.order);

    return {
        id: item.id,
        order: item.order,
        label: item.label,
        href: item.href,
        target,
        subNavigation: sortedSubNavigation,
        icon: item.icon,
    };
};

export const transformToFooterNavigation = (navigationData: FooterNavigation): FooterNavigation => {
    const navigationItems = navigationData.list.map(item => transformToFooterNavigationItem(item));
    const sortedNavigationItems = navigationItems.sort((item, comparisonItem) => item.order - comparisonItem.order);

    return {
        id: navigationData.id,
        list: sortedNavigationItems,
    };
};
