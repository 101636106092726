import { FC, ReactElement } from 'react';

import { Picture, Wrapper } from '../../components';
import { BrandHeaderInterface } from '../../entities/@blocks/BrandHeader/BrandHeader';
import { useTrans } from '../../hooks';

import './BrandHeader.scss';

export interface BrandHeaderProps extends BrandHeaderInterface {
    className?: string;
}

export const BrandHeader: FC<BrandHeaderProps> = ({
    brand,
    description = '',
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <section className={`brand-header ${className}`}>
            <Wrapper className="brand-header__wrapper">
                <h1 className="brand-header__heading">
                    {trans('pages.brands.exploreCollectionOf', {
                        brand: brand.name,
                    })}
                </h1>

                <div className="brand-header__content-wrapper">
                    <Picture
                        src={brand.image.src}
                        className="brand-header__logo"
                    />

                    <p className="brand-header__description">
                        {description}
                    </p>
                </div>
            </Wrapper>
        </section>
    );
};
