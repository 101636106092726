import { useEffect, useState } from 'react';

import { useWindowSize } from 'react-use';

import { Device } from '../entities/Device/Device';

interface UseDeviceWidth {
    device?: Device;
    isMobile: boolean;
    isTabletPortrait: boolean;
    isTabletLandscape: boolean;
    isDesktop: boolean;
}

export const useDeviceWidth = (): UseDeviceWidth => {
    const { width } = useWindowSize();

    const [device, setDevice] = useState<Device>();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isTabletPortrait, setIsTabletPortrait] = useState<boolean>(false);
    const [isTabletLandscape, setIsTableLandscape] = useState<boolean>(false);
    const [isDesktop, setIsDesktop] = useState<boolean>(false);

    const deviceMinWidths: Record<Device, number> = {
        mobile: 0,
        tabletPortrait: 768,
        tabletLandscape: 1024,
        desktop: 1448,
    };

    useEffect((): void => {
        if (width < deviceMinWidths.tabletPortrait) {
            setDevice(Device.mobile);
        } else if (width >= deviceMinWidths.tabletPortrait && width < deviceMinWidths.tabletLandscape) {
            setDevice(Device.tabletPortrait);
        } else if (width >= deviceMinWidths.tabletLandscape && width < deviceMinWidths.desktop) {
            setDevice(Device.tabletLandscape);
        } else {
            setDevice(Device.desktop);
        }

        setIsMobile(width < deviceMinWidths.tabletPortrait);
        setIsTabletPortrait(width >= deviceMinWidths.tabletPortrait && width < deviceMinWidths.tabletLandscape);
        setIsTableLandscape(width >= deviceMinWidths.tabletLandscape && width < deviceMinWidths.desktop);
        setIsDesktop(width >= deviceMinWidths.desktop);
    }, [width]);

    return {
        device,
        isMobile,
        isTabletPortrait,
        isTabletLandscape,
        isDesktop,
    };
};
