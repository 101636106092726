import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedInstantSearchInstance } from '../../connectors';
import { InstantSearch } from '../../containers';

import './SearchPage.scss';

export const SearchPage: FC = (): ReactElement => (
    <Page className="search-page">
        <ConnectedInstantSearchInstance>
            <InstantSearch />
        </ConnectedInstantSearchInstance>
    </Page>
);
