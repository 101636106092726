import { FC, ReactElement } from 'react';

import { BlogPostCard, ProductCard } from '../../../../compositions';
import { SliderSection } from '../../../../containers';
import { MOCK_BLOG_POSTS, MOCK_PRODUCTS } from '../../../../mock/mock-data';

import './SliderExamples.scss';

interface SliderExamplesProps {
    className?: string;
}

export const SliderExamples: FC<SliderExamplesProps> = ({
    className = '',
}): ReactElement => {
    const blogPosts = [...MOCK_BLOG_POSTS, ...MOCK_BLOG_POSTS, ...MOCK_BLOG_POSTS];
    const products = [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS];

    return (
        <div className={`slider-examples ${className}`}>
            <SliderSection
                id="blog-posts-example"
                title="Nieuw bij Loods 5"
                items={blogPosts.map(blogPost => (
                    <BlogPostCard blogPost={blogPost} />
                ))}
                link={{
                    href: '/',
                    label: 'Bekijk meer',
                }}
                className="slider-examples__slider"
            />

            <SliderSection
                id="products-example"
                title="Nieuw bij Loods 5"
                items={products.map(product => (
                    <ProductCard
                        product={product}
                        className="slider-examples__slider-product"
                    />
                ))}
                className="slider-examples__slider"
            />
        </div>
    );
};
