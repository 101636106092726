import { getImageType } from '../../helpers/image';
import { ProductHitResource } from '../Api/Webshop/Resource/ProductHit';
import {
    AutoplayMediaOption,
    Image,
    ImageFormat,
    ImageFormatSize,
    MediaFormatResource,
    MediaItem,
    MediaItemResource,
    MediaResource,
    Video,
} from './Media';

export const transformToImageFormat = (resource: MediaFormatResource, format: ImageFormatSize): ImageFormat => {
    const mediaSizes = {
        [ImageFormatSize.original]: '(min-width: 1024px)',
        [ImageFormatSize.large]: '(min-width: 768px)',
        [ImageFormatSize.medium]: '(min-width: 480px)',
        [ImageFormatSize.small]: '(min-width: 320px)',
        [ImageFormatSize.thumbnail]: '(max-width: 320px)',
    };

    return {
        srcSet: resource.url,
        type: resource.mime,
        media: mediaSizes[format],
    };
};

export const transformToImageFormats = (
    resource: Partial<Record<ImageFormatSize, MediaFormatResource>>,
): Partial<Record<ImageFormatSize, ImageFormat>> => {
    const original = resource.original
        ? transformToImageFormat(resource.original, ImageFormatSize.original)
        : undefined;

    const large = resource.large
        ? transformToImageFormat(resource.large, ImageFormatSize.large)
        : undefined;

    const medium = resource.medium
        ? transformToImageFormat(resource.medium, ImageFormatSize.medium)
        : undefined;

    const small = resource.small
        ? transformToImageFormat(resource.small, ImageFormatSize.small)
        : undefined;

    const thumbnail = resource.thumbnail
        ? transformToImageFormat(resource.thumbnail, ImageFormatSize.thumbnail)
        : undefined;

    return {
        original,
        large,
        medium,
        small,
        thumbnail,
    };
};

export const transformToImage = (resource: MediaResource): Image => {
    const formats = resource?.formats
        ? transformToImageFormats({ ...resource.formats, [ImageFormatSize.original]: resource })
        : {};

    return {
        src: resource?.url || '',
        formats,
        alt: resource?.alternativeText || resource?.caption || resource?.name || '',
    };
};

const transformInstantSearchImageWithResource = (resource: ProductHitResource, image: string): Image => ({
    src: image || '',
    formats: {
        original: {
            srcSet: image,
            type: getImageType(image),
            media: '(min-width: 320px)',
        },
    },
    alt: resource?.title || resource?.article_title || '', // TODO: Get Alt text from the Back-end
});

export const transformInstantSearchImageToImage = (resource: ProductHitResource): Image => transformInstantSearchImageWithResource(resource, resource.image);

export const transformInstantSearchImagesToMediaItems = (resource: ProductHitResource): MediaItem[] => resource.images.map((image, index) => ({
    id: index.toString(),
    image: transformInstantSearchImageWithResource(resource, image),
}));

export const transformToVideo = (resource: MediaResource): Video => ({
    src: resource?.url || '',
    type: resource?.mime || '',
});

export const transformToMediaItem = (resource: MediaItemResource): MediaItem => {
    const { media } = resource;

    const image = media.mime.startsWith('image')
        ? transformToImage(media)
        : undefined;

    const video = media.mime.startsWith('video')
        ? transformToVideo(media)
        : undefined;

    const videoPlaysInline = !!video && resource.playOption === AutoplayMediaOption.autoPlay;

    return {
        id: resource.id.toString(),
        image,
        video,
        videoPlaysInline,
    };
};
