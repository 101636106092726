import { FC, ReactElement } from 'react';

import { useHits } from 'react-instantsearch';

import { useTrans } from '../../../../hooks';

import './InstantSearchProductsCount.scss';

interface ProductsCountProps {
    className?: string;
}

export const InstantSearchProductsCount: FC<ProductsCountProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const { results } = useHits();

    return (
        <p className={`instant-search-products-count ${className}`}>
            {trans('containers.instantSearch.countProducts', { count: results?.nbHits ?? 0 })}
        </p>
    );
};
