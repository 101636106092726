import { FC, ReactElement } from 'react';

import { Page } from '../../components';

import './NotFoundPage.scss';

export const NotFoundPage: FC = (): ReactElement => (
    <Page className="not-found-page">
        Not found!
    </Page>
);
