import { FC, ReactElement, useMemo } from 'react';

import { Wrapper } from '../../../components';
import { SectionFooter } from '../../../compositions';
import { BrandCarouselInterface, BrandLogo } from '../../../entities/@blocks/BrandCarousel/BrandCarousel';
import { LinkTarget } from '../../../entities/Link/Link';
import { useTrans } from '../../../hooks';
import { BrandCarouselItems } from './subcomponents';

import './BrandCarousel.scss';

interface BrandCarouselProps extends BrandCarouselInterface {
    logos: BrandLogo[];
    title: string;
    className?: string;
}

export const BrandCarousel: FC<BrandCarouselProps> = ({
    logos,
    title,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    // Ensures there are enough logos to fill the carousel by duplicating them as necessary
    const duplicatedLogos = useMemo(() => {
        const minimumLogosToFillCarousel = 6;

        if (logos.length >= minimumLogosToFillCarousel) {
            return logos;
        }

        const timesToDuplicate = Math.ceil(minimumLogosToFillCarousel / logos.length);

        return Array(timesToDuplicate)
            .fill(logos)
            .flat()
            .map((logo, index) => ({
                ...logo,
                id: `${index}-${logo.id}`,
            }));
    }, [logos]);

    return (
        <section className={`brand-carousel ${className}`}>
            <Wrapper>
                <h2 className="brand-carousel__title">
                    {title}
                </h2>
            </Wrapper>

            <div className="brand-carousel__wrapper">
                <BrandCarouselItems logos={duplicatedLogos} className="brand-carousel__items-wrapper" />
                <BrandCarouselItems logos={duplicatedLogos} className="brand-carousel__items-wrapper" />
            </div>

            <Wrapper className="brand-carousel__footer-wrapper">
                <SectionFooter
                    link={{
                        href: '/brands',
                        label: trans('containers.brandCarousel.showAllBrands'),
                        target: LinkTarget.self,
                    }}
                />
            </Wrapper>
        </section>
    );
};
