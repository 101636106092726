import {
    ChangeEvent,
    FC,
    InputHTMLAttributes,
    ReactElement,
} from 'react';

import classNames from 'classnames';

import { ErrorLabel, Icon, SafeHtml } from '../../../components';

import './Checkbox.scss';

interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    isSmall?: boolean;
    hideLabel?: boolean;
    error?: string;
    onChange: (isChecked: boolean) => void;
    className?: string;
}

interface CheckboxPropsWithLabel extends CheckboxProps {
    label: string;
    htmlLabel?: never;
}

interface CheckboxPropsWithHtmlLabel extends CheckboxProps {
    label?: never;
    htmlLabel: string;
}

export const Checkbox: FC<CheckboxPropsWithLabel | CheckboxPropsWithHtmlLabel> = ({
    isSmall,
    label,
    htmlLabel,
    hideLabel,
    checked,
    disabled,
    error,
    onChange,
    className = '',
    ...checkboxProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.checked);
    };

    const checkboxClassNames = classNames('checkbox', {
        'checkbox--is-small': isSmall,
        'checkbox--is-checked': checked,
        'checkbox--is-disabled': disabled,
        'checkbox--has-error': error,
    }, className);

    const renderedLabel = htmlLabel ? (
        <SafeHtml html={htmlLabel} />
    ) : label;

    return (
        <div className={checkboxClassNames}>
            <label aria-label={label} className="checkbox__label">
                <input
                    {...checkboxProps}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    className="checkbox__input"
                />

                <div className="checkbox__box">
                    <Icon name="check" className="checkbox__icon" />
                </div>

                {!hideLabel && (
                    <span className="checkbox__label-text">
                        {renderedLabel}
                    </span>
                )}
            </label>

            {error && (
                <ErrorLabel
                    text={error}
                    className="checkbox__error-label"
                />
            )}
        </div>
    );
};
