import { useEffect, useState } from 'react';

import { PageCoords } from '../entities/Window/Window';

export const usePointerPosition = (): PageCoords => {
    const [pointerPosition, setPointerPosition] = useState<PageCoords>({ x: 0, y: 0 });

    useEffect((): () => void => {
        const updatePointerPosition = (event: PointerEvent): void => {
            setPointerPosition({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener('pointermove', updatePointerPosition);

        return (): void => {
            window.removeEventListener('pointermove', updatePointerPosition);
        };
    }, []);

    return pointerPosition;
};
