import { FC, ReactElement } from 'react';

import { Icon } from '../../../components';
import { TooltipWrapper } from '../..';

import './InputLabel.scss';

export interface InputLabelProps {
    hideLabel?: boolean;
    label: string;
    required?: boolean;
    tooltip?: string;
    className?: string;
}

export const InputLabel: FC<InputLabelProps> = ({
    hideLabel,
    label,
    required,
    tooltip,
    className = '',
}): ReactElement | null => {
    if (hideLabel) {
        return null;
    }

    return (
        <span className={`input-label ${className}`}>
            <span className="input-label__label-wrapper">
                {label}

                {required && (
                    <span className="input-label__required-star">*</span>
                )}

                {tooltip && (
                    <TooltipWrapper
                        id={`${label}-${tooltip}`}
                        text={tooltip}
                        className="input-label__tooltip-wrapper"
                        tooltipClassName="input-label__tooltip"
                    >
                        <Icon name="box-help" className="input-label__tooltip-icon" />
                    </TooltipWrapper>
                )}
            </span>
        </span>
    );
};
