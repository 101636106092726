import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel } from '../../../components';
import { InputLabel, InputLabelProps } from '../..';

import './InputLabelWrapper.scss';

interface InputLabelWrapperProps extends InputLabelProps, PropsWithChildren {
    error?: string;
    className?: string;
    labelClassName?: string;
    errorClassName?: string;
}

export const InputLabelWrapper: FC<InputLabelWrapperProps> = ({
    label,
    hideLabel,
    required,
    tooltip,
    error,
    className = '',
    labelClassName = '',
    errorClassName = '',
    children,
}): ReactElement => {
    const inputLabelWrapperClassNames = classNames('input-label-wrapper', {
        'input-label-wrapper--has-error': error,
    }, className);

    return (
        <span className={inputLabelWrapperClassNames}>
            <InputLabel
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                className={`input-label-wrapper__label ${labelClassName}`}
            />

            {children}

            {error && (
                <ErrorLabel
                    text={error}
                    className={`input-label-wrapper__error-label ${errorClassName}`}
                />
            )}
        </span>
    );
};
