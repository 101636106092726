import { FC, ReactElement } from 'react';

import { AccountDashboardOrders } from '../../containers';
import { Order } from '../../entities/Order/Order';
import { MOCK_PRODUCT_1, MOCK_PRODUCT_2, MOCK_PRODUCT_3 } from '../../mock/mock-data';

interface ConnectedAccountDashboardOrdersProps {
    className?: string;
}

export const ConnectedAccountDashboardOrders: FC<ConnectedAccountDashboardOrdersProps> = ({ className }): ReactElement => {
    const mockOnlineOrders: Order[] = [
        {
            id: '1',
            orderNumber: '123456',
            orderDate: new Date('01-01-2021'),
            deliveryDate: new Date('01-01-2021'),
            products: [MOCK_PRODUCT_1],
            totalPrice: 123.45,
        },
        {
            id: '2',
            orderNumber: '123457',
            orderDate: new Date('01-01-2021'),
            deliveryDate: new Date('01-01-2021'),
            totalPrice: 24.95,
            products: [MOCK_PRODUCT_2],
        },
    ];

    const mockInStoreOrders: Order[] = [
        {
            id: '1',
            orderNumber: '123456',
            orderDate: new Date('01-01-2021'),
            deliveryDate: new Date('01-01-2021'),
            products: [MOCK_PRODUCT_3],
            totalPrice: 123.45,
        },
    ];

    return (
        <AccountDashboardOrders
            onlineOrders={mockOnlineOrders}
            inStoreOrders={mockInStoreOrders}
            className={className}
        />
    );
};
