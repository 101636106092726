import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Block } from '../../../entities/Block/Block';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomerServiceFaqPageState = AsyncReduxState<{
    blocks: Block[];
}>;

const initialState: CustomerServiceFaqPageState = {
    ...initialAsyncReduxState,
    blocks: [],
};

const customerServiceFaqPageSlice = createSlice({
    name: 'customerServiceFaqPageSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): CustomerServiceFaqPageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): CustomerServiceFaqPageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomerServiceFaqPageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomerServiceFaqPageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlocks(state, action: PayloadAction<Block[]>): CustomerServiceFaqPageState {
            return {
                ...state,
                blocks: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlocks,
} = customerServiceFaqPageSlice.actions;

export default customerServiceFaqPageSlice;
