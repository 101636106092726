import { DefaultBlock, DefaultBlockResource } from '../../Block/Block';

export enum FormTypeResource {
    contact = 'Contact',
    kitchen = 'Keuken',
}

export interface FormResource extends DefaultBlockResource {
    title?: string;
    type?: FormTypeResource;
}

export enum FormType {
    contact = 'contact',
    kitchen = 'kitchen',
}

export interface FormInterface extends DefaultBlock {
    title: string;
    formType: FormType;
}
