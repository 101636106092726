/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconConversation: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path
            className="icon__stroke"
            d="M60.3,38.3c0-12.2-11.8-22.1-26.4-22.1S7.5,26.1,7.5,38.3c0,7.9,2.5,10.8,6.7,14.7L9.6,63.9l18.2-4.1c2,0.4,4,0.6,6.1,0.6C48.5,60.4,60.3,50.5,60.3,38.3z"
        />
        <path
            className="icon__stroke"
            d="M67.6,35c13.9,0.7,24.8,10.3,24.8,22c0,5.6-2.5,10.8-6.7,14.7l4.6,10.9l-18.2-4.1c-2,0.4-4,0.6-6.1,0.6c-10.3,0-19.2-4.9-23.6-12.2"
        />
        <g className={accentClassName}>
            <path d="M25,31.2c0-3,2.6-5.5,5.6-5.5h5.8c3.5,0,6.3,2.7,6.3,6.2s-1.2,4.5-3.2,5.5l-3.3,2v3.2h-4.6v-5.9l1.2-0.6l4.5-2.6c0.6-0.3,0.9-0.9,0.9-1.6s-0.7-1.6-1.7-1.6h-5.8c-0.6,0-1,0.4-1,0.9v0.7h-4.6v-0.7L25,31.2z" />
            <rect x="31.4" y="46.7" width="4.9" height="4.9" />
        </g>
    </svg>
));
