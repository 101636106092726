import { FC, ReactElement } from 'react';

import { AccountDashboardConfig } from '../../containers';
import { MOCK_USER } from '../../mock/mock-data/user';

export const ConnectedAccountDashboardConfig: FC = (): ReactElement => {
    // TODO: Get user from API
    const user = MOCK_USER;

    return (
        <AccountDashboardConfig user={user} />
    );
};
