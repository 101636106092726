import { Tab } from '../Tab/Tab';
import { BlogCategory, BlogCategoryResource } from './BlogCategory';

export const transformToBlogCategory = (resource: BlogCategoryResource): BlogCategory => ({
    id: resource.id.toString(),
    title: resource.title,
    slug: resource.title,
    order: resource.order || 0,
});

export const transformBlogCategoryToTab = (resource: BlogCategory): Tab => ({
    label: resource.title,
    value: resource.title,
});
