import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult/FetchResult';

export const getOpenStreetMapApiCall = async <ResponseData>(query: string, limit = 5): Promise<FetchResult<ResponseData, string>> => {
    let data: ResponseData | null;

    try {
        const apiUrl = process.env.REACT_APP_OPEN_STREET_MAP_URL || '';

        const params = new URLSearchParams({
            q: query,
            format: 'geojson',
            layers: 'address',
            limit: limit.toString(),
            countrycodes: 'nl',
        });

        const response = await fetch(`${apiUrl}/search?${params}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Accept-Language': 'nl',
            },
        });

        data = await response.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: data as ResponseData,
        };
    } catch (error: unknown) {
        console.error('[getOpenStreetMapApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    } finally {
        data = null;
    }
};

