import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon, Price } from '../../components';
import {
    Button,
    Dialog,
    DialogContent,
    LinkButton,
    ShoppingCartCount,
} from '../../compositions';
import { CartSidebarInterface } from '../../entities/Cart/Cart';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { CartProduct } from './subcomponents';

import './CartSidebar.scss';

interface CartSidebarProps extends CartSidebarInterface {
    onUpdateCartItemQuantity: (productId: string, quantity: number) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    onRemoveItemFromCart: (productId: string) => void;
    className?: string;
}

export const CartSidebar: FC<CartSidebarProps> = ({
    cartData,
    totalPrice,
    uspItems,
    onUpdateCartItemQuantity,
    onUpdateCartItemIsInWishlist,
    onRemoveItemFromCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const dialogRef = useRef<HTMLDialogElement>(null);

    const totalItemsInCart = cartData.reduce((acc, item) => acc + item.quantity, 0);

    const [layoutIsMini, setLayoutIsMini] = useState(false);

    const handleOpenDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.showModal();
    };

    const handleCloseDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.close();

        if (layoutIsMini) {
            setLayoutIsMini(false);
        }
    };

    const handleOpenMiniSideBar = async (): Promise<void> => {
        setLayoutIsMini(true);
        await handleOpenDialog();
    };

    const dialogClassNames = classNames('cart-sidebar__dialog', {
        'cart-sidebar__dialog--layout-mini': layoutIsMini,
    });

    // TODO: Activate "remove from cart" transition when removing product from cart

    return (
        <div className={`cart-sidebar ${className}`}>
            {/* TODO: Remove this button when logic is implemented that when product is added to cart, the dialog opens with a mini layout */}
            <button
                type="button"
                onClick={handleOpenMiniSideBar}
            >
                Open sidebar
            </button>

            <ShoppingCartCount
                count={totalItemsInCart}
                onClick={handleOpenDialog}
            />

            <Dialog
                enableBackdropClose
                ref={dialogRef}
                hasDarkBackdrop={!layoutIsMini}
                onClose={handleCloseDialog}
                className={dialogClassNames}
            >
                <DialogContent>
                    <h6 className="cart-sidebar__heading">
                        {trans('common.cart')}
                    </h6>

                    <div className="cart-sidebar__items-wrapper">
                        {cartData.map((item) => (
                            <CartProduct
                                {...item}
                                key={item.product.id}
                                onUpdateCartItemQuantity={onUpdateCartItemQuantity}
                                onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                                onRemoveItemFromCart={onRemoveItemFromCart}
                            />
                        ))}
                    </div>

                    <div className="cart-sidebar__total-price-wrapper">
                        <p className="cart-sidebar__total">
                            {trans('containers.cart.total')}
                        </p>

                        <Price
                            includeCurrencySymbol
                            amount={totalPrice}
                            className="cart-sidebar__total-price"
                        />
                    </div>

                    <div className="cart-sidebar__buttons-wrapper">
                        <LinkButton
                            text={trans('common.toCart')}
                            to={trans(appRoutes[AppRoute.cart].path)}
                            isSmall
                            className="cart-sidebar__button"
                        />

                        {!layoutIsMini && (
                            <Button
                                text={trans('common.continueShopping')}
                                isSmall
                                onClick={handleCloseDialog} // TODO: This does not work well when after this trying to open the dialog
                                className="cart-sidebar__button cart-sidebar__button--is-outline"
                            />
                        )}
                    </div>

                    {!layoutIsMini && (
                        <ul className="cart-sidebar__usp-list">
                            {uspItems.map((item) => (
                                <li
                                    key={item.id}
                                    className="cart-sidebar__usp-item"
                                >
                                    <Icon
                                        name={item.icon}
                                        className="cart-sidebar__usp-item-icon"
                                    />

                                    {item.text}
                                </li>
                            ))}
                        </ul>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};
