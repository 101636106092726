import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Block } from '../../../entities/Block/Block';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomerServiceReturnsPageState = AsyncReduxState<{
    blocks: Block[];
}>;

const initialState: CustomerServiceReturnsPageState = {
    ...initialAsyncReduxState,
    blocks: [],
};

const customerServiceReturnsPageSlice = createSlice({
    name: 'customerServiceReturnsPageSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): CustomerServiceReturnsPageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): CustomerServiceReturnsPageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomerServiceReturnsPageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomerServiceReturnsPageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlocks(state, action: PayloadAction<Block[]>): CustomerServiceReturnsPageState {
            return {
                ...state,
                blocks: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlocks,
} = customerServiceReturnsPageSlice.actions;

export default customerServiceReturnsPageSlice;
