import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Block } from '../../../entities/Block/Block';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomerServiceDeliveryPageState = AsyncReduxState<{
    blocks: Block[];
}>;

const initialState: CustomerServiceDeliveryPageState = {
    ...initialAsyncReduxState,
    blocks: [],
};

const customerServiceDeliveryPageSlice = createSlice({
    name: 'customerServiceDeliveryPageSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): CustomerServiceDeliveryPageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): CustomerServiceDeliveryPageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomerServiceDeliveryPageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomerServiceDeliveryPageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlocks(state, action: PayloadAction<Block[]>): CustomerServiceDeliveryPageState {
            return {
                ...state,
                blocks: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlocks,
} = customerServiceDeliveryPageSlice.actions;

export default customerServiceDeliveryPageSlice;
