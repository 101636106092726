/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconCart: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polyline className="icon__stroke" points="5 14.6 17.7 14.6 30 69.7 85 69.7" />
        <polygon className="icon__stroke" points="91 19.6 80.7 54.5 26.6 54.5 18.8 19.6 91 19.6" />
        <circle className="icon__stroke" cx="33.7" cy="83.4" r="5.9" />
        <circle className="icon__stroke" cx="76.2" cy="83.4" r="5.9" />
    </svg>
));
