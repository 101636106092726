/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { BrandRenderer } from '../../containers';
import { BrandHeaderInterface } from '../../entities/@blocks/BrandHeader/BrandHeader';
import { CarouselInterface } from '../../entities/@blocks/Carousel/Carousel';
import { ContentColumnsInterface } from '../../entities/@blocks/ContentColumns/ContentColumns';
import { FeaturedProductsInterface, FeaturedProductsType } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { MediaGridInterface } from '../../entities/@blocks/MediaGrid/MediaGrid';
import { Block, BlockType } from '../../entities/Block/Block';
import {
    MOCK_BRANDS,
    MOCK_IMAGE_1,
    MOCK_IMAGE_2,
    MOCK_IMAGE_3,
    MOCK_IMAGE_4,
    MOCK_IMAGE_5,
    MOCK_PRODUCTS,
} from '../../mock/mock-data';

interface ConnectedBrandRendererProps {
    slug: string;
}

export const ConnectedBrandRenderer: FC<ConnectedBrandRendererProps> = ({
    slug,
}): ReactElement => {
    //  TODO: Fetch brand from API
    const mockBrand = MOCK_BRANDS.find(brand => brand.slug === slug)!;

    //  TODO: Fetch content blocks from API
    const mockBrandContentBlocks: Block[] = [
        {
            id: '1',
            type: BlockType.brandHeader,
            brand: mockBrand,
            description: 'Bold Monkey gelooft dat er iets nieuws staat te gebeuren in meubilair en interieurinrichting. In plaats van de traditionele halfjaarlijkse seizoenscollecties die je meestal ziet in deze branche, doen wij het bij Bold Monkey anders. Wij verkopen meubels zoals jij ze koopt. Beter toch? En dat doen we altijd! Even een reality check: niemand wordt toch op de eerste lentedag wakker met het idee om te gaan shoppen voor een stoel met bloemenprint? En als jij hartje winter opeens gebloemde meubels wil, wie zijn wij dan om je tegen te houden?',
        } as BrandHeaderInterface,
        {
            id: '2',
            type: BlockType.carousel,
            images: [
                MOCK_IMAGE_1,
                MOCK_IMAGE_2,
                MOCK_IMAGE_3,
                MOCK_IMAGE_4,
                MOCK_IMAGE_5,
            ],
        } as CarouselInterface,
        {
            id: '3',
            type: BlockType.featuredProducts,
            layoutType: FeaturedProductsType.alternatingSizes,
            products: MOCK_PRODUCTS,
            title: 'Featured Products',
        } as FeaturedProductsInterface,
        {
            id: '4',
            type: BlockType.mediaGrid,
            mediaItems: [
                { id: '1', image: MOCK_IMAGE_1 },
                { id: '2', image: MOCK_IMAGE_2 },
                { id: '3', image: MOCK_IMAGE_3 },
            ],
        } as MediaGridInterface,
        {
            id: '5',
            type: BlockType.contentColumns,
            columns: [
                {
                    id: 1,
                    content: {
                        paragraphHtml: '<q>Dit is een leuke of mooie quote over dit merk, waar zij voor staan</q>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 2,
                    content: {
                        paragraphHtml: '<h3>Ontdek de collectie van Bold monkey</h3><p>Lorem ipsum dolor sit amet consectetur. '
                            + 'Vivamus pretium volutpat vitae faucibus. Est euismod turpis suspendisse aenean viverra sem '
                            + 'ipsum ornare. Tortor enim egestas cursus nisi vitae at tellus tristique. Viverra enim pretium'
                            + ' bibendum aliquet sapien neque et adipiscing tempus. Suspendisse ut mattis et praesent nulla '
                            + 'pulvinar feugiat egestas euismod. Nibh pellentesque auctor sed neque volutpat. Consectetur lorem'
                            + ' fringilla enim et scelerisque ante amet sit. Mauris imperdiet congue suspendisse est suspendisse '
                            + 'eget sagittis.\n'
                            + 'Nisl risus varius viverra quis nisi. Sagittis justo massa condimentum faucibus leo sapien massa.'
                            + ' In at pretium bibendum lectus a pharetra elit. Est non purus viverra.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 3,
                    content: {
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Lorem ultricies non sit fusce est. Placerat enim vitae nibh'
                            + ' feugiat diam est arcu sed. Arcu tincidunt laoreet libero viverra eu eu. Sed odio maecenas ac viverra sit.'
                            + ' Amet ac cras in turpis dis vivamus adipiscing. Aliquam rhoncus vel elementum at elementum nam ut ultrices. '
                            + 'Mi morbi libero ultricies tortor. Nunc posuere nunc et aliquam neque proin tincidunt quam. Purus nec orci '
                            + 'lectus mus convallis eget id cursus. Volutpat egestas pretium risus faucibus quam orci quam. Gravida tellus '
                            + 'dui ipsum odio sem aliquam enim risus. Nunc blandit morbi vitae laoreet sagittis.\n'
                            + 'Sit et nibh ac est facilisi ultrices proin. Dui at viverra montes et. Sed a sed ipsum quam neque. Bibendum'
                            + ' enim mattis semper tempor mattis et urna. Adipiscing facilisi lacinia purus ac odio sed. Orci mattis pharetra '
                            + 'sociis vel. Odio risus in at mi arcu lectus. Ut mauris sed curabitur mattis. Ornare etiam ultricies augue potenti'
                            + ' laoreet penatibus feugiat pulvinar.\n'
                            + 'Leo lectus dignissim eu scelerisque adipiscing convallis. Eget luctus diam at.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
            ],
        } as ContentColumnsInterface,
        {
            id: '6',
            type: BlockType.contentColumns,
            columns: [
                {
                    id: 1,
                    content: {
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p><p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 2,
                    content: {
                        paragraphHtml: '',
                        image: MOCK_IMAGE_5,
                        video: undefined,
                    },
                },
            ],
        } as ContentColumnsInterface,
    ];

    return (
        <BrandRenderer blocks={mockBrandContentBlocks} />
    );
};
