import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { FaqItemInterface } from '../../entities/@blocks/Faq/Faq';
import { serviceFaqSearchParam } from '../../entities/@forms/ServiceForm/ServiceForm';
import { normalizeString } from '../../helpers/string';
import { useTrans } from '../../hooks';
import { TextInput } from '..';
import { FaqItem } from './subcomponents';

import './FaqList.scss';

interface FaqListProps {
    faqItems: FaqItemInterface[];
    className?: string;
}

export const FaqList: FC<FaqListProps> = ({
    faqItems,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [searchParams] = useSearchParams();

    const [query, setQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<FaqItemInterface[]>(faqItems);

    const queryLengthThreshold = 2;

    useEffect((): void => {
        const searchQuery = searchParams.get(serviceFaqSearchParam);

        if (searchQuery) {
            setQuery(searchQuery);
        }
    }, [searchParams]);

    useEffect((): void => {
        if (query.length < queryLengthThreshold) {
            setSearchResults(faqItems);
            return;
        }

        const searchTerms = query.trim().split(' ');
        let filteredItems = faqItems;

        searchTerms.forEach(term => {
            const normalizedTerm = normalizeString(term.toLowerCase());

            filteredItems = filteredItems.filter(item => {
                const question = normalizeString(item.question.toLowerCase());
                const answer = normalizeString(item.answer.toLowerCase());

                return question.includes(normalizedTerm) || answer.includes(normalizedTerm);
            });
        });

        setSearchResults(filteredItems);
    }, [faqItems, query]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <div className={`faq-list ${className}`}>
            <form enterKeyHint="search" onSubmit={handleSubmit}>
                <TextInput
                    hideLabel
                    hasUnderline
                    type="search"
                    icon="search"
                    label={trans('form.whatAreYouLookingFor')}
                    placeholder={trans('form.whatAreYouLookingFor')}
                    value={query}
                    onChange={setQuery}
                    className="faq-list__search-input"
                    labelClassName="faq-list__search-input-label"
                    fieldClassName="faq-list__search-input-field"
                />
            </form>

            {searchResults.length === 0 && (
                <p className="faq-list__no-results">
                    {trans('compositions.faqList.noResults')}
                </p>
            )}

            {searchResults.length > 0 && (
                <ul className="faq-list__list">
                    {searchResults.map(item => (
                        <FaqItem
                            {...item}
                            key={item.question}
                            className="faq-list__item"
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};
