import { FC, PropsWithChildren, ReactElement } from 'react';

import './Page.scss';

interface PageProps extends PropsWithChildren {
    className?: string;
}

export const Page: FC<PageProps> = ({
    className = '',
    children,
}): ReactElement => (
    <main className={`page ${className}`}>
        {children}
    </main>
);
