import {
    forwardRef,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useHits, useSearchBox } from 'react-instantsearch';

import { InputProps } from '../../components';
import { ProductsSearch } from '../../containers';
import { ProductHitResource } from '../../entities/Api/Webshop/Resource/ProductHit';
import { transformProductHitToSearchPopupProductItem } from '../../entities/Product/ProductTransformers';
import { SearchPopupProductItem } from '../../entities/SearchPopup/SearchPopup';

interface ConnectedProductsSearchProps extends InputProps {
    onIsTyping: (isTyping: boolean) => void;
    onNewSearchTerm: (query: string) => void;
    onNewProducts: (products: SearchPopupProductItem[]) => void;
    onSubmit: () => void;
}

export const ConnectedProductsSearch = forwardRef<HTMLInputElement, ConnectedProductsSearchProps>(({
    onIsTyping,
    onNewSearchTerm,
    onNewProducts,
    onSubmit,
}, ref): ReactElement => {
    const { refine } = useSearchBox();
    const { results } = useHits();

    const [query, setQuery] = useState<string>('');

    useEffect((): void => {
        if (query.length < 1) {
            return;
        }

        onIsTyping(true);

        const value = query.trim();

        refine(value);

        const productHits = results?.hits as unknown as ProductHitResource[];
        const transformedProducts = productHits.map(transformProductHitToSearchPopupProductItem).slice(0, 10);
        onNewProducts(transformedProducts);
        onNewSearchTerm(value);
    }, [query]);

    return (
        <ProductsSearch
            ref={ref}
            onIsTyping={onIsTyping}
            newValue={setQuery}
            onSubmit={onSubmit}
        />
    );
});
