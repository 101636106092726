import { FC, ReactElement } from 'react';

import { ChangePasswordForm } from '../../../containers';
import { ChangePasswordFormData } from '../../../entities/@forms/ChangePasswordForm/ChangePasswordForm';
import { changePassword } from '../../../redux/changePassword/changePasswordActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedChangePasswordFormProps {
    className?: string;
}

export const ConnectedChangePasswordForm: FC<ConnectedChangePasswordFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, isChangedSuccessfully, error } = useTypedSelector(state => state.changePasswordSlice);

    const handleSubmit = (formData: ChangePasswordFormData): void => {
        dispatch(changePassword(formData));
    };

    return (
        <ChangePasswordForm
            isLoading={isLoading}
            isSuccessful={isChangedSuccessfully}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
