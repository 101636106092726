import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { BlogPost } from '../../../../entities/BlogPost/BlogPost';
import { useTrans } from '../../../../hooks';

interface BlogPostHelmetProps extends PropsWithChildren {
    blogPost: BlogPost;
}

export const BlogPostHelmet: FC<BlogPostHelmetProps> = ({
    blogPost,
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const twitterHandle = trans('company.twitterHandle');
    const siteUrl = trans('company.url');

    const logoUrl = `${siteUrl}/images/logo.jpg`;
    const fallbackImageUrl = `${siteUrl}/images/metadata-preview.jpg`;

    const metaTitle = blogPost.seo
        ? blogPost.seo.title
        : blogPost.title;

    const metaDescription = blogPost.seo
        ? blogPost.seo.description
        : undefined;

    const metaKeywords = blogPost.seo
        ? blogPost.seo.keywords
        : undefined;

    const metaImage = blogPost.seo
        ? blogPost.seo.image
        : blogPost.image;

    return (
        <Helmet>
            <title>{blogPost.title}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />

            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />

            <meta property="og:image" content={metaImage?.src || fallbackImageUrl} />
            <meta property="og:image:alt" content={metaImage?.alt || trans('company.name')} />

            <meta property="og:url" content={siteUrl + pathname} />

            {twitterHandle.startsWith('@') && (
                <>
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:site" content={twitterHandle} />

                    <meta property="twitter:title" content={metaTitle} />
                    <meta property="twitter:description" content={metaDescription} />

                    <meta property="twitter:image" content={metaImage?.src || fallbackImageUrl} />
                    <meta property="twitter:image:alt" content={metaImage?.alt || trans('company.name')} />
                </>
            )}

            <link rel="canonical" href={siteUrl + pathname} />

            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BlogPosting',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                    },
                    author: {
                        '@type': 'Organization',
                        name: trans('company.name'),
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: trans('company.name'),
                        logo: {
                            '@type': 'ImageObject',
                            url: logoUrl,
                        },
                    },
                    headline: metaTitle,
                    image: metaImage?.src ? [metaImage.src] : undefined,
                    datePublished: blogPost.publishDate,
                    dateModified: blogPost.modifyDate,
                })}
            </script>

            {children}
        </Helmet>
    );
};
