import { Token, TokenResource } from './Token';

export const transformToToken = (resource: TokenResource): Token => {
    const customerId = resource.customer.split('/').at(-1);

    return {
        customerId: customerId || '',
        token: `Bearer ${resource.token}`,
    };
};
