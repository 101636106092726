import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { OpenStreetMapResource } from '../../entities/OpenStreetMap/OpenStreetMap';
import { getOpenStreetMapApiCall } from '../../entities/OpenStreetMap/OpenStreetMapService';
import { transformOpenStreetMapFeatureToSearchableOption } from '../../entities/OpenStreetMap/OpenStreetMapTransformers';
import { retrieveUniqueValues } from '../../helpers/array';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSearchResults } from './storeLocatorSlice';

export const getStoreLocationSearchResults = (query: string, limit: number = 5) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const openStreetMapResponse = await getOpenStreetMapApiCall<OpenStreetMapResource>(query, limit);

        if (!isFetchResultSuccessful(openStreetMapResponse)) {
            dispatch(setError(openStreetMapResponse.error));
            return;
        }

        const { features } = openStreetMapResponse.data;

        const results = features.length > 0
            ? features.map(transformOpenStreetMapFeatureToSearchableOption)
            : [];

        const uniqueResults = retrieveUniqueValues(results);

        dispatch(setSearchResults(uniqueResults));
    } catch (error) {
        console.error('[getStoreLocationSearchResults]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
