import { FC, FormEvent, ReactElement } from 'react';

import { Address, AddressType } from '../../../entities/Address/Address';
import { transformAddresses } from '../../../entities/Address/AddressTransformers';
import { UserAddress } from '../../../entities/User/User';
import { useTrans } from '../../../hooks';
import { useTypedDispatch } from '../../../redux/store';
import { addPositiveToast } from '../../../redux/toast/toastActions';
import { Button } from '../../@buttons/Button/Button';
import { TextInput } from '../../@inputs/TextInput/TextInput';

import './AddressForm.scss';

interface AddressesFormProps {
    addresses: UserAddress[];
    onSave: (isSaved: boolean) => void;
    className?: string;
}

export const AddressForm: FC<AddressesFormProps> = ({
    addresses,
    onSave,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();

    // TODO: Move this, and all connector logic to a connector
    const showToast = (title: string, description: string): void => {
        dispatch(addPositiveToast({
            title,
            description,
        }));
    };

    const addressFieldStates = transformAddresses(addresses);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData = addressFieldStates.map(({ type, state }) => ({
            type,
            ...state,
        }));

        const hasErrors = false;

        if (hasErrors) {
            onSave(false);
            console.error('There are validation errors.');
        }

        // TODO: Replace with actual API call to update user addresses data
        console.log('TODO: POST Request with address data:', formData);

        onSave(true);

        showToast(
            trans('common.succeeded'),
            trans('compositions.addressForm.addressesSaved'),
        );
    };

    const handleInputChange = (field: keyof Address, value: string, type: AddressType): void => {
        const address = addressFieldStates.find(addressFieldState => addressFieldState.type === type);

        if (!address) {
            return;
        }

        address.setState(field, value);
    };

    return (
        <form onSubmit={handleSubmit} className={`address-form ${className}`}>
            <div className="address-form__list">
                {addressFieldStates.map(addressFieldState => {
                    const { type, state, setState } = addressFieldState;

                    return (
                        <div
                            key={type}
                            className="address-form__item"
                        >
                            <b className="address-form__type">
                                {trans(`compositions.addressCard.${type}`)}
                            </b>

                            <div className="address-form__input-wrapper">
                                <TextInput
                                    label={trans('form.street')}
                                    value={state.street}
                                    onChange={(value) => handleInputChange('street', value, type)}
                                />

                                <TextInput
                                    label={trans('form.houseNumber')}
                                    value={state.houseNumber}
                                    onChange={(value) => handleInputChange('houseNumber', value, type)}
                                />
                            </div>

                            <div className="address-form__input-wrapper">
                                <TextInput
                                    autoCapitalize="characters"
                                    label={trans('form.postalCode')}
                                    value={state.postalCode}
                                    onChange={(value) => setState('postalCode', value)}
                                />

                                <TextInput
                                    label={trans('form.city')}
                                    value={state.city}
                                    onChange={(value) => setState('city', value)}
                                />
                            </div>

                            <TextInput
                                label={trans('form.country')}
                                value={state.country}
                                onChange={(value) => setState('country', value)}
                            />
                        </div>
                    );
                })}
            </div>

            <Button
                text={trans('common.save')}
                type="submit"
                className="address-form__submit-button"
            />
        </form>
    );
};
