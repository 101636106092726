export interface RegistrationEmailFormData {
    email: string;
}

export interface RegistrationAccountDetailsFormData {
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword?: string; // Unmapped
    subscribedToNewsletter: boolean;
    acceptTermsAndConditions?: boolean; // Unmapped
}

export type RegistrationFormData = RegistrationEmailFormData & RegistrationAccountDetailsFormData;

export enum RegistrationFormStep {
    email = 'email',
    accountDetails = 'accountDetails',
}
