import { useState } from 'react';

import { LatLng } from '../entities/Map/Map';
import { useTrans } from './useTrans';

interface GeolocationState {
    geolocation?: LatLng;
    error?: string;
}

interface UseGeolocation extends GeolocationState {
    requestGeolocation: () => void;
}

export const useGeolocation = (): UseGeolocation => {
    const trans = useTrans();
    const [geolocationState, setGeolocationState] = useState<GeolocationState>({
        geolocation: undefined,
        error: undefined,
    });

    const requestGeolocation = (): void => {
        if (!navigator.geolocation) {
            setGeolocationState({
                error: trans('hooks.useGeolocation.notSupported'),
            });

            return;
        }

        const successCallback = (position: GeolocationPosition): void => {
            setGeolocationState({
                geolocation: position.coords,
            });
        };

        const errorCallback = (): void => {
            setGeolocationState({
                error: trans('hooks.useGeolocation.userDeniedCurrentLocation'),
            });
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    };

    return {
        ...geolocationState,
        requestGeolocation,
    };
};
