import { FC, ReactElement, useState } from 'react';

import {
    IconButton,
    Progress,
    TabList,
    WayfindingWrapper,
} from '../../../../compositions';
import { WayfindingType } from '../../../../entities/Wayfinding/Wayfinding';
import { useTrans } from '../../../../hooks';

import './WayfindingExamples.scss';

interface WayfindingExamplesProps {
    className?: string;
}

export const WayfindingExamples: FC<WayfindingExamplesProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const tabs = [
        { label: 'Tab name', value: 'tab-1' },
        { label: 'Tab name', value: 'tab-2' },
        { label: 'Tab name', value: 'tab-3' },
    ];

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const handleTabSelect = (value: string): void => {
        const selectedIndex = tabs.findIndex(tab => tab.value === value);

        setActiveIndex(selectedIndex || 0);
    };

    const handlePrevClick = (): void => setActiveIndex(activeIndex - 1);
    const handleNextClick = (): void => setActiveIndex(activeIndex + 1);

    return (
        <div className={`wayfinding-examples ${className}`}>
            <TabList
                tabs={tabs}
                activeTab={tabs[activeIndex].value}
                onTabSelect={handleTabSelect}
                className="wayfinding-examples__item"
            />

            <div className="wayfinding-examples__item wayfinding-examples__progress-wrapper">
                <Progress
                    total={tabs.length}
                    activeIndex={activeIndex}
                    onChange={setActiveIndex}
                    className="wayfinding-examples__progress"
                />

                <div className="wayfinding-examples__progress-button-wrapper">
                    <IconButton
                        icon="wayfinding-left"
                        hideLabel
                        text={trans('common.prev')}
                        disabled={activeIndex === 0}
                        onClick={handlePrevClick}
                        className="wayfinding-examples__progress-button"
                        iconClassName="wayfinding-examples__progress-icon"
                    />

                    <IconButton
                        icon="wayfinding-right"
                        hideLabel
                        text={trans('common.next')}
                        disabled={activeIndex === tabs.length - 1}
                        onClick={handleNextClick}
                        className="wayfinding-examples__progress-button"
                        iconClassName="wayfinding-examples__progress-icon"
                    />
                </div>
            </div>

            <div className="wayfinding-examples__item">
                <WayfindingWrapper
                    id="wayfinding-test"
                    type={WayfindingType.enter}
                >
                    <div className="wayfinding-examples__wrapper-example">
                        Hover me
                    </div>
                </WayfindingWrapper>
            </div>
        </div>
    );
};
