import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../compositions';
import { Link } from '../../../../entities/Link/Link';

import './LinkSuggestions.scss';

interface LinkSuggestionsProps {
    label: string;
    links: Link[];
    onClickLink?: () => void;
    className?: string;
}

export const LinkSuggestions: FC<LinkSuggestionsProps> = ({
    label,
    links,
    onClickLink,
    className = '',
}): ReactElement => (
    <div className={`link-suggestions ${className}`}>
        <p className="link-suggestions__label">
            {label}
        </p>

        <ul>
            {links.map(itemLink => (
                <li key={itemLink.label}>
                    <LinkButton
                        to={itemLink.href}
                        text={itemLink.label}
                        onClick={onClickLink}
                        className="link-suggestions__list-item"
                    />
                </li>
            ))}
        </ul>
    </div>
);

