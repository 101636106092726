import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import './Breadcrumbs.scss';

interface Breadcrumb {
    title: string;
    url: string;
}

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
    className?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
    breadcrumbs,
    className = '',
}): ReactElement => (
    <div className={`breadcrumbs ${className}`}>
        {breadcrumbs.map((breadcrumb, index) => (
            <div key={breadcrumb.url} className="breadcrumbs__item">
                {index === breadcrumbs.length - 1 ? (
                    <p>{breadcrumb.title}</p>
                ) : (
                    <Link to={breadcrumb.url}>
                        {breadcrumb.title}
                    </Link>
                )}

                {index < breadcrumbs.length - 1 && ' / '}
            </div>
        ))}
    </div>
);
