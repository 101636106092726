import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './FaqSkeletons.scss';

interface FaqSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const FaqSkeletons: FC<FaqSkeletonsProps> = ({
    numberOfSkeletons = randomInBetweenValue(4, 6),
    className = '',
}): ReactElement => {
    const faqSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`faq-skeletons ${className}`}>
            {faqSkeletons.map(skeleton => (
                <li key={skeleton} className="faq-skeletons__item">
                    <div className="faq-skeletons__question-wrapper">
                        <Skeleton className="faq-skeletons__question" />
                    </div>
                </li>
            ))}
        </ul>
    );
};
