export interface DefaultBlockResource {
    id?: string;
    __component?: string;
}

export interface DefaultBlock {
    id?: string;
    type?: string;
}

export type BlockResource = DefaultBlockResource & unknown;
export type Block = DefaultBlock & unknown;

export enum BlockTypeResource {
    // Data
    blogOverview = 'data.blog-overview',
    highlightedBlogPost = 'data.blog-display',
    productCollection = 'data.products-collection',
    featuredProducts = 'data.products-view',
    relatedBlogPosts = 'data.related-blog-posts',
    // Display
    brandCarousel = 'display.brands-carousel',
    faq = 'display.faq',
    followUs = 'display.',
    fullImage = 'display.full-image',
    service = 'display.service',
    social = 'display.social',
    uspSection = 'display.unique-selling-point',
    // TextImage
    carousel = 'text-image.carousel',
    contentColumns = 'text-image.content-columns',
    header = 'text-image.media',
    iconColumns = 'text-image.icon-columns',
    mediaGrid = 'text-image.media-grid',
    // User input
    form = 'user-input.form',
    newsletterCallToAction = 'user-input.news-letter',
}

export enum BlockType {
    blogOverview = 'blogOverview',
    brandCarousel = 'brandCarousel',
    brandHeader = 'brandHeader',
    carousel = 'carousel',
    contentColumns = 'contentColumns',
    faqs = 'faqs',
    featuredProducts = 'featuredProducts',
    form = 'form',
    header = 'header',
    highlightedBlogPost = 'highlightedBlogPost',
    iconColumns = 'iconColumns',
    mediaGrid = 'mediaGrid',
    newsletterCallToAction = 'newsletterCallToAction',
    serviceSection = 'serviceSection',
    uspSection = 'uspSection'
}
