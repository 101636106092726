import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import {
    UspItem,
    UspItemResource,
    UspSectionInterface,
    UspSectionResource,
} from './UspSection';

const transformToUspItem = (resource: UspItemResource): UspItem => ({
    id: resource.id,
    icon: resource.icon.icon,
    text: resource.title,
});

export const transformToUspSection = (resource: UspSectionResource): UspSectionInterface => {
    const defaultBlock = transformToDefaultBlock(resource);
    const items = resource.uniqueSellingPoint.map(transformToUspItem);

    return {
        ...defaultBlock,
        type: BlockType.uspSection,
        items,
        backgroundColor: resource?.color,
    };
};
