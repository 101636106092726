import { IconName } from '../../../components';
import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import {
    IconColumnInterface,
    IconColumnResource,
    IconColumnsInterface,
    IconColumnsResource,
} from './IconColumns';

const transformToIconColumn = (resource: IconColumnResource): IconColumnInterface => {
    const icon = resource.icon
        ? resource.icon.icon as IconName
        : undefined;

    return {
        id: resource.id,
        content: {
            title: resource.title || '',
            paragraphHtml: resource.paragraph || '',
            icon,
        },
    };
};

export const transformToIconColumns = (resource: IconColumnsResource): IconColumnsInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const columns = resource.columns.map(transformToIconColumn);

    return {
        ...defaultBlock,
        type: BlockType.iconColumns,
        title: resource.title || '',
        columns,
    };
};
