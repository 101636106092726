import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
    useEffect,
    useRef,
    useState,
    VideoHTMLAttributes,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { useCombinedRefs, useTrans } from '../../hooks';
import { IconButton, PlayButton } from '..';

import './Video.scss';

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement>, RefAttributes<HTMLVideoElement> {
    type: string;
    onPlayStateChange?: (isPlaying: boolean) => void;
    className?: string;
    videoClassName?: string;
}

export const Video: ForwardRefExoticComponent<VideoProps> = forwardRef(({
    autoPlay,
    muted,
    controls,
    src,
    type,
    onPlayStateChange,
    className = '',
    videoClassName = '',
    ...videoProps
}, ref: Ref<HTMLVideoElement>): ReactElement => {
    const trans = useTrans();

    const [isPlaying, setIsPlaying] = useState<boolean>(!!(autoPlay && muted));
    const [isMuted, toggleIsMuted] = useToggle(!!muted);

    const videoRef = useRef<HTMLVideoElement>(null);
    const combinedRef = useCombinedRefs<HTMLVideoElement>(videoRef, ref);

    const handlePlayStateClick = async (): Promise<void> => {
        const videoElement = videoRef.current;

        if (!videoElement) {
            return;
        }

        if (isPlaying) {
            await videoElement.pause();
        } else {
            await videoElement.play();
        }
    };

    const handlePlay = (): void => setIsPlaying(true);
    const handlePause = (): void => setIsPlaying(false);

    useEffect((): void => {
        if (!onPlayStateChange) {
            return;
        }

        onPlayStateChange(isPlaying);
    }, [isPlaying]);

    const videoClassNames = classNames('video', {
        'video--is-paused': !isPlaying,
    }, className);

    return (
        <div className={videoClassNames}>
            {controls && (
                <div className="video__play-button-wrapper">
                    <PlayButton
                        isPlaying={isPlaying}
                        onClick={handlePlayStateClick}
                        className="video__button video__play-button"
                    />
                </div>
            )}

            {controls && (
                <div className="video__controls-wrapper">
                    <IconButton
                        icon={isMuted ? 'audio-off' : 'audio-on'}
                        text={trans(`common.${isMuted ? 'unmute' : 'mute'}`)}
                        hideLabel
                        onClick={toggleIsMuted}
                        className="video__button video__control-button"
                        iconClassName="video__control-icon"
                    />
                </div>
            )}

            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                {...videoProps}
                ref={combinedRef}
                autoPlay={autoPlay}
                muted={isMuted}
                onPlay={handlePlay}
                onPause={handlePause}
                crossOrigin="anonymous"
                className={`video__element ${videoClassName}`}
            >
                <source src={src} type={type} />

                {trans('compositions.video.notSupported')}
            </video>
        </div>
    );
});
