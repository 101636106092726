import { FC } from 'react';

import { Link } from 'react-router-dom';

import { Price, SafeHtml } from '../../components';
import { Product } from '../../entities/Product/Product';
import { ProductCallToAction } from '../ProductCallToAction/ProductCallToAction';

import './ProductSidebar.scss';

interface ProductSidebarProps {
    product: Product;
    className?: string;
}

export const ProductSidebar: FC<ProductSidebarProps> = ({
    product,
    className = '',
}) => (
    <div className={`product-sidebar ${className}`}>
        <span>Bestseller</span>
        <span>Alleen online</span>

        <div className="product-sidebar__brand-wrapper">
            {product.brand && (
                <Link to={`/brand/${product.brand.slug}`} className="product-sidebar__brand-link">
                    <h3 className="product-sidebar__brand">
                        {product.brand.name}
                    </h3>
                </Link>
            )}
        </div>

        <h1 className="product-sidebar__name">{product.name}</h1>

        <Price
            amount={product.price}
            className="product-sidebar__price"
        />

        {product.description && (
            <SafeHtml html={product.description} className="product-sidebar__description" />
        )}

        <div className="product-sidebar__notices">
            <div className="product-sidebar__notice">Verkrijgbaar vanaf maart</div>
        </div>

        <ProductCallToAction
            product={product}
            className="product-sidebar__call-to-action"
        />

        {product.attributes && (
            <div className="product-sidebar__attributes">
                <div className="product-sidebar__attributes-title">Kenmerken</div>
                <ul className="product-sidebar__list">
                    {product.attributes.map((attribute) => (
                        <li key={attribute.value}>
                            {attribute.label && (
                                `${attribute.label}: `
                            )}
                            {attribute.value}
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
);
