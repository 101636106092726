import { createObjectFromAttributes } from '../../helpers/product';
import trans from '../../helpers/trans';

export enum MeiliSearchAttributes {
    categories = 'categories',
    productType = 'productType',
    colors = 'colors.color',
    brand = 'brand',
    price = 'price_in_cents',
    material = 'material',
    origin = 'origin',
    extraLightning = 'extraLightning',
    lampFitting = 'lampFitting',
    lightDirection = 'lightDirection',
    powerSupply = 'powerSupply',
    suitableForOutdoorUse = 'suitableForOutdoorUse',
    size = 'size',
}

export interface ProductFilter {
    attribute: MeiliSearchAttributes;
    operator: 'and' | 'or';
    label: string;
}

export const productFilters: ProductFilter[] = [
    {
        attribute: MeiliSearchAttributes.categories,
        label: trans('compositions.productsFilters.category'),
        operator: 'and',
    },
    {
        attribute: MeiliSearchAttributes.colors,
        label: trans('compositions.productsFilters.color'),
        operator: 'and',
    },
    {
        attribute: MeiliSearchAttributes.brand,
        label: trans('compositions.productsFilters.brand'),
        operator: 'or',
    },
    // TODO: Add `price`, `productType`, `material` and `origin` filters
];

export const productFiltersConfig = createObjectFromAttributes(productFilters);
