import { FC, ReactElement } from 'react';

import { BrandProducts } from '../../containers';
import { MOCK_PRODUCTS } from '../../mock/mock-data';

import '../../containers/BrandProducts/BrandProducts.scss';

// TODO: Remove this file when ConnectedInstantSearch is implemented

interface ConnectedBrandContentBlocksProps {
    slug: string;
}

export const ConnectedBrandProducts: FC<ConnectedBrandContentBlocksProps> = ({
    slug,
}): ReactElement => {
    const mockProducts = MOCK_PRODUCTS.filter(product => product.brand?.slug === slug);

    return (
        <BrandProducts products={mockProducts} />
    );
};
