import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { useHits } from 'react-instantsearch';

import { InstantSearchProductsCount, InstantSearchRefinementList } from '../../containers/InstantSearch/subcomponents';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { productFilters } from '../../entities/ProductFilters/ProductFilters';
import { useDeviceWidth, useTrans } from '../../hooks';
import {
    Accordion,
    Button,
    IconButton,
    ProductsSorting,
} from '..';

import './ProductFilterBar.scss';

interface ProductsFiltersProps {
    onToggleSidebar: (isChecked: boolean) => void;
    className?: string;
}

export const ProductFilterBar: FC<ProductsFiltersProps> = ({
    onToggleSidebar,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();
    const { results } = useHits();

    const [filterSidebarIsActive, setFilterSidebarIsActive] = useState<boolean>(false);

    useEffect((): void => {
        if (isMobile && filterSidebarIsActive) {
            return document.body.classList.add('has-active-modal');
        }

        return document.body.classList.remove('has-active-modal');
    }, [filterSidebarIsActive]);


    const handleOnToggleSidebarButton = (): void => {
        setFilterSidebarIsActive(!filterSidebarIsActive);
        onToggleSidebar(!filterSidebarIsActive);
    };

    const sidebarClassNames = classNames('product-filter-bar__sidebar', {
        'product-filter-bar__sidebar--is-active': filterSidebarIsActive,
    });

    return (
        <div className={`product-filter-bar ${className}`}>
            <div className="product-filter-bar__wrapper">
                <div className="product-filter-bar__filter-button-wrapper">
                    <IconButton
                        isSmall={!isMobile}
                        icon="filter"
                        text={trans('compositions.productsFilters.allFilters')}
                        iconPos={HorizontalAlignment.left}
                        className="product-filter-bar__filter-button"
                        onClick={handleOnToggleSidebarButton}
                    />
                </div>

                <InstantSearchProductsCount className="product-filter-bar__products-count" />

                <ProductsSorting className="product-filter-bar__products-sorting" />
            </div>

            <aside className={sidebarClassNames}>
                <h6 className="product-filter-bar__heading">
                    {trans('compositions.productsFilters.allFilters')}
                </h6>

                {productFilters.map(filterItem => (
                    <Accordion
                        hasBorder
                        key={filterItem.attribute}
                        header={filterItem.label}
                        className="product-filter-bar__accordion"
                        headingClassName="product-filter-bar__accordion-heading"
                    >
                        <InstantSearchRefinementList
                            attribute={filterItem.attribute}
                            label={filterItem.label}
                            operator={filterItem.operator}
                        />
                    </Accordion>
                ))}

                <Button
                    isSmall
                    text={trans('compositions.productsFilters.results', { amount: results?.nbHits })}
                    className="product-filter-bar__results-button"
                    onClick={handleOnToggleSidebarButton}
                />
            </aside>
        </div>
    );
};
