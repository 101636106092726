import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { ServiceSectionInterface, ServiceSectionResource } from './ServiceSection';

export const transformToServiceSection = (resource: ServiceSectionResource): ServiceSectionInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    return {
        ...defaultBlock,
        type: BlockType.serviceSection,
        title: resource.title,
    };
};
