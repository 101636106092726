import { FC, ReactElement } from 'react';

import { CustomerServicePageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServicePage } from '../../../redux/@pages/customerServicePage/customerServicePageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServicePageRendererProps {
    className?: string;
}

export const ConnectedCustomerServicePageRenderer: FC<ConnectedCustomerServicePageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.customerServicePageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServicePageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchCustomerServicePage());
    }, []);

    return (
        <CustomerServicePageRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
