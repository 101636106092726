/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconArrowShortLeft: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polyline className="icon__stroke" points="60.1 80.4 30.4 50 60.1 19.6" />
        <line className="icon__stroke" x1="74" y1="50" x2="30.4" y2="50" />
    </svg>
));
