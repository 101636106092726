/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconWayfindingLeft: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={`icon__stroke ${pathClassName}`} d="M34.2,56.6l.7.7,21.1,21.1-8.8,8.8L10.1,50.2,47.2,13.1l8.8,8.8-21.5,21.5-.7.7h1s56.2,0,56.2,0v12.5h-55.8s-1,0-1,0Z" />
    </svg>
));
