import { BlogCategoriesResponse } from '../../../entities/BlogCategories/BlogCategories';
import { transformBlogCategoryToTab, transformToBlogCategory } from '../../../entities/BlogCategory/BlogCategoryTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { transformStrapiPaginationToPagination } from '../../../entities/Pagination/PaginationTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { sortByOrder } from '../../../helpers/sort';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlogCategoryTabs,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setPagination,
} from './blogCategoriesSlice';

export const fetchBlogCategories: ReduxFetchAction = () => async dispatch => {
    dispatch(setBlogCategoryTabs([]));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const blogCategoriesResponse = await getStrapiApiCall<BlogCategoriesResponse>('/blog-categories');

        if (!isFetchResultSuccessful(blogCategoriesResponse)) {
            dispatch(setError(blogCategoriesResponse.error));
            return;
        }

        const { data, meta } = blogCategoriesResponse.data;

        const blogCategories = data?.length
            ? data.map(transformToBlogCategory)
            : [];

        const sortedBlogCategories = blogCategories.sort(sortByOrder);

        const blogCategoryTabs = sortedBlogCategories.map(transformBlogCategoryToTab);

        const pagination = meta
            ? transformStrapiPaginationToPagination(meta.pagination)
            : undefined;

        dispatch(setBlogCategoryTabs(blogCategoryTabs));
        dispatch(setPagination(pagination));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBlogCategories]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
