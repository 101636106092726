import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { LinkButton, ProductCard } from '../../compositions';
import { useTrans } from '../../hooks';
import { MOCK_PRODUCTS } from '../../mock/mock-data';

import './WishlistPage.scss';

export const WishlistPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="guest-wishlist-page">
            <Wrapper>
                <div className="guest-wishlist-page__header">
                    <h1>{trans('pages.guestWishlist.title')}</h1>
                    <p>{trans('pages.guestWishlist.description')}</p>

                    <LinkButton
                        isSmall
                        text={trans('routes.login.label')}
                        to={trans('routes.login.path')}
                        className="guest-wishlist-page__login-button"
                    />
                    <div className="guest-wishlist-page__create-account">
                        <p className="guest-wishlist-page__no-account-label">
                            {trans('pages.guestWishlist.noAccount')}
                        </p>
                        <LinkButton
                            text={trans('pages.guestWishlist.createAccount')}
                            to={trans('routes.registration.path')}
                            className="guest-wishlist-page__no-account-button"
                        />
                    </div>
                </div>

                <div className="guest-wishlist-page__products-grid">
                    {MOCK_PRODUCTS.map((product) => (
                        <ProductCard
                            key={product.id}
                            product={product}
                        />
                    ))}
                </div>
            </Wrapper>
        </Page>
    );
};
