import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { useHits, useSearchBox } from 'react-instantsearch';
import { useSearchParams } from 'react-router-dom';

import { Wrapper } from '../../components';
import { ProductCard, ProductFilterBar } from '../../compositions';
import { useTrans } from '../../hooks';
import { MOCK_PRODUCTS } from '../../mock/mock-data';
import { SliderSection } from '../SliderSection/SliderSection';
import { InstantSearchPagesList, InstantSearchPagination, InstantSearchProductList } from './subcomponents';

import './InstantSearch.scss';

interface InstantSearchProps {
    className?: string;
}

export const InstantSearch: FC<InstantSearchProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [queryParams] = useSearchParams();
    const { results } = useHits();
    const { refine } = useSearchBox();

    // TODO: Calculate how many results are a page (from MeiliSearch API) (L5W-151)
    const countPageResults = 3;

    // TODO: Calculate how many results are a product (from MeiliSearch API) (L5W-151)
    const countProductsResults = 8;

    const [hasActiveSidebar, setHasActiveSidebar] = useState<boolean>(false);

    const searchTermParam = queryParams.get('q') || '';

    useEffect(() => {
        if (searchTermParam.length > 0) {
            refine(searchTermParam);
        }
    }, [refine, searchTermParam]);

    // TODO: Get products data from API for this blocks (L5W-98)
    const mockProductSliders = [
        {
            id: 'others-also-viewed-products',
            heading: trans('containers.instantSearch.othersAlsoViewedProducts'),
            products: [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS],
            link: {
                label: 'Bekijk meer',
                href: '/producten', // TODO: determine what this link should be (L5W-98)
            },
        },
        {
            id: 'popular-products-at-this-moment',
            heading: trans('containers.instantSearch.popularAtThisMoment'),
            products: [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS],
            link: {
                label: 'Bekijk meer',
                href: '/producten', // TODO: determine what this link should be (L5W-98)
            },
        },
    ];

    const instantSearchClassNames = classNames('instant-search', {
        'instant-search--has-active-product-filter-sidebar': hasActiveSidebar,
    }, className);

    return (
        <div className={instantSearchClassNames}>
            <Wrapper className="instant-search__count-wrapper">
                <p className="instant-search__search-results-text">
                    {searchTermParam.length
                        ? trans('containers.instantSearch.searchResults', {
                            count: results?.nbHits || 0,
                            query: searchTermParam,
                        })
                        : trans('containers.instantSearch.searchActively')}
                </p>
            </Wrapper>

            {(results && results.hits.length > 0) && searchTermParam.length && (
                <div className="instant-search__results-wrapper">
                    <div className="instant-search__pages-results">
                        <Wrapper className="instant-search__count-wrapper">
                            <p className="instant-search__count-indicator">
                                {trans('containers.instantSearch.countPages', { count: countPageResults })}
                            </p>
                        </Wrapper>

                        <Wrapper className="instant-search__wrapper">
                            <InstantSearchPagesList />
                        </Wrapper>
                    </div>

                    <div className="instant-search__products-results">
                        {/* TODO: Only show this count indicator for products when there are also pages from the MeiliSearch API */}
                        <Wrapper className="instant-search__count-wrapper">
                            <p className="instant-search__count-indicator">
                                {trans('containers.instantSearch.countProducts', { count: countProductsResults })}
                            </p>
                        </Wrapper>

                        <Wrapper className="instant-search__wrapper">
                            <ProductFilterBar onToggleSidebar={setHasActiveSidebar} />
                        </Wrapper>

                        <div className="instant-search__product-list">
                            <Wrapper>
                                <InstantSearchProductList />

                                <InstantSearchPagination />
                            </Wrapper>
                        </div>
                    </div>
                </div>
            )}

            {results?.hits.length === 0 && searchTermParam.length && (
                <div className="instant-search__sliders-wrapper">
                    {mockProductSliders.map(productSlider => (
                        <SliderSection
                            key={productSlider.id}
                            id={productSlider.id}
                            title={productSlider.heading}
                            items={productSlider.products.map(product => (
                                <ProductCard product={product} />
                            ))}
                            link={productSlider.link}
                            className="instant-search__slider"
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
