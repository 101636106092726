/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconDelivery: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M10.4,27.1V10.4h52.1v16.7M37.5,72.9h25v-27.1M10.4,76c0,7.5,6.1,13.5,13.5,13.5s13.5-6.1,13.5-13.5-6.1-13.5-13.5-13.5-13.5,6.1-13.5,13.5ZM10.4,76v-21.9M62.5,27.1v18.7M93.7,72.9v-27.1l-14.6-18.7h-16.7M93.7,45.8h-31.2M93.7,72.9h6.2M93.7,72.9h-4.5M89.2,72.9c-1.4-6-6.8-10.4-13.2-10.4s-13.5,6.1-13.5,13.5,6.1,13.5,13.5,13.5,13.5-6.1,13.5-13.5-.1-2.1-.4-3.1ZM0,27.1h39.6M2.1,37.5h41.7M0,47.9h39.6" />
    </svg>
));
