import { FC, ReactElement } from 'react';

import { SafeHtml } from '../../components';
import { Product } from '../../entities/Product/Product';
import { useTrans } from '../../hooks';
import { LinkButton } from '../@buttons/LinkButton/LinkButton';

import './ProductInformation.scss';

interface ProductInformationProps {
    product: Product;
    className?: string;
}

export const ProductInformation: FC<ProductInformationProps> = ({
    product,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`product-information ${className}`}>
            <div className="product-information__block product-information__main">
                <div className="product-information__main-inner">
                    <div className="product-information__text">
                        <div className="product-information__title">Omschrijving</div>

                        {product.description
                            ? (
                                <SafeHtml html={product.description} className="product-information__description" />
                            )
                            : (
                                <p>Geen omschrijving beschikbaar</p>
                            )}
                    </div>

                    <div className="product-information__specs">
                        {product.specs && (
                            <>
                                <div className="product-information__title">Specificaties</div>
                                <table className="product-information__table">
                                    <tbody>
                                        {product.specs.map(spec => (
                                            <tr key={spec.value}>
                                                <td>{spec.label}</td>
                                                <td>{spec.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}

                        {product.dimensions && (
                            <>
                                <div className="product-information__title">Afmetingen</div>
                                <table className="product-information__table">
                                    <tbody>
                                        {Object.entries(product.dimensions).map(([key, value]) => (
                                            <tr key={key}>
                                                <td>{key}</td>
                                                <td>{value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {product.brand && (
                <div className="product-information__block product-information__brand">
                    <div className="product-information__brand-logo">
                        [Brand logo here]
                    </div>

                    <div className="product-information__brand-information">
                        <h5 className="product-information__brand-name">
                            {product.brand.name}
                        </h5>

                        {product.brand.description && (
                            <SafeHtml
                                html={product.brand.description}
                                className="product-information__brand-description"
                            />
                        )}

                        <LinkButton
                            to={`/merk/${product.brand.slug}`}
                            text={trans('containers.productInformation.viewAllFromBrand', { brand: product.brand.name })}
                            className="product-information__brand-link"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
