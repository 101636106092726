import trans from '../../helpers/trans';
import { Token, tokenKey } from '../Api/Webshop';
import { FetchResult, FetchResultError, FetchResultType } from '../FetchResult/FetchResult';

export const syliusFetch = async <ResponseData>(endpoint: string, options?: RequestInit): Promise<FetchResult<ResponseData, string>> => {
    let data: ResponseData | FetchResultError | null;

    try {
        const apiUrl = process.env.REACT_APP_SYLIUS_URL || '';

        const requestOptions: RequestInit = {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Accept-Language': 'nl',
                ...options?.headers,
            },
        };

        const response = await fetch(apiUrl + endpoint, requestOptions);
        const isSuccessfulStatusCode = ['2', '3'].includes(response.status.toString().substring(0, 1));
        const isErrorWithTranslatedMessageStatusCode = response.status.toString().substring(0, 1) === '4';

        const responseHasContent = response.status !== 204;
        data = responseHasContent ? await response.json() : null;

        if (!isSuccessfulStatusCode) {
            const errorMessage = (data && isErrorWithTranslatedMessageStatusCode)
                ? (data as FetchResultError).detail
                : trans('errors.unknownError');

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: errorMessage,
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: data as ResponseData,
        };
    } catch (error: unknown) {
        console.error('[syliusFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    } finally {
        data = null;
    }
};

export const authorizedSyliusFetch = async <ResponseData>(endpoint: string, options?: RequestInit): Promise<FetchResult<ResponseData, string>> => {
    try {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);

        if (!storedToken) {
            // TODO: Log out
            throw new Error('No token found.');
        }

        const tokenData = JSON.parse(storedToken) as Token;

        return await syliusFetch<ResponseData>(endpoint, {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: tokenData.token || '',
            },
        });
    } catch (error: unknown) {
        console.error('[authorizedSyliusFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
