import { FC, ReactElement } from 'react';

import { CustomerServiceReturnsPageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServiceReturnsPage } from '../../../redux/@pages/customerServiceReturnsPage/customerServiceReturnsPageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServiceReturnsPageRendererProps {
    className?: string;
}

export const ConnectedCustomerServiceReturnsPageRenderer: FC<ConnectedCustomerServiceReturnsPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.customerServiceReturnsPageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServiceReturnsPageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchCustomerServiceReturnsPage());
    }, []);

    return (
        <CustomerServiceReturnsPageRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
