import { FC, ReactElement } from 'react';

import { NavigationItem } from '../../components';
import { IconButton } from '../../compositions';
import { AccountNavigationItem } from '../../entities/Account/Account';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';

import './AccountNavigationSidebar.scss';

interface AccountNavigationSidebarProps {
    className?: string;
}

export const AccountNavigationSidebar: FC<AccountNavigationSidebarProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const navigationItems: AccountNavigationItem[] = [
        {
            id: 1,
            icon: 'dashboard',
            link: {
                label: trans(appRoutes[AppRoute.accountDashboard].label),
                href: trans(appRoutes[AppRoute.accountDashboard].path),
            },
        },
        {
            id: 2,
            icon: 'user',
            link: {
                label: trans(appRoutes[AppRoute.accountData].label),
                href: trans(appRoutes[AppRoute.accountData].path),
            },
        },
        {
            id: 3,
            icon: 'orders',
            link: {
                label: trans(appRoutes[AppRoute.accountOrders].label),
                href: trans(appRoutes[AppRoute.accountOrders].path),
            },
        },
        {
            id: 4,
            icon: 'heart',
            link: {
                label: trans(appRoutes[AppRoute.accountWishlist].label),
                href: trans(appRoutes[AppRoute.accountWishlist].path),
            },
        },
        {
            id: 5,
            icon: 'lock',
            link: {
                label: trans(appRoutes[AppRoute.accountChangePassword].label),
                href: trans(appRoutes[AppRoute.accountChangePassword].path),
            },
        },
    ];

    // TODO: Handle log out
    const handleLogOut = (): void => {
        console.log('Handle log out');
    };

    return (
        <aside className={`account-navigation-sidebar ${className}`}>
            <nav aria-label={trans('containers.accountNavigationSidebar.accountNavigation')}>
                <ul className="account-navigation-sidebar__list">
                    {navigationItems.map(item => (
                        <NavigationItem
                            key={item.id}
                            icon={item.icon}
                            to={item.link.href}
                            label={item.link.label}
                            className="account-navigation-sidebar__item"
                            navLinkClassName="account-navigation-sidebar__link"
                        />
                    ))}
                </ul>

                <IconButton
                    icon="logout"
                    text={trans('common.logOut')}
                    onClick={handleLogOut}
                    className="account-navigation-sidebar__logout-button"
                    iconClassName="account-navigation-sidebar__logout-button-icon"
                    labelClassName="account-navigation-sidebar__logout-button-label"
                />
            </nav>
        </aside>
    );
};
