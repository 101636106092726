import { Params } from 'react-router-dom';

export const replaceUrlValuesWithParamKeys = (url: string, params: Params): string => {
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((accumulatedUrl, [key, value]) => {
        if (!value) return accumulatedUrl;

        return accumulatedUrl.replace(value, `:${key}`);
    }, url);
};

export const replaceUrlParamKeysWithValues = (url: string, params: Params): string => {
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((accumulatedUrl, [key, value]) => {
        if (!value) return accumulatedUrl;

        return accumulatedUrl.replace(`:${key}`, value);
    }, url);
};
