import {
    DetailsHTMLAttributes,
    FC,
    PropsWithChildren,
    ReactElement,
} from 'react';

import classNames from 'classnames';

import { Icon, SafeHtml } from '../../components';

import './Accordion.scss';

interface AccordionProps extends DetailsHTMLAttributes<HTMLDetailsElement>, PropsWithChildren {
    header: string;
    htmlContent?: string;
    hasBorder?: boolean;
    className?: string;
    headingClassName?: string;
    contentClassName?: string;
}

export const Accordion: FC<AccordionProps> = ({
    header,
    htmlContent,
    hasBorder,
    className = '',
    headingClassName = '',
    contentClassName = '',
    children,
    ...accordionProps
}): ReactElement => {
    const accordionClassNames = classNames('accordion', {
        'accordion--has-border': hasBorder,
    }, className);

    return (
        <details
            {...accordionProps}
            className={accordionClassNames}
        >
            <summary className={`accordion__summary ${headingClassName}`}>
                {header}

                <Icon name="chevron-down" className="accordion__icon" />
            </summary>

            <div className={`accordion__content-wrapper ${contentClassName}`}>
                <SafeHtml
                    html={htmlContent}
                    className="accordion__content"
                />

                {children}
            </div>
        </details>
    );
};
