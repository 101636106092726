import { FC, ReactElement } from 'react';

import { useHits } from 'react-instantsearch';

import { ProductCard } from '../../../../compositions';
import { ProductHitResource } from '../../../../entities/Api/Webshop/Resource/ProductHit';
import { Product } from '../../../../entities/Product/Product';
import { transformProductHitToProduct } from '../../../../entities/Product/ProductTransformers';

import './InstantSearchProductList.scss';

interface InstantSearchProductListProps {
    className?: string;
}

export const InstantSearchProductList: FC<InstantSearchProductListProps> = ({
    className = '',
}): ReactElement => {
    const { items } = useHits<ProductHitResource>();

    const products: Product[] = items.map(transformProductHitToProduct);

    return (
        <div className={`instant-search-product-list ${className}`}>
            {products.map(product => (
                <ProductCard
                    key={product.id}
                    product={product}
                />
            ))}
        </div>
    );
};
