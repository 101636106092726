import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Block } from '../../../entities/Block/Block';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomerServiceOrderingPageState = AsyncReduxState<{
    blocks: Block[];
}>;

const initialState: CustomerServiceOrderingPageState = {
    ...initialAsyncReduxState,
    blocks: [],
};

const customerServiceOrderingPageSlice = createSlice({
    name: 'customerServiceOrderingPageSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): CustomerServiceOrderingPageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): CustomerServiceOrderingPageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomerServiceOrderingPageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomerServiceOrderingPageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlocks(state, action: PayloadAction<Block[]>): CustomerServiceOrderingPageState {
            return {
                ...state,
                blocks: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlocks,
} = customerServiceOrderingPageSlice.actions;

export default customerServiceOrderingPageSlice;
