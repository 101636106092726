/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconWayfindingEnter: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon className={`icon__stroke ${pathClassName}`} points="80.4 18.7 80.4 71.3 67.9 71.3 67.9 40.4 27.8 80.6 18.9 71.7 59.3 31.3 27.9 31.3 27.9 18.7 80.4 18.7" />
    </svg>
));
