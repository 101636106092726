import { FC, ReactElement } from 'react';

import { LoginForm } from '../../../containers';
import { LoginFormData } from '../../../entities/@forms/LoginForm/LoginForm';
import { authenticateUser } from '../../../redux/authentication/authenticationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedLoginFormProps {
    className?: string;
}

export const ConnectedLoginForm: FC<ConnectedLoginFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.authenticationSlice.isLoading);
    const error = useTypedSelector(state => state.authenticationSlice.error);

    const handleSubmit = (formData: LoginFormData): void => {
        dispatch(authenticateUser(formData));
    };

    return (
        <LoginForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
