import { ServiceContactDetails } from '../../entities/@blocks/ServiceSection/ServiceSection';

export const MOCK_SERVICE_CONTACT_DETAILS: ServiceContactDetails = {
    whatsApp: {
        phoneNumber: '+31612345678',
        workingHours: [
            { from: '12:00', to: '22:00' }, // Sunday
            { from: '08:00', to: '17:00' },
            { from: '08:00', to: '17:00' },
            { from: '08:00', to: '17:00' },
            { from: '08:00', to: '17:00' },
            { from: '08:00', to: '17:00' },
            { from: '10:00', to: '22:00' }, // Saturday
        ],
    },
    phone: {
        phoneNumber: '+31886550555',
        workingHours: [
            { from: '12:00', to: '20:00' }, // Sunday
            { from: '08:00', to: '16:00' },
            { from: '08:00', to: '16:00' },
            null, // Closed on Wednesday
            { from: '08:00', to: '16:00' },
            { from: '08:00', to: '16:00' },
            { from: '10:00', to: '20:00' }, // Saturday
        ],
    },
    email: {
        emailAddress: 'info@loods5.nl',
        workingHours: [], // 24/7
    },
};
