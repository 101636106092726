import { FC, ReactElement } from 'react';

import { Picture, SafeHtml, Wrapper } from '../../../components';
import { LinkButton, Video } from '../../../compositions';
import { Link } from '../../../entities/Link/Link';
import { isSilentLoop, MediaItem } from '../../../entities/Media/Media';

import './TextMedia.scss';

interface TextMediaProps {
    media: MediaItem;
    title: string;
    descriptionHtml: string;
    link: Link;
    className?: string;
}

export const TextMedia: FC<TextMediaProps> = ({
    media,
    title,
    descriptionHtml,
    link,
    className = '',
}): ReactElement => (
    <div className={`text-media ${className}`}>
        <Wrapper className="text-media__wrapper">
            <div className="text-media__media-wrapper">
                {media.image && (
                    <Picture
                        {...media.image}
                        className="text-media__picture"
                        imageClassName="text-media__image"
                    />
                )}

                {media.video && (
                    <Video
                        {...media.video}
                        {...isSilentLoop}
                        className="text-media__video"
                    />
                )}
            </div>

            <div className="text-media__text-wrapper">
                <h2 className="text-media__title">
                    {title}
                </h2>

                <SafeHtml
                    html={descriptionHtml}
                    className="text-media__description-html"
                />

                <LinkButton
                    to={link.href}
                    text={link.label}
                    className="text-media__button"
                />
            </div>
        </Wrapper>
    </div>
);
