export enum LinkTarget {
    blank = '_blank',
    self = '_self',
    top = '_top',
}

export interface Link {
    href: string;
    label: string;
    target?: LinkTarget;
}
