import { FC } from 'react';

import { LinkButton } from '../../../../compositions';
import { useTrans } from '../../../../hooks';

import './OrderItemStatus.scss';

interface OrderItemStatusProps {
    className?: string;
    deliveryDate: string;
    paymentMethod: string;
    paymentProviderIcon: string;
    invoiceLink: string;
    canceled: boolean;
}

export const OrderItemStatus: FC<OrderItemStatusProps> = ({
    className = '',
    deliveryDate,
    paymentMethod,
    paymentProviderIcon,
    invoiceLink,
    canceled,
}) => {
    const trans = useTrans();

    return (
        <div className={`order-status ${className}`}>
            <div className="order-status__item">
                <p className="order-status__title">
                    {trans('pages.accountOrderDetail.status')}
                </p>
                <p>
                    {trans('pages.accountOrderDetail.deliveredAt', { deliveryDate })}
                </p>
            </div>

            <div className="order-status__item">
                <p className="order-status__title">
                    {trans('pages.accountOrderDetail.paymentMethod', { paymentMethod })}
                </p>
                <div className="order-status__item-payment">
                    <p>{trans('pages.accountOrderDetail.paymentProvider')}</p>
                    <img src={paymentProviderIcon} alt={paymentMethod} />
                </div>
                <LinkButton
                    className="order-status__item-link"
                    text={trans('pages.accountOrderDetail.seeInvoice')}
                    to={invoiceLink}
                    isSmall
                />
            </div>

            <div className="order-status__item">
                <p className="order-status__title">
                    {trans('pages.accountOrderDetail.canceled')}
                </p>
                <p>
                    {!canceled ? trans('pages.accountOrderDetail.nothingCanceled') : trans('pages.accountOrderDetail.productCanceled')}
                </p>
            </div>
        </div>
    );
};
