import { FC, ReactElement } from 'react';

import { Block } from '../../../entities/Block/Block';
import { BlockRenderer } from '../..';

import './CustomerServicePageRenderer.scss';

interface CustomerServicePageRendererProps {
    isLoading: boolean;
    blocks: Block[];
    className?: string;
}

export const CustomerServicePageRenderer: FC<CustomerServicePageRendererProps> = ({
    isLoading,
    blocks,
    className = '',
}): ReactElement => (
    <div className={`customer-service-page-renderer ${className}`}>
        <BlockRenderer
            isLoading={isLoading}
            blocks={blocks}
            className="customer-service-page-renderer__block-renderer"
        />
    </div>
);
