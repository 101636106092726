import { Wishlist } from '../../entities/Wishlist/Wishlist';
import {
    MOCK_PRODUCT_1,
    MOCK_PRODUCT_2,
    MOCK_PRODUCT_3,
} from './product';

export const MOCK_WISHLISTS: Wishlist[] = [
    {
        id: '1',
        name: 'Verlanglijst naam 1',
        products: [MOCK_PRODUCT_1, MOCK_PRODUCT_2],
    },
    {
        id: '2',
        name: 'Verlanglijst naam 2',
        products: [MOCK_PRODUCT_3],
    },
    {
        id: '3',
        name: 'Verlanglijst naam 3',
        products: [MOCK_PRODUCT_1, MOCK_PRODUCT_3],
    },
];
