import { FC, ReactElement } from 'react';

import { useTrans } from '../../../hooks';
import { ButtonProps, IconButton } from '../..';

import './PlayButton.scss';

export interface PlayButtonProps extends Omit<ButtonProps, 'text'> {
    isPlaying?: boolean;
    text?: string;
    className?: string;
}

export const PlayButton: FC<PlayButtonProps> = ({
    isPlaying,
    text,
    className = '',
    ...buttonProps
}): ReactElement => {
    const trans = useTrans();

    return (
        <IconButton
            {...buttonProps}
            hideLabel
            icon={isPlaying ? 'pause' : 'play'}
            text={text || trans(`common.${isPlaying ? 'pause' : 'play'}`)}
            className={`play-button ${className}`}
            iconClassName="play-button__icon"
        />
    );
};
