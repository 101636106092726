import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import {
    FaqDataInterface,
    FaqDataResource,
    FaqItemInterface,
    FaqItemResource,
} from './Faq';

export const transformToFaqItem = (resource: FaqItemResource): FaqItemInterface => ({
    id: resource.id,
    question: resource.question || '',
    answer: resource.response || '',
});

export const transformToFaqItems = (resource: FaqDataResource): FaqDataInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const faqItems = resource.faqs.map(transformToFaqItem);

    return {
        ...defaultBlock,
        type: BlockType.faqs,
        title: resource.title || '',
        faqItems,
    };
};
