import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../compositions';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { SearchPopupProductItem } from '../../../../entities/SearchPopup/SearchPopup';
import { convertNumberToCurrency } from '../../../../helpers/number';
import { useTrans } from '../../../../hooks';

import './ProductSuggestions.scss';

interface ProductSuggestionsProps {
    items: SearchPopupProductItem[];
    onClickItem: (product: SearchPopupProductItem) => void;
    className?: string;
}

export const ProductSuggestions: FC<ProductSuggestionsProps> = ({
    items,
    onClickItem,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`product-suggestions ${className}`}>
            <p className="product-suggestions__label">
                {trans('common.products')}
            </p>

            <ul>
                {items.map((product) => {
                    const handleProductItemClick = (): void => onClickItem(product);

                    return (
                        <li key={product.id}>
                            <LinkButton
                                to={trans(appRoutes[AppRoute.productDetail].path).replace(':slug', product.slug)}
                                text={`${product.name ? `${product.name} ⋅ ` : ''}${product.brand} ⋅ ${convertNumberToCurrency(product.price, true)}`}
                                onClick={handleProductItemClick}
                                className="product-suggestions__list-item"
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

