import { ForgotPasswordFormData } from '../../../../entities/@forms/ForgotPasswordForm/ForgotPasswordForm';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
} from '../../../../entities/FormValidation/FormValidationService';

export type ForgotPasswordFormErrors = FormErrors<ForgotPasswordFormData>;

export const validateForgotPasswordFormData = (formData: ForgotPasswordFormData): FormValidation<ForgotPasswordFormErrors> => {
    const errors: ForgotPasswordFormErrors = {
        username: validateEmailAddress(formData.username),
    };

    return validateForm<ForgotPasswordFormErrors>(errors);
};
