import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformToMediaItem } from '../../Media/MediaTransformers';
import { MediaGridInterface, MediaGridResource } from './MediaGrid';

export const transformToMediaGrid = (resource: MediaGridResource): MediaGridInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const mediaItems = resource.mediaItems.map(transformToMediaItem);

    return {
        ...defaultBlock,
        type: BlockType.mediaGrid,
        mediaItems,
    };
};
