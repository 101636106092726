import { FC, ReactElement } from 'react';

import { Picture, Price } from '../../../../components';

import './OrderItemProduct.scss';

interface OrderItemProductProps {
    product: {
        id: number;
        name: string;
        brand: string;
        price: number;
        image: string;
    };
    className?: string;
}

export const AccountDashboardOrderItemProduct: FC<OrderItemProductProps> = ({
    product,
    className = '',
}): ReactElement => (
    <div className={`order-product ${className}`}>
        <Picture
            className="order-product__image"
            alt={product.name}
            src={product.image}
        />
        <h3 className="order-product__brand">{product.brand}</h3>
        <h2 className="order-product__name">{product.name}</h2>
        <Price
            amount={product.price}
            className="product-card__price"
            includeCurrencySymbol
        />
    </div>
);
