import { IconName } from '../../components';
import { WayfindingType } from './Wayfinding';

export const transformToWayfindingIcon = (resource: WayfindingType): IconName => {
    switch (resource) {
        case WayfindingType.cta: {
            return 'wayfinding-cta';
        }
        case WayfindingType.download: {
            return 'wayfinding-download';
        }
        case WayfindingType.left: {
            return 'wayfinding-left';
        }
        case WayfindingType.right: {
            return 'wayfinding-right';
        }
        case WayfindingType.up: {
            return 'wayfinding-up';
        }
        case WayfindingType.enter:
        default: {
            return 'wayfinding-enter';
        }
    }
};
