import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { WishlistCard } from '../../compositions';
import { useTrans } from '../../hooks';
import { MOCK_WISHLISTS } from '../../mock/mock-data';

import './AccountWishlistPage.scss';

export const AccountWishlistPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="account-wishlist-page">
            <div className="account-wishlist-page__header">
                <h5 className="account-wishlist-page__heading">
                    {trans('pages.accountWishlist.wishlist')}
                </h5>

                <p className="account-wishlist-page__description">
                    {trans('pages.accountWishlist.description')}
                </p>
            </div>

            {MOCK_WISHLISTS.map((wishlist) => (
                <div key={wishlist.id} className="account-wishlist-page__content">
                    <h2 className="account-wishlist-page__wishlist-title">
                        {wishlist.name}
                    </h2>
                    <div className="account-wishlist-page__wishlist-grid">
                        {wishlist.products.map((product) => (
                            <WishlistCard
                                product={product}
                                key={product.id}
                                className="account-wishlist-page__wishlist-card"
                            />
                        ))}
                    </div>
                </div>
            ))}
        </Page>
    );
};
