import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Wrapper } from '../../components';
import { IconButton } from '../../compositions';
import { ConnectedInstantSearchInstance, ConnectedProductsSearch } from '../../connectors';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { Link } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { SearchPopupProductItem } from '../../entities/SearchPopup/SearchPopup';
import { StoreWithWorkingHours } from '../../entities/Store/Store';
import { useDeviceWidth, useHandleClickOutside, useTrans } from '../../hooks';
import {
    LinkSuggestions,
    ProductSuggestions,
    Stores,
    Suggestions,
} from './subcomponents';

import './SearchPopup.scss';

interface SearchPopupProps {
    searchTerm: string;
    products: SearchPopupProductItem[];
    pages: Link[];
    locations: StoreWithWorkingHours[];
    pageSuggestions: Link[];
    recentSearches?: string[];
    onSetStoreTermInRecentSearches: (term: string) => void;
    onSetProducts: (products: SearchPopupProductItem[]) => void;
    onSetSearchTerm: (term: string) => void;
    className?: string;
}

export const SearchPopup: FC<SearchPopupProps> = ({
    searchTerm,
    products,
    pages,
    locations,
    pageSuggestions,
    recentSearches,
    onSetStoreTermInRecentSearches,
    onSetProducts,
    onSetSearchTerm,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { isMobile } = useDeviceWidth();

    const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
    const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);

    const itemRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect((): void => {
        if (isMobile && popUpIsOpen) {
            return document.body.classList.add('has-active-modal');
        }

        return document.body.classList.remove('has-active-modal');
    }, [popUpIsOpen]);

    const closePopUp = (): void => {
        setPopUpIsOpen(false);
    };

    useHandleClickOutside(itemRef, closePopUp);

    const navigateToSearchResults = (term: string): void => {
        navigate({
            pathname: trans(appRoutes[AppRoute.search].path),
            search: createSearchParams({ q: term }).toString(),
        });
    };

    const handleClickRecentSearch = (event: FormEvent<HTMLButtonElement>): void => {
        const { currentTarget } = event;
        const { innerText } = currentTarget;

        closePopUp();
        onSetSearchTerm(innerText);
        navigateToSearchResults(innerText);
    };

    const handleShowAllResults = (): void => {
        closePopUp();
        navigateToSearchResults(searchTerm);
        onSetStoreTermInRecentSearches(searchTerm);
    };

    const handleSearchButtonClick = (): void => {
        setPopUpIsOpen(!popUpIsOpen);

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const handleNewProducts = (newProducts: SearchPopupProductItem[]): void => {
        onSetProducts(newProducts.slice(0, 6));
        setHasStartedTyping(true);
    };

    const popupWrapperClassNames = classNames('search-popup__wrapper', {
        'search-popup__wrapper--is-active': popUpIsOpen,
    });

    return (
        // TODO: On this way, the API will always be called because this file is imported by the MenuBar which is through the whole application.
        <ConnectedInstantSearchInstance>
            <div
                ref={itemRef}
                className={`search-popup ${className}`}
            >
                <IconButton
                    hideLabel
                    icon={(popUpIsOpen && isMobile) ? 'cross' : 'search'}
                    text={trans('common.search')}
                    onClick={handleSearchButtonClick}
                    className="search-popup__link"
                    iconClassName="search-popup__link-icon"
                />

                <div className={popupWrapperClassNames}>
                    <Wrapper>
                        <ConnectedProductsSearch
                            ref={searchInputRef}
                            onNewSearchTerm={onSetSearchTerm}
                            onNewProducts={handleNewProducts}
                            onIsTyping={setHasStartedTyping}
                            onSubmit={handleShowAllResults}
                        />

                        <div className="search-popup__search-results-wrapper">
                            {(!hasStartedTyping && !products.length) && (
                                <>
                                    <LinkSuggestions
                                        label={trans('containers.searchPopup.pageSuggestions')}
                                        links={pageSuggestions}
                                        onClickLink={closePopUp}
                                    />

                                    {locations.length > 0 && (
                                        <Stores
                                            locations={locations}
                                            onClickSeeAll={closePopUp}
                                        />
                                    )}
                                </>
                            )}

                            {hasStartedTyping && (
                                <>
                                    {recentSearches && (
                                        <Suggestions
                                            label={trans('containers.searchPopup.recentSearches')}
                                            items={recentSearches}
                                            onClickItem={handleClickRecentSearch}
                                        />
                                    )}

                                    {products.length > 0 && (
                                        <ProductSuggestions
                                            items={products}
                                            onClickItem={closePopUp}
                                        />
                                    )}

                                    {pages.length > 0 && (
                                        <LinkSuggestions
                                            label={trans('containers.searchPopup.pages')}
                                            links={pageSuggestions}
                                            onClickLink={closePopUp}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        {hasStartedTyping && (
                            <IconButton
                                isSmall
                                icon="arrow-right"
                                text={trans('containers.searchPopup.showAllResults')}
                                iconPos={HorizontalAlignment.right}
                                onClick={handleShowAllResults}
                                className="search-popup__show-all-results-button"
                            />
                        )}
                    </Wrapper>
                </div>
            </div>
        </ConnectedInstantSearchInstance>
    );
};
