/* eslint-disable arrow-body-style */
export const stringContainsValue = (string?: string): boolean => {
    if (!string) {
        return false;
    }

    return string.trim().length > 0;
};

export const stringEqualsLength = (string: string, exactLength: number): boolean => {
    return string.length === exactLength;
};

export const stringHasMinimumLength = (string: string, minLength: number): boolean => {
    return string.length >= minLength;
};

export const stringDoesNotExceedLength = (string: string, maxLength: number): boolean => {
    return string.length <= maxLength;
};

export const stringMatchesRegEx = (string: string, regEx: RegExp, flag?: string): boolean => {
    const regExp = new RegExp(regEx, flag);

    return regExp.test(string);
};
/* eslint-enable arrow-body-style */
