import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { PageResponse, PageType } from '../../../entities/Page/Page';
import { generatePageQuery } from '../../../entities/Page/PageRequests';
import { transformToPage } from '../../../entities/Page/PageTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlocks,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './customerServiceDeliveryPageSlice';

export const fetchCustomerServiceDeliveryPage: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generatePageQuery(PageType.customerServiceDelivery);

        const customerServiceDeliveryPageResponse = await getStrapiApiCall<PageResponse>(`/pages?${query}`);

        if (!isFetchResultSuccessful(customerServiceDeliveryPageResponse)) {
            dispatch(setError(customerServiceDeliveryPageResponse.error));
            return;
        }

        const { data: customerServiceDeliveryPageData } = customerServiceDeliveryPageResponse.data;

        const customerServiceDeliveryPageResource = customerServiceDeliveryPageData
            ? customerServiceDeliveryPageData[0]
            : undefined;

        const customerServiceDeliveryPage = customerServiceDeliveryPageResource
            ? transformToPage(customerServiceDeliveryPageResource)
            : undefined;

        const blocks = customerServiceDeliveryPage?.blocks || [];

        dispatch(setBlocks(blocks));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomerServiceDeliveryPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
