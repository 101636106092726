import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { ErrorLabel } from '../../../components';
import { Button, TextInput } from '../../../compositions';
import { NewsletterFormData } from '../../../entities/@forms/NewsletterForm/NewsletterForm';
import { FormProps } from '../../../entities/Form/Form';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { NewsletterFormErrors, validateNewsletterFormData } from './validations';

import './NewsletterForm.scss';

interface NewsletterFormProps extends FormProps<NewsletterFormData> {
    className?: string;
}

export const NewsletterForm: FC<NewsletterFormProps> = ({
    isLoading,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<NewsletterFormErrors>({});

    const [email, setEmail] = useState<string>('');

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData: NewsletterFormData = { email };

        const [errors, hasErrors] = validateNewsletterFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`newsletter-form ${className}`}
        >
            <TextInput
                hasUnderline
                hideLabel
                required
                type="email"
                autoComplete="email"
                label={trans('containers.newsletterForm.email.label')}
                placeholder={trans('containers.newsletterForm.email.placeholder')}
                value={email}
                error={formErrors.email}
                disabled={isLoading}
                onChange={setEmail}
                className="newsletter-form__input"
            />

            <Button
                isSmall
                type="submit"
                text={trans('containers.newsletterForm.submit')}
                className="newsletter-form__submit-button"
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="newsletter-form__error-label"
                />
            )}
        </form>
    );
};
