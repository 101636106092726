import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { BlogOverviewBlockInterface, BlogOverviewBlockResource } from './BlogOverview';

export const transformToBlogOverview = (resource: BlogOverviewBlockResource): BlogOverviewBlockInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const categories = resource.blogCategory
        ? [resource.blogCategory.title]
        : [];

    return {
        ...defaultBlock,
        type: BlockType.blogOverview,
        title: resource.title,
        categories,
        amount: resource.amount,
        showPagination: !!resource.showPagination,
    };
};
