export const MOCK_ACCORDION_DATA = [
    {
        header: 'Wat is jullie retourbeleid?',
        htmlContent: '<p>We accepteren retouren binnen 30 dagen na aankoop. Het artikel moet in originele staat zijn.</p>',
        hasBorder: true,
        headingClassName: 'accordion-heading',
    },
    {
        header: 'Hoe lang duurt de levering?',
        htmlContent: '<p>Onze standaard levertijd is 3-5 werkdagen. Expresslevering is beschikbaar voor een meerprijs.</p>',
        hasBorder: true,
    },
    {
        header: 'Kan ik mijn bestelling wijzigen?',
        htmlContent: '<p>Ja, wijzigingen kunnen worden doorgevoerd binnen 24 uur na het plaatsen van de bestelling.</p>',
        hasBorder: false,
    },
];
