import { FC, ReactElement, useEffect } from 'react';

import { Footer } from '../../containers';
import { transformToFooterNavigation } from '../../entities/Footer/FooterTransformers';
import { MOCK_FOOTER_NAVIGATION } from '../../mock/mock-data';
import { fetchCustomer } from '../../redux/customer/customerActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedFooter: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const customer = useTypedSelector(state => state.customerSlice.customer);

    const mockFooterNavigation = transformToFooterNavigation(MOCK_FOOTER_NAVIGATION);

    // TODO: Move to proper location
    useEffect((): void => {
        dispatch(fetchCustomer());
    }, []);

    console.log('Customer:', customer);

    return (
        <Footer footerNavigation={mockFooterNavigation} />
    );
};
