/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconHappy: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <circle className="icon__stroke" cx="50" cy="50" r="46.9" />
        <path className="icon__stroke" d="M71.5,62.6c-4.3,7.4-12.4,12.3-21.5,12.3s-17.2-5-21.5-12.3" />
        <circle cx="34.4" cy="40.4" r="4.8" />
        <circle cx="65.6" cy="40.4" r="4.8" />
    </svg>
));
