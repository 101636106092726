import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { PageResponse, PageType } from '../../../entities/Page/Page';
import { generatePageQuery } from '../../../entities/Page/PageRequests';
import { transformToPage } from '../../../entities/Page/PageTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlocks,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './customerServiceReturnsPageSlice';

export const fetchCustomerServiceReturnsPage: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generatePageQuery(PageType.customerServiceReturns);

        const customerServiceReturnsPageResponse = await getStrapiApiCall<PageResponse>(`/pages?${query}`);

        if (!isFetchResultSuccessful(customerServiceReturnsPageResponse)) {
            dispatch(setError(customerServiceReturnsPageResponse.error));
            return;
        }

        const { data: customerServiceReturnsPageData } = customerServiceReturnsPageResponse.data;

        const customerServiceReturnsPageResource = customerServiceReturnsPageData
            ? customerServiceReturnsPageData[0]
            : undefined;

        const customerServiceReturnsPage = customerServiceReturnsPageResource
            ? transformToPage(customerServiceReturnsPageResource)
            : undefined;

        const blocks = customerServiceReturnsPage?.blocks || [];

        dispatch(setBlocks(blocks));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomerServiceReturnsPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
