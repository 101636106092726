import { StrapiPaginationResource } from '../Pagination/Pagination';

export interface StrapiEntityData {
    id: number;
    documentId: string;
}

export interface StrapiEntity <T>{
    data?: T;
}

export interface StrapiMeta <T>{
    meta: T;
}

export enum StrapiQuerySortDirection {
    asc = 'asc',
    desc = 'desc',
}

export type StrapiQuerySortObject = Record<string, StrapiQuerySortDirection>;

export type StrapiQueryPaginationObject = Partial<Pick<StrapiPaginationResource, 'page' | 'pageSize'>>;
