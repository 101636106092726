import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { LinkIconButton } from '../../compositions';
import { AccountDashboardOrderDetail } from '../../containers';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { useTrans } from '../../hooks';

import './AccountOrderDetailPage.scss';

export const AccountOrderDetailPage: FC = (): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const trans = useTrans();

    return (
        <Page className="account-order-detail">
            <Wrapper>
                <div className="account-order-detail__content">
                    <LinkIconButton
                        to={trans('routes.orders.path')}
                        icon="chevron-left"
                        iconPos={HorizontalAlignment.left}
                        text={trans('pages.accountOrderDetail.backButtonText')}
                        className="account-order-detail__back-button"
                        iconClassName="account-order-detail__back-button-icon"
                        isSmall
                    />

                    <h1 className="account-order-detail__title">
                        {trans('pages.accountOrderDetail.orderTitle', { id })}
                    </h1>

                    <AccountDashboardOrderDetail />
                </div>
            </Wrapper>
        </Page>
    );
};
