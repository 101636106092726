import { IconName } from '../../components';
import { RouteParams } from '../../LocalizedRoutes';
import { Link } from '../Link/Link';

export enum AccountPages {
    dashboard = 'dashboard',
    data = 'data',
    orders = 'orders',
    accountWishlist = 'accountWishlist',
    orderDetail = 'orderDetail',
    changePassword = 'changePassword',
}

export interface AccountParams extends RouteParams {
    tab?: AccountPages;
}

export interface AccountNavigationItem {
    id: number;
    icon: IconName;
    link: Link;
}

export enum AccountDashboardOrderTab {
    onlinePurchase = 'onlinePurchase',
    storePurchase = 'storePurchase',
}

export enum AccountDashboardConfigTab {
    myData = 'myData',
    myAddresses = 'myAddresses',
    giftcard = 'giftcard',
}
