/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconEdit: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M61.9,22.2l15.9,15.9M69.8,14.3c1-1.2,2.3-2.2,3.7-2.9s2.9-1.1,4.5-1.1c1.6,0,3.1.2,4.6.8,1.5.6,2.8,1.4,3.9,2.5,1.1,1.1,2,2.4,2.5,3.9.6,1.5.8,3,.7,4.6,0,1.6-.5,3.1-1.2,4.5-.7,1.4-1.7,2.6-2.9,3.6l-53.6,53.6-21.8,6,6-21.8L69.8,14.3Z" />
    </svg>
));
