import { FC, ReactElement, useState } from 'react';

import { BlogCategories } from '../../containers';
import { useClientEffect } from '../../hooks';
import { fetchBlogCategories } from '../../redux/@blog/blogCategories/blogCategoriesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedBlogCategoriesProps {
    onChange: (category: string) => void;
    className?: string;
}

export const ConnectedBlogCategories: FC<ConnectedBlogCategoriesProps> = ({
    onChange,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.blogCategoriesSlice.isLoading);
    const categoryTabs = useTypedSelector(state => state.blogCategoriesSlice.categoryTabs);

    const [activeCategory, setActiveCategory] = useState<string>('');

    useClientEffect((): void => {
        dispatch(fetchBlogCategories());
    }, []);

    const handleCategorySelect = (category: string): void => {
        setActiveCategory(category);
        onChange(category);
    };

    return (
        <BlogCategories
            isLoading={isLoading}
            activeCategory={activeCategory}
            categories={categoryTabs}
            onCategorySelect={handleCategorySelect}
            className={className}
        />
    );
};
