import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon, Picture, Wrapper } from '../../../../../components';
import { Button, VideoTheatre } from '../../../../../compositions';
import { MediaItem } from '../../../../../entities/Media/Media';
import { useTrans } from '../../../../../hooks';

import './ProductMediaSlider.scss';

interface ProductMediaSliderProps {
    mediaItems: MediaItem[];
    className?: string;
}

export const ProductMediaSlider: FC<ProductMediaSliderProps> = ({
    mediaItems,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [activeMediaItem, setActiveMediaItem] = useState<MediaItem>();

    useEffect((): void => {
        if (mediaItems.length > 0) {
            setActiveMediaItem(mediaItems[0]);
        }
    }, [mediaItems]);

    return (
        <div className={`product-media-slider ${className}`}>
            <Wrapper className="product-media-slider__active-item-wrapper">
                {activeMediaItem && (
                    <div className="product-media-slider__active-item">
                        {activeMediaItem.image && !activeMediaItem.video && (
                            <Picture
                                {...activeMediaItem.image}
                                className="product-media-slider__active-item-image"
                            />
                        )}

                        {activeMediaItem.video && (
                            <VideoTheatre
                                {...activeMediaItem.video}
                                playsInline={activeMediaItem.videoPlaysInline}
                                poster={activeMediaItem.image}
                                className="product-media-slider__video"
                            />
                        )}
                    </div>
                )}
            </Wrapper>

            <ul className="product-media-slider__selector">
                {mediaItems.map(mediaItem => {
                    const handleSelect = (): void => {
                        setActiveMediaItem(mediaItem);
                    };

                    const itemClassNames = classNames('product-media-slider__selector-item', {
                        'product-media-slider__selector-item--is-video': mediaItem.video,
                    });

                    return (
                        <li key={mediaItem.id} className={itemClassNames}>
                            <Button
                                text={trans('containers.productDetail.selectMediaItem')}
                                onClick={handleSelect}
                                className="product-media-slider__selector-button"
                            >
                                {mediaItem.video && (
                                    <div className="product-media-slider__selector-video-icon-wrapper">
                                        <Icon name="play" className="product-media-slider__selector-video-icon" />
                                    </div>
                                )}

                                <Picture
                                    {...mediaItem.image}
                                    className="product-media-slider__selector-image"
                                />
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
