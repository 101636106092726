import { HorizontalAlignment } from '../../Alignment/Alignment';
import { DefaultBlock, DefaultBlockResource } from '../../Block/Block';
import { BlogPost, BlogPostResource } from '../../BlogPost/BlogPost';

export enum HighlightedBlogPostLayoutResource {
    left = 'Foto link',
    right = 'Foto rechts',
}

export interface HighlightedBlogPostResource extends DefaultBlockResource {
    blog?: BlogPostResource;
    layout?: HighlightedBlogPostLayoutResource;
}

export interface HighlightedBlogPostInterface extends DefaultBlock {
    blogPost?: BlogPost;
    imagePosition?: HorizontalAlignment;
}
