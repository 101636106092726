import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Link } from 'react-router-dom';

import { ErrorLabel } from '../../../components';
import {
    Button,
    Checkbox,
    PasswordInput,
    TextInput,
} from '../../../compositions';
import { LoginFormData } from '../../../entities/@forms/LoginForm/LoginForm';
import { FormProps } from '../../../entities/Form/Form';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { LoginFormErrors, validateLoginFormData } from './validations';

import './LoginForm.scss';

interface LoginFormProps extends FormProps<LoginFormData> {
    className?: string;
}

export const LoginForm: FC<LoginFormProps> = ({
    isLoading,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<LoginFormErrors>({});

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(true);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData: LoginFormData = {
            username,
            password,
            rememberMe,
        };

        const [errors, hasErrors] = validateLoginFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`login-form ${className}`}
        >
            <h2 className="login-form__heading">
                {trans('compositions.loginForm.heading')}
            </h2>

            <TextInput
                type="email"
                autoComplete="email"
                label={trans('compositions.loginForm.usernameLabel')}
                placeholder={trans('compositions.loginForm.usernamePlaceholder')}
                value={username}
                error={formErrors.username}
                disabled={isLoading}
                onChange={setUsername}
                className="login-form__username-input"
            />

            <PasswordInput
                autoComplete="current-password"
                label={trans('compositions.loginForm.passwordLabel')}
                placeholder={trans('compositions.loginForm.passwordPlaceholder')}
                value={password}
                error={formErrors.password}
                disabled={isLoading}
                onChange={setPassword}
                fieldClassName="login-form__password-input-field"
                className="login-form__password-input"
            />

            <Checkbox
                label={trans('compositions.loginForm.rememberMeLabel')}
                checked={rememberMe}
                disabled={isLoading}
                onChange={setRememberMe}
                className="login-form__remember-me-checkbox"
            />

            <Button
                type="submit"
                text={trans('compositions.loginForm.submitButton')}
                className="login-form__submit-button"
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="login-form__error-label"
                />
            )}

            <Link
                to={trans(appRoutes[AppRoute.forgotPassword].path)}
                className="login-form__forgot-password-button"
            >
                {trans('compositions.loginForm.forgotPasswordButton')}
            </Link>
        </form>
    );
};
