import { BlogOverviewRequestConfig, BlogOverviewResponse } from '../../../entities/BlogOverview/BlogOverview';
import { generateBlogOverviewQuery } from '../../../entities/BlogOverview/BlogOverviewRequests';
import { transformToBlogPost } from '../../../entities/BlogPost/BlogPostTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { transformStrapiPaginationToPagination } from '../../../entities/Pagination/PaginationTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setOverviews,
} from './blogOverviewsSlice';

export const fetchBlogOverview: ReduxFetchAction = (config: BlogOverviewRequestConfig) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateBlogOverviewQuery(config);

        const overviewResponse = await getStrapiApiCall<BlogOverviewResponse>(`/blogs?${query}`);

        if (!isFetchResultSuccessful(overviewResponse)) {
            dispatch(setError(overviewResponse.error));
            return;
        }

        const { data: blogOverviewData, meta: blogOverviewMeta } = overviewResponse.data;

        const blogPosts = blogOverviewData
            ? blogOverviewData.map(transformToBlogPost)
            : [];

        const overviewPagination = blogOverviewMeta
            ? transformStrapiPaginationToPagination(blogOverviewMeta.pagination)
            : undefined;

        const state = getState();
        const { overviews } = state.blogOverviewsSlice;

        const updatedOverviews = {
            ...overviews,
            [config.key]: {
                blogPosts,
                pagination: overviewPagination,
            },
        };

        dispatch(setOverviews(updatedOverviews));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBlogOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
