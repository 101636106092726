import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ConnectedForgotPasswordForm, ConnectedNewsletterCallToAction } from '../../connectors';
import { ServiceSection, UspSection } from '../../containers';
import { MOCK_SERVICE_CONTACT_DETAILS } from '../../mock/mock-data/contact';

import './ForgotPasswordPage.scss';

interface ForgotPasswordPageProps {
    className?: string;
}

export const ForgotPasswordPage: FC<ForgotPasswordPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`forgot-password-page ${className}`}>
        <Wrapper className="forgot-password-page__wrapper">
            <ConnectedForgotPasswordForm
                className="forgot-password-page__forgot-password-form"
            />
        </Wrapper>

        <UspSection
            items={[
                {
                    id: 0,
                    text: 'Online besteld? Gratis verzonden',
                    icon: 'cart-truck',
                },
                {
                    id: 1,
                    text: 'Keuze uit meer dan 300 meubelmerken',
                    icon: 'lamp',
                },
                {
                    id: 2,
                    text: 'Niet tevreden? Geld terug!',
                    icon: 'happy',
                },
            ]}
            className="forgot-password-page__section"
        />

        <div className="forgot-password-page__section forgot-password-page__service-section">
            <Wrapper className="contact-page__wrapper">
                <ServiceSection
                    title="Service & contact"
                    serviceContactDetails={MOCK_SERVICE_CONTACT_DETAILS}
                />
            </Wrapper>
        </div>

        <ConnectedNewsletterCallToAction
            title="Wekelijks inspiratie in je inbox?"
            description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
            textColor="#000000"
            className="forgot-password-page__section forgot-password-page__sub-footer"
        />
    </Page>
);
