/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconLamp: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon className="icon__stroke" points="28.3 5 71.9 5 87.6 47 12.3 47 28.3 5" />
        <path className="icon__stroke" d="M59.1,47v16.4s6.8,4.7,10.4,13.1-.8,16.4-3,18.7c-11,0-22,0-33,0-2.2-2.4-6.9-9.6-3-18.7,3.9-9.1,10.4-13.1,10.4-13.1v-16.4" />
    </svg>
));
