import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { PageResponse, PageType } from '../../../entities/Page/Page';
import { generatePageQuery } from '../../../entities/Page/PageRequests';
import { transformToPage } from '../../../entities/Page/PageTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlocks,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './customerServiceAccountPageSlice';

export const fetchCustomerServiceAccountPage: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generatePageQuery(PageType.customerServiceAccount);

        const customerServiceAccountPageResponse = await getStrapiApiCall<PageResponse>(`/pages?${query}`);

        if (!isFetchResultSuccessful(customerServiceAccountPageResponse)) {
            dispatch(setError(customerServiceAccountPageResponse.error));
            return;
        }

        const { data: customerServiceAccountPageData } = customerServiceAccountPageResponse.data;

        const customerServiceAccountPageResource = customerServiceAccountPageData
            ? customerServiceAccountPageData[0]
            : undefined;

        const customerServiceAccountPage = customerServiceAccountPageResource
            ? transformToPage(customerServiceAccountPageResource)
            : undefined;

        const blocks = customerServiceAccountPage?.blocks || [];

        dispatch(setBlocks(blocks));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomerServiceAccountPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
