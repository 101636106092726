import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FaqItemInterface } from '../../entities/@blocks/Faq/Faq';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type FaqState = AsyncReduxState<{
    faqItems: FaqItemInterface[];
}>;

const initialState: FaqState = {
    ...initialAsyncReduxState,
    faqItems: [],
};

const faqSlice = createSlice({
    name: 'faqSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): FaqState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): FaqState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): FaqState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): FaqState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFaqItems(state, action: PayloadAction<FaqItemInterface[]>): FaqState {
            return {
                ...state,
                faqItems: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setFaqItems,
} = faqSlice.actions;

export default faqSlice;
