import { FC, ReactElement } from 'react';

import { usePagination } from 'react-instantsearch';

import { Pagination } from '../../../../compositions';

import './InstantSearchPagination.scss';

interface InstantSearchPaginationProps {
    className?: string;
}

export const InstantSearchPagination: FC<InstantSearchPaginationProps> = ({
    className = '',
}): ReactElement => {
    const { nbPages, currentRefinement, refine } = usePagination();

    return (
        <div className={`instant-search-pagination ${className}`}>
            <Pagination
                numberOfPages={nbPages}
                currentPage={currentRefinement}
                onChange={refine}
            />
        </div>
    );
};
