import { MOCK_BRANDS } from '../../mock/mock-data';
import { LinkTarget } from '../Link/Link';
import { Brand } from './Brand';

export const transformToBrand = (brand: string): Brand => ({
    id: '1',
    name: brand,
    slug: brand.toLowerCase().replace(/\s+/g, '-'),
    link: {
        label: brand,
        target: LinkTarget.self,
        href: `/merk/${brand.toLowerCase().replace(/\s+/g, '-')}`,
    },
    image: MOCK_BRANDS[0].image, // TODO: Add link to get the brand logo from CDN
});
