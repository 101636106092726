import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { thunk } from 'redux-thunk';

import { isProduction, isSSR } from '../helpers';
import slices, { Slices } from './slices';

export type TypedDispatch = ThunkDispatch<Slices, unknown, Action>;
export type SliceGetter = () => Slices;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Slices> = useSelector;

export const store = configureStore({
    reducer: slices,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk),
    preloadedState: !isSSR
        ? window.__PRELOADED_STATE__
        : undefined,
    devTools: !isProduction,
});

// Allow the passed state from SSR to be garbage-collected
if (!isSSR) {
    delete window.__PRELOADED_STATE__;
}
