import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Block } from '../../../entities/Block/Block';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomerServiceAccountPageState = AsyncReduxState<{
    blocks: Block[];
}>;

const initialState: CustomerServiceAccountPageState = {
    ...initialAsyncReduxState,
    blocks: [],
};

const customerServiceAccountPageSlice = createSlice({
    name: 'customerServiceAccountPageSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): CustomerServiceAccountPageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): CustomerServiceAccountPageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomerServiceAccountPageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomerServiceAccountPageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlocks(state, action: PayloadAction<Block[]>): CustomerServiceAccountPageState {
            return {
                ...state,
                blocks: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlocks,
} = customerServiceAccountPageSlice.actions;

export default customerServiceAccountPageSlice;
