import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type AppState = AsyncReduxState<{
    hasServerData: boolean;
    currentVersion: string;
    latestVersion: string;
}>;

const initialState: AppState = {
    ...initialAsyncReduxState,
    hasServerData: false,
    currentVersion: '',
    latestVersion: '',
};

const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setHasServerData(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                hasServerData: action.payload,
            };
        },
        setCurrentVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                currentVersion: action.payload,
            };
        },
        setLatestVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                latestVersion: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setHasServerData,
    setCurrentVersion,
    setLatestVersion,
} = appSlice.actions;

export default appSlice;
