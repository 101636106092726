import { RouteData } from '../../LocalizedRoutes';

export enum AppRoute {
    // Content
    customerService = 'routes.customerService',
    customerServiceAccount = 'routes.customerServiceAccount',
    customerServiceDelivery = 'routes.customerServiceDelivery',
    customerServiceFaq = 'routes.customerServiceFaq',
    customerServiceOrdering = 'routes.customerServiceOrdering',
    customerServiceReturns = 'routes.customerServiceReturns',
    customerServiceTerms = 'routes.customerServiceTerms',
    // Other
    account = 'routes.account',
    accountChangePassword = 'routes.changePassword',
    accountDashboard = 'routes.dashboard',
    accountData = 'routes.data',
    accountOrders = 'routes.orders',
    accountWishlist = 'routes.accountWishlist',
    accountOrderDetail = 'routes.orderDetail',
    articleDetail = 'routes.articleDetail',
    blogPost = 'routes.blogPost',
    brandDetail = 'routes.brandDetail',
    contentBlocks = 'routes.contentBlocks',
    cart = 'routes.cart',
    forgotPassword = 'routes.forgotPassword',
    home = 'routes.home',
    homeWithInteractiveProductHeader = 'routes.homeWithInteractiveProductHeader',
    index = 'routes.index',
    inspiration = 'routes.inspiration',
    login = 'routes.login',
    notFound = 'routes.notFound',
    productCategory = 'routes.productCategory',
    productDetail = 'routes.productDetail',
    registration = 'routes.registration',
    search = 'routes.search',
    storeDetail = 'routes.storeDetail',
    storeOverview = 'routes.storeOverview',
    styleguide = 'routes.styleguide',
    wishlist = 'routes.wishlist',
}

export const appRoutes = Object.fromEntries<RouteData<AppRoute>>(
    Object.values(AppRoute).map(value => ([value, {
        key: value as AppRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);
