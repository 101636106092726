import { fetchCustomerServiceAccountPage } from '../../redux/@pages/customerServiceAccountPage/customerServiceAccountPageActions';
import { fetchCustomerServiceDeliveryPage } from '../../redux/@pages/customerServiceDeliveryPage/customerServiceDeliveryPageActions';
import { fetchCustomerServiceFaqPage } from '../../redux/@pages/customerServiceFaqPage/customerServiceFaqPageActions';
import { fetchCustomerServiceOrderingPage } from '../../redux/@pages/customerServiceOrderingPage/customerServiceOrderingPageActions';
import { fetchCustomerServicePage } from '../../redux/@pages/customerServicePage/customerServicePageActions';
import { fetchCustomerServiceReturnsPage } from '../../redux/@pages/customerServiceReturnsPage/customerServiceReturnsPageActions';
import { fetchCustomerServiceTermsPage } from '../../redux/@pages/customerServiceTermsPage/customerServiceTermsPageActions';
import { ReduxFetchAction } from '../../redux/defaults';
import { Block, BlockResource } from '../Block/Block';
import { DateResource } from '../Date/Date';
import { AppRoute } from '../Routing/Routing';
import { StrapiEntity, StrapiEntityData } from '../Strapi/Strapi';

export enum PageType {
    customerService = 'Contact',
    customerServiceAccount = '…account',
    customerServiceDelivery = '…delivery',
    customerServiceFaq = 'Veel gestelde vragen',
    customerServiceOrdering = '…orderings',
    customerServiceReturns = '…returns',
    customerServiceTerms = '…terms',
}

export const appRoutePageTypes: Partial<Record<AppRoute, PageType>> = {
    [AppRoute.customerService]: PageType.customerService,
    [AppRoute.customerServiceAccount]: PageType.customerServiceAccount,
    [AppRoute.customerServiceDelivery]: PageType.customerServiceDelivery,
    [AppRoute.customerServiceFaq]: PageType.customerServiceFaq,
    [AppRoute.customerServiceOrdering]: PageType.customerServiceOrdering,
    [AppRoute.customerServiceReturns]: PageType.customerServiceReturns,
    [AppRoute.customerServiceTerms]: PageType.customerServiceTerms,
};

export const pageTypeFetchActions: Partial<Record<PageType, ReduxFetchAction>> = {
    [PageType.customerService]: fetchCustomerServicePage,
    [PageType.customerServiceAccount]: fetchCustomerServiceAccountPage,
    [PageType.customerServiceDelivery]: fetchCustomerServiceDeliveryPage,
    [PageType.customerServiceFaq]: fetchCustomerServiceFaqPage,
    [PageType.customerServiceOrdering]: fetchCustomerServiceOrderingPage,
    [PageType.customerServiceReturns]: fetchCustomerServiceReturnsPage,
    [PageType.customerServiceTerms]: fetchCustomerServiceTermsPage,
};

export interface PageResource extends StrapiEntityData {
    title: string;
    description: string;
    elements: BlockResource[];
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type PageResponse = StrapiEntity<PageResource[]>;

export interface PageInterface {
    id: string;
    title: string;
    description: string;
    blocks: Block[];
}
