import { FC, ReactElement } from 'react';

import { ContactFormWidget, FormWrapper } from '../../../compositions';
import { FormInterface, FormType } from '../../../entities/@blocks/Form/Form';

interface ConnectedFormProps extends FormInterface {
    className?: string;
}

export const ConnectedForm: FC<ConnectedFormProps> = ({
    title,
    formType,
    className = '',
}): ReactElement => (
    <FormWrapper title={title} className={className}>
        {formType === FormType.contact && (
            <ContactFormWidget />
        )}

        {formType === FormType.kitchen && (
            <div>Kitchen form</div>
        )}
    </FormWrapper>
);
