import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { OrderItems } from '../../containers/AccountDashboardOrders/subcomponents';
import { Order } from '../../entities/Order/Order';
import { useTrans } from '../../hooks';
import { MOCK_PRODUCT_1, MOCK_PRODUCT_2, MOCK_PRODUCT_3 } from '../../mock/mock-data';

import './AccountOrdersPage.scss';

interface AccountDashboardOrdersProps {
    onlineOrders?: Order[];
    inStoreOrders?: Order[];
}

export const AccountOrdersPage: FC<AccountDashboardOrdersProps> = (): ReactElement => {
    const trans = useTrans();

    const mockOnlineOrders: Order[] = [
        {
            id: '1',
            orderNumber: '123456',
            orderDate: new Date('01-01-2021'),
            deliveryDate: new Date('01-01-2021'),
            products: [MOCK_PRODUCT_1],
            totalPrice: 123.45,
        },
        {
            id: '2',
            orderNumber: '123457',
            orderDate: new Date('01-01-2021'),
            deliveryDate: new Date('01-01-2021'),
            totalPrice: 24.95,
            products: [MOCK_PRODUCT_2],
        },
    ];

    const mockInStoreOrders: Order[] = [
        {
            id: '1',
            orderNumber: '123456',
            orderDate: new Date('01-01-2021'),
            deliveryDate: new Date('01-01-2021'),
            products: [MOCK_PRODUCT_3],
            totalPrice: 123.45,
        },
    ];

    return (
        <Page className="account-orders-page">
            <div className="account-orders-page__header">
                <h5 className="account-orders-page__heading">
                    {trans('pages.accountOrders.orders')}
                </h5>

                <p className="account-orders-page__description">
                    {trans('pages.accountOrders.description')}
                </p>
            </div>

            <h2 className="account-orders-page__orders-title">
                {trans('pages.accountOrders.yourLatestOrders')}
            </h2>

            <div className="account-orders-page__orders">
                <OrderItems orders={mockOnlineOrders} />

                <OrderItems orders={mockInStoreOrders} />
            </div>
        </Page>
    );
};
