import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './BlogCategoriesSkeletons.scss';

interface BlogCategoriesSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const BlogCategoriesSkeletons: FC<BlogCategoriesSkeletonsProps> = ({
    numberOfSkeletons = randomInBetweenValue(4, 6),
    className = '',
}): ReactElement => {
    const blogCategoriesSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`blog-categories-skeletons ${className}`}>
            {blogCategoriesSkeletons.map(skeleton => (
                <li key={skeleton} className="blog-categories-skeletons__list-item">
                    <Skeleton className="blog-categories-skeletons__category" />
                </li>
            ))}
        </ul>
    );
};
