import { FC, ReactElement } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { FormOption } from '../../entities/Form/Form';
import { useTrans } from '../../hooks';
import { SelectInput } from '..';

import './ProductsSorting.scss';

interface ProductsSortingProps {
    className?: string;
}

export const ProductsSorting: FC<ProductsSortingProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const selectedSort = searchParams.get('sort') || '';

    const formOptions: FormOption[] = [
        { label: trans('compositions.productsSorting.recommended'), value: 'option1' },
        { label: trans('compositions.productsSorting.new'), value: 'option2' },
        { label: trans('compositions.productsSorting.priceLowHigh'), value: 'option3' },
        { label: trans('compositions.productsSorting.priceHighLow'), value: 'option4' },
        { label: trans('compositions.productsSorting.highestDiscount'), value: 'option5' },
        { label: trans('compositions.productsSorting.bestRating'), value: 'option6' },
    ];

    const handleSortChange = (selectedOption: string) => {
        const params = new URLSearchParams(searchParams.toString());

        params.set('sort', selectedOption);
        navigate(`/?${params.toString()}`);
    };

    return (
        <div className={`products-sorting ${className}`}>
            <SelectInput
                name="productSortingSelect"
                label={trans('compositions.productsSorting.label')}
                value={formOptions.find((option) => option.value === selectedSort)}
                onChange={handleSortChange}
                options={formOptions}
                placeholder={trans('compositions.productsSorting.recommended')}
                inputLabelWrapperClassName="products-sorting__input-label-wrapper"
                labelClassName="products-sorting__input-label"
                className="products-sorting__select"
            />
        </div>
    );
};
