import { FC, ReactElement } from 'react';

import { Price } from '../../../../components';
import { useTrans } from '../../../../hooks';

import './OrderItemInfo.scss';

interface OrderItemInfoProps {
    className?: string;
    receipt: {
        orderNmr: number;
        orderDate: string;
        productQuantity: number;
        productPrice: number;
        totalOnInvoice: number;
        totalRefund: number;
        total: number;
    };
}

export const AccountDashboardOrderItemInfo: FC<OrderItemInfoProps> = ({
    receipt,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`order-info ${className}`}>
            <p className="order-info__title">
                {trans('pages.accountOrderDetail.order')}
            </p>

            <div className="order-info__label">
                <p>{trans('pages.accountOrderDetail.orderNr')}</p>
                <p>{receipt.orderNmr}</p>
            </div>

            <div className="order-info__label">
                <p>{trans('pages.accountOrderDetail.orderDate')}</p>
                <p>{receipt.orderDate}</p>
            </div>

            <div className="order-info__item">
                <p>
                    {trans('common.total')}
                    {' '}
                    {receipt.productQuantity}
                    {' '}
                    <span className="order-info__item-product">
                        {receipt.productQuantity === 1 ? trans('common.product') : trans('common.products')}
                    </span>
                </p>
                <Price includeCurrencySymbol amount={receipt.productPrice} />
            </div>

            <div className="order-info__item">
                <p>
                    {trans('common.total')}
                    {' '}
                    {trans('pages.accountOrderDetail.onInvoice')}
                </p>
                <Price includeCurrencySymbol amount={receipt.totalOnInvoice} />
            </div>

            <div className="order-info__item">
                <p>
                    {trans('common.total')}
                    {' '}
                    {trans('pages.accountOrderDetail.refund')}
                </p>
                <Price includeCurrencySymbol amount={receipt.totalRefund} />
            </div>

            <div className="order-info__total-price">
                <p>{trans('common.total')}</p>
                <Price includeCurrencySymbol amount={receipt.total} />
            </div>
        </div>
    );
};
