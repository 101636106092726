import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../compositions';
import { StoreListItem } from '../../../entities/Store/Store';
import { useTrans } from '../../../hooks';

import './StoreList.scss';

interface StoreListProps {
    storeListItems: StoreListItem[];
    className?: string;
}

export const StoreList: FC<StoreListProps> = ({
    storeListItems,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`store-list ${className}`}>
            {storeListItems.map(shop => (
                <div className="store-list__item">
                    <h3 className="store-list__title">
                        {shop.title}
                    </h3>

                    <p className="store-list__address">
                        {shop.address}
                    </p>

                    <div className="store-list__opening-hours">
                        <p>
                            {shop.isOpen ? (`${trans('common.nowOpen')}⋅`) : (`${trans('common.closed')}⋅`)}
                        </p>

                        <p>{shop.openingHours}</p>
                    </div>

                    <LinkButton
                        isSmall
                        to="/winkels/winkel-naam"
                        text={trans('common.moreInfo')}
                        className="store-list__link-button"
                    />
                </div>
            ))}
        </div>
    );
};
