import { HorizontalAlignment } from '../../Alignment/Alignment';
import { DefaultBlock, DefaultBlockResource } from '../../Block/Block';
import { Image, MediaResource } from '../../Media/Media';

export enum NewsletterCallToActionImageAlignmentResource {
    left = 'Links',
    right = 'Rechts',
}

export enum NewsletterCallToActionImageTypeResource {
    standalone = 'Foto',
    background = 'Achtergrond afbeelding',
    backgroundPattern = 'Achtergrond patroon',
}

export interface NewsletterCallToActionResource extends DefaultBlockResource {
    title: string;
    text?: string;
    textColor?: string;
    backgroundColor?: string;
    image?: MediaResource;
    imageAlignment?: NewsletterCallToActionImageAlignmentResource;
    imageType: NewsletterCallToActionImageTypeResource;
    shopLocator: boolean;
    shopLocatorBackgroundColor?: string;
}

export enum NewsletterCallToActionImageType {
    standalone = 'standalone',
    background = 'background',
    backgroundPattern = 'background-pattern',
}

export interface NewsletterCallToActionInterface extends DefaultBlock {
    title: string;
    description: string;
    textColor?: string;
    backgroundColor?: string;
    image?: Image;
    imageAlignment?: HorizontalAlignment;
    imageType?: NewsletterCallToActionImageType;
    showStoreLocator?: boolean;
    storeLocatorBackgroundColor?: string;
}
