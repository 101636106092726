import { FC, ReactElement } from 'react';

import { CustomerServiceOrderingPageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServiceOrderingPage } from '../../../redux/@pages/customerServiceOrderingPage/customerServiceOrderingPageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServiceOrderingPageRendererProps {
    className?: string;
}

export const ConnectedCustomerServiceOrderingPageRenderer: FC<ConnectedCustomerServiceOrderingPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.customerServiceOrderingPageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServiceOrderingPageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchCustomerServiceOrderingPage());
    }, []);

    return (
        <CustomerServiceOrderingPageRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
