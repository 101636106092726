import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import classNames from 'classnames';

import { Icon } from '../../../components';
import { generateIdArray } from '../../../helpers/array';
import { Button, ButtonProps } from '../Button/Button';

import './WishlistButton.scss';

interface wishlistButtonProps extends Omit<ButtonProps, 'onClick'> {
    isActive?: boolean;
    onClick: (isActive: boolean) => void;
}

export const WishlistButton: ForwardRefExoticComponent<wishlistButtonProps> = forwardRef(({
    isActive,
    text,
    onClick,
    className = '',
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const animationIcons = generateIdArray(3);

    const handleClick = (): void => {
        onClick(!isActive);
    };

    const wishlistButtonClassNames = classNames('wishlist-button', {
        'wishlist-button--is-active': isActive,
    }, className);

    return (
        <Button
            {...buttonProps}
            ref={ref}
            text={text}
            onClick={handleClick}
            className={wishlistButtonClassNames}
        >
            <div className="wishlist-button__icon-wrapper">
                <Icon
                    name="heart"
                    className="wishlist-button__icon wishlist-button__icon--main"
                />
                <Icon
                    name="heart"
                    className="wishlist-button__icon wishlist-button__icon--fill"
                    pathClassName="wishlist-button__icon-path"
                />

                <div className="wishlist-button__animation-wrapper">
                    {animationIcons.map(icon => (
                        <Icon
                            key={icon}
                            name="heart"
                            className="wishlist-button__icon wishlist-button__icon--animation"
                            pathClassName="wishlist-button__icon-path"
                        />
                    ))}
                </div>
            </div>
        </Button>
    );
});
