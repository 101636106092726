/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconArrowShortRight: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polyline className="icon__stroke" points="39.9 80.4 69.6 50 39.9 19.6" />
        <line className="icon__stroke" x1="26" y1="50" x2="69.6" y2="50" />
    </svg>
));
