import { LoginFormData } from '../../entities/@forms/LoginForm/LoginForm';
import {
    isUnauthorizedResponse,
    tokenKey,
    TokenResource,
    transformToToken,
    UnauthorizedTokenResource,
} from '../../entities/Api/Webshop';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { syliusFetch } from '../../entities/Sylius/SyliusService';
import { TypedDispatch } from '../store';
import { setError, setIsAuthenticated, setIsLoading } from './authenticationSlice';

export const authenticateUser = (formData: LoginFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsAuthenticated(false));

    try {
        const requestBody = {
            email: formData.username,
            password: formData.password,
        };

        const tokenResponse = await syliusFetch<TokenResource | UnauthorizedTokenResource>('/shop/customers/token', {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });

        if (!isFetchResultSuccessful(tokenResponse)) {
            dispatch(setError(tokenResponse.error));
            return;
        }

        if (isUnauthorizedResponse(tokenResponse.data)) {
            dispatch(setError(tokenResponse.data.message));
            return;
        }

        const token = transformToToken(tokenResponse.data);

        // Store Sylius JWT token based on preference
        if (formData.rememberMe) {
            localStorage.setItem(tokenKey, JSON.stringify(token));
        } else {
            sessionStorage.setItem(tokenKey, JSON.stringify(token));
        }

        dispatch(setIsAuthenticated(true));
    } catch (error) {
        console.error('[authenticateUser]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
