import { ProductHitResource } from '../Api/Webshop/Resource/ProductHit';
import { transformToBrand } from '../Brand/BrandTransformers';
import { transformInstantSearchImagesToMediaItems, transformInstantSearchImageToImage } from '../Media/MediaTransformers';
import { SearchPopupProductItem } from '../SearchPopup/SearchPopup';
import { Product } from './Product';

// TODO: Check which data is really necessary to search and filter products (L5W-349)
export const transformProductHitToProduct = (resource: ProductHitResource): Product => {
    const brand = resource.brand
        ? transformToBrand(resource.brand)
        : undefined;

    const mainImage = resource.image !== null
        ? transformInstantSearchImageToImage(resource)
        : undefined;

    const mediaItems = resource.images.length
        ? transformInstantSearchImagesToMediaItems(resource)
        : [];

    return ({
        id: resource.id.toString(),
        slug: resource.article_slug,
        name: resource.title ?? undefined,
        description: resource.description ?? undefined,
        brand,
        mainImage,
        mediaItems,
        price: resource.price_in_cents.LOODS_5 / 100,
        isOnlineOnly: resource.is_only_online,
        isNew: resource.is_new,
        isSale: resource.is_sale,
        colors: [resource.colors.color],
        variants: resource.variants || 0,
        stock: resource.stock || 0,
        categories: resource.categories,
    });
};

export const transformProductHitToSearchPopupProductItem = (resource: ProductHitResource): SearchPopupProductItem => ({
    id: resource.id,
    name: resource.title ?? undefined,
    slug: resource.slug,
    brand: resource.brand,
    price: resource.price_in_cents.LOODS_5 / 100,
});
