import { FC, ReactElement } from 'react';

import { ForgotPasswordForm } from '../../../containers';
import { ForgotPasswordFormData } from '../../../entities/@forms/ForgotPasswordForm/ForgotPasswordForm';
import { forgotPassword } from '../../../redux/resetPassword/resetPasswordActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedForgotPasswordFormProps {
    className?: string;
}

export const ConnectedForgotPasswordForm: FC<ConnectedForgotPasswordFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.resetPasswordSlice.isLoading);
    const error = useTypedSelector(state => state.resetPasswordSlice.error);

    const handleSubmit = (formData: ForgotPasswordFormData): void => {
        dispatch(forgotPassword(formData));
    };

    return (
        <ForgotPasswordForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
