export enum FetchResultType {
    Abort = 'abort',
    Error = 'error',
    Success = 'success',
}

export interface SuccessfulFetchResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
    totalPages?: number;
}

export interface UnsuccessfulFetchResult<E> {
    status?: number;
    type: FetchResultType.Error | FetchResultType.Abort;
    error: E;
}

export interface FetchResultErrorViolation {
    propertyPath: string;
    message: string;
    code: string | number | null;
}

export interface FetchResultError {
    title: string;
    detail: string;
    type: string;
    violations: FetchResultErrorViolation[];
}

export type FetchResult<T, E = T> = SuccessfulFetchResult<T> | UnsuccessfulFetchResult<E>;

export const isFetchResultSuccessful = <T, E>(result: FetchResult<T, E>): result is SuccessfulFetchResult<T> => (
    result.type === FetchResultType.Success
);
