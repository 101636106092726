import { FC, ReactElement } from 'react';

import { CustomerServiceAccountPageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServiceAccountPage } from '../../../redux/@pages/customerServiceAccountPage/customerServiceAccountPageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServiceAccountPageRendererProps {
    className?: string;
}

export const ConnectedCustomerServiceAccountPageRenderer: FC<ConnectedCustomerServiceAccountPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.customerServiceAccountPageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServiceAccountPageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchCustomerServiceAccountPage());
    }, []);

    return (
        <CustomerServiceAccountPageRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
