/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconLoodsLocation: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,7.4c-17.4,0-31.5,14.1-31.5,31.5s31.5,53.9,31.5,53.9c0,0,31.5-36.5,31.5-53.9s-14.1-31.5-31.5-31.5ZM69.2,46.8c0,2.5-.3,4.5-1,5.9-.7,1.4-1.6,2.5-2.9,3.2-1.3.7-2.9,1.2-4.9,1.4-2,.2-4.3.3-6.9.3h-7.9c-2.5,0-4.6,0-6.5-.3-1.9-.2-3.4-.6-4.6-1.2-1.2-.6-2.1-1.5-2.7-2.6-.6-1.1-.9-2.7-.9-4.6v-1.9h9.2c0,.7,0,1.3.2,1.7s.4.8.8,1.1c.4.3.9.5,1.7.6.7.1,1.6.2,2.8.2h9.4c1.1,0,2.1,0,2.7-.2.7-.2,1.2-.4,1.5-.8.3-.4.6-.8.7-1.4.1-.6.2-1.2.2-2s0-1.7-.3-2.2c-.2-.5-.5-1-.9-1.2-.4-.3-1-.5-1.8-.5-.8,0-1.7-.1-2.9-.1h-10.6c-.8,0-1.4.2-1.9.5-.5.3-.8.9-1,1.7h-8.7v-19.7h35.7v7.1h-27v5.7c.3-.3.6-.6,1-.8.4-.3.8-.5,1.2-.7.5-.2,1-.3,1.7-.4.6,0,1.4-.1,2.3-.1h11.4c1.7,0,3.3.2,4.7.5,1.4.3,2.5.8,3.4,1.6.9.7,1.7,1.7,2.2,2.9.5,1.2.7,2.7.7,4.5v2.1Z" />
    </svg>
));
