import {
    CSSProperties,
    FC,
    KeyboardEvent,
    PointerEvent,
    ReactElement,
} from 'react';

import { useTrans } from '../../hooks';

import './Progress.scss';

interface ProgressProps {
    total: number;
    activeIndex: number;
    onChange: (value: number) => void;
    className?: string;
}

export const Progress: FC<ProgressProps> = ({
    total,
    activeIndex,
    onChange,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const handleClick = (event: PointerEvent<HTMLDivElement>): void => {
        const bar = event.currentTarget;

        const clickedPos = event.clientX - bar.getBoundingClientRect().left;
        const clickedPercentage = clickedPos / bar.offsetWidth;
        const clickedIndex = Math.floor(total * clickedPercentage);

        onChange(clickedIndex);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
        const { key } = event;

        if (key === 'ArrowLeft' || key === 'ArrowDown') {
            event.preventDefault();
            onChange(Math.max(0, activeIndex - 1));
        }

        if (key === 'ArrowRight' || key === 'ArrowUp') {
            event.preventDefault();
            onChange(Math.min(activeIndex + 1, total - 1));
        }
    };

    const label = trans('compositions.progress.label', {
        current: activeIndex + 1,
        total,
    });

    const cssVariables = {
        '--progress-percentage': `${((activeIndex + 1) / total) * 100}%`,
    } as CSSProperties;

    return (
        <div
            role="slider"
            aria-label={label}
            aria-valuemin={0}
            aria-valuemax={total}
            aria-valuenow={activeIndex + 1}
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            style={cssVariables}
            className={`progress ${className}`}
        >
            <div className="progress__line" />
            <div className="progress__bar" />
        </div>
    );
};
