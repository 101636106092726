import { FC, ReactElement, useState } from 'react';

import { useLocalStorage } from 'react-use';

import { SearchPopup } from '../../containers';
import { Link, LinkTarget } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { SearchPopupProductItem } from '../../entities/SearchPopup/SearchPopup';
import { StoreWithWorkingHours } from '../../entities/Store/Store';
import { useTrans } from '../../hooks';
import { MOCK_STORES_WITH_OPENING_HOURS } from '../../mock/mock-data';

export const ConnectedSearchPopup: FC = (): ReactElement => {
    const trans = useTrans();

    const mockStoresWithOpeningHours: StoreWithWorkingHours[] = MOCK_STORES_WITH_OPENING_HOURS;

    const pageSuggestions: Link[] = [
        {
            href: trans(appRoutes[AppRoute.storeOverview].path),
            label: trans(appRoutes[AppRoute.storeOverview].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerService].path),
            label: trans(appRoutes[AppRoute.customerService].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerServiceFaq].path),
            label: trans(appRoutes[AppRoute.customerServiceFaq].label),
            target: LinkTarget.blank,
        },
    ];

    // TODO: Search on real pages via MeiliSearch API (L5W-151)
    const mockPages: Link[] = [
        {
            href: trans(appRoutes[AppRoute.storeOverview].path),
            label: trans(appRoutes[AppRoute.storeOverview].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerService].path),
            label: trans(appRoutes[AppRoute.customerService].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerServiceFaq].path),
            label: trans(appRoutes[AppRoute.customerServiceFaq].label),
            target: LinkTarget.blank,
        },
    ];

    const [recentSearches, storeRecentSearches] = useLocalStorage<string[]>('recentSearches', []);

    const [products, setProducts] = useState<SearchPopupProductItem[]>([]);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const storeTermInRecentSearches = (term: string): void => {
        if (term.length < 1) {
            return;
        }

        const otherSearches = recentSearches?.filter(item => item !== term) || [];
        const cappedSearches = [term, ...otherSearches].slice(0, 5);

        storeRecentSearches(cappedSearches);
    };

    return (
        <SearchPopup
            searchTerm={searchTerm}
            products={products}
            pages={mockPages}
            locations={mockStoresWithOpeningHours}
            pageSuggestions={pageSuggestions}
            recentSearches={recentSearches}
            onSetStoreTermInRecentSearches={storeTermInRecentSearches}
            onSetProducts={setProducts}
            onSetSearchTerm={setSearchTerm}
        />
    );
};
