import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Picture, Price } from '../../../../components';
import { IconButton, NumberInput, WishlistButton } from '../../../../compositions';
import { CartItem } from '../../../../entities/Cart/Cart';
import { useTrans } from '../../../../hooks';

import './CartProduct.scss';

interface CartItemProps extends CartItem {
    onRemoveItemFromCart: (productId: string) => void;
    onUpdateCartItemQuantity: (productId: string, quantity: number) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    className?: string;
}

export const CartProduct: FC<CartItemProps> = ({
    product,
    quantity,
    onRemoveItemFromCart,
    onUpdateCartItemQuantity,
    onUpdateCartItemIsInWishlist,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    // TODO: implement favorite functionality
    const [isInWishlist, toggleWishlistStatus] = useToggle(false);

    const handleRemoveFromCart = (): void => {
        onRemoveItemFromCart(product.id);
    };

    const handleUpdateQuantity = (value: number): void => {
        onUpdateCartItemQuantity(product.id, value);
    };

    const handleUpdateWishlistStatus = (): void => {
        toggleWishlistStatus(!isInWishlist);
        onUpdateCartItemIsInWishlist(product.id, isInWishlist);
    };

    const totalPrice = product.price * quantity;

    return (
        <div key={product.id} className={`cart-product ${className}`}>
            <div className="cart-product__header">
                {product.mainImage && (
                    <Picture
                        src={product.mainImage.src}
                        alt={product.mainImage.alt}
                        className="cart-product__image"
                    />
                )}

                <Link to={`/product/${product.slug}`} className="cart-product__name">
                    {product.name}
                </Link>
            </div>

            <div className="cart-product__content-wrapper">
                <div className="cart-product__content">
                    <NumberInput
                        hideLabel
                        label={trans('containers.cartProduct.changeQuantity')}
                        value={quantity}
                        max={100}
                        onChange={handleUpdateQuantity}
                        className="cart-product__quantity-input"
                        buttonClassName="cart-product__quantity-button"
                    />

                    <WishlistButton
                        isActive={isInWishlist}
                        text={trans('common.addToWishlist')}
                        onClick={handleUpdateWishlistStatus}
                        className="cart-product__button cart-product__wishlist-button"
                    />

                    <IconButton
                        hideLabel
                        text={trans('common.delete')}
                        icon="cross"
                        onClick={handleRemoveFromCart}
                        className="cart-product__button"
                    />
                </div>

                <Price
                    includeCurrencySymbol
                    amount={totalPrice}
                    className="cart-product__price"
                />
            </div>
        </div>
    );
};
