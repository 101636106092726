import { HorizontalAlignment } from '../../Alignment/Alignment';
import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import {
    NewsletterCallToActionImageAlignmentResource,
    NewsletterCallToActionImageType,
    NewsletterCallToActionImageTypeResource,
    NewsletterCallToActionInterface,
    NewsletterCallToActionResource,
} from './NewsletterCallToAction';

export const transformToNewsletterCallToActionImageAlignment = (resource?: NewsletterCallToActionImageAlignmentResource): HorizontalAlignment => {
    if (resource === NewsletterCallToActionImageAlignmentResource.right) {
        return HorizontalAlignment.right;
    }

    return HorizontalAlignment.left;
};

export const transformToNewsletterCallToActionImageType = (resource?: NewsletterCallToActionImageTypeResource): NewsletterCallToActionImageType => {
    if (resource === NewsletterCallToActionImageTypeResource.background) {
        return NewsletterCallToActionImageType.background;
    }

    if (resource === NewsletterCallToActionImageTypeResource.backgroundPattern) {
        return NewsletterCallToActionImageType.backgroundPattern;
    }

    return NewsletterCallToActionImageType.standalone;
};

export const transformToNewsletterCallToAction = (resource: NewsletterCallToActionResource): NewsletterCallToActionInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const image = resource.image
        ? transformToImage(resource.image)
        : undefined;

    const imageAlignment = transformToNewsletterCallToActionImageAlignment(resource.imageAlignment);

    const imageType = transformToNewsletterCallToActionImageType(resource.imageType);

    return {
        ...defaultBlock,
        type: BlockType.newsletterCallToAction,
        title: resource.title,
        description: resource.text || '',
        backgroundColor: resource.backgroundColor,
        textColor: resource.textColor,
        image,
        imageAlignment,
        imageType,
        showStoreLocator: resource.shopLocator,
        storeLocatorBackgroundColor: resource.shopLocatorBackgroundColor,
    };
};
