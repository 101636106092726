import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServiceAccountPageRenderer } from '../../../connectors';

import './CustomerServiceAccountPage.scss';

export const CustomerServiceAccountPage: FC = (): ReactElement => (
    <Page className="customer-service-account-page">
        <ConnectedCustomerServiceAccountPageRenderer />
    </Page>
);
