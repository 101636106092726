import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ProductCard, SectionFooter } from '../../../../../compositions';
import { FeaturedProductsInterface } from '../../../../../entities/@blocks/FeaturedProducts/FeaturedProducts';

import './FeaturedProductsAlternatingSizes.scss';

export interface FeaturedProductsAlternatingSizesProps extends Omit<FeaturedProductsInterface, 'layoutType'> {
    className?: string;
}

export const FeaturedProductsAlternatingSizes: FC<FeaturedProductsAlternatingSizesProps> = ({
    title,
    products,
    link,
    className = '',
}): ReactElement => {
    const [mainProduct, ...restProducts] = products;

    return (
        <div className={`featured-products-alternating-sizes ${className}`}>
            <header className="featured-products-alternating-sizes__header">
                <h2 className="featured-products-alternating-sizes__title">
                    {title}
                </h2>
            </header>

            <div className="featured-products-alternating-sizes__products-wrapper">
                <div className="featured-products-alternating-sizes__main-wrapper">
                    <ProductCard
                        product={mainProduct}
                        className="featured-products-alternating-sizes__product"
                    />
                </div>

                <div className="featured-products-alternating-sizes__rest-wrapper">
                    <ul className="featured-products-alternating-sizes__rest-list">
                        {restProducts.map((product, index) => {
                            const restItemClassNames = classNames('featured-products-alternating-sizes__rest-item', {
                                'featured-products-alternating-sizes__rest-item--is-wide': index === 2,
                            });

                            return (
                                <li key={product.id} className={restItemClassNames}>
                                    <ProductCard
                                        product={product}
                                        className="featured-products-alternating-sizes__product"
                                        pictureClassname="featured-products-alternating-sizes__picture"
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            {link && (
                <SectionFooter
                    link={link}
                    className="featured-products-alternating-sizes__footer"
                />
            )}
        </div>
    );
};
