import { FC, ReactElement } from 'react';

import { IconName } from '../../components';
import { useTrans } from '../../hooks';
import { IconButton } from '../@buttons/IconButton/IconButton';

import './ShoppingCartCount.scss';

interface ShoppingCartCountProps {
    count: number;
    onClick: () => void;
    className?: string;
}

export const ShoppingCartCount: FC<ShoppingCartCountProps> = ({
    count,
    onClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const getCartIcon = (): IconName => {
        if (count <= 4) {
            return 'cart-bag';
        }

        if (count <= 9) {
            return 'cart-wagon';
        }

        return 'cart-truck';
    };

    return (
        <div className={`shopping-cart-count ${className}`}>
            <IconButton
                hideLabel
                icon={getCartIcon()}
                text={trans('common.openCart')}
                onClick={onClick}
                className="shopping-cart-count__icon-button"
                iconClassName="shopping-cart-count__icon-accent"
            />

            {count > 0 && (
                <div className="shopping-cart-count__amount">
                    {count}
                </div>
            )}
        </div>
    );
};
