import { generateUuid } from '../../helpers/string';
import { AtLeast } from '../../types';
import { AnchorPosition } from '../Alignment/Alignment';

export enum ToastState {
    positive = 'positive',
    warning = 'warning',
    negative = 'negative',
}

export interface ToastInterface {
    id: string;
    revealDuration?: number;
    transitionDuration?: number;
    position?: AnchorPosition;
    state?: ToastState;
    title: string;
    description?: string;
}

export const defaultToastRevealDuration = 5000;

export const defaultToastProperties = (): AtLeast<ToastInterface, 'id'> => ({
    id: generateUuid(),
    revealDuration: defaultToastRevealDuration,
    position: AnchorPosition.blockEndInlineEnd,
});
