import { FC, ReactElement } from 'react';

import { CustomerServiceDeliveryPageRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchCustomerServiceDeliveryPage } from '../../../redux/@pages/customerServiceDeliveryPage/customerServiceDeliveryPageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomerServiceDeliveryPageRendererProps {
    className?: string;
}

export const ConnectedCustomerServiceDeliveryPageRenderer: FC<ConnectedCustomerServiceDeliveryPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.customerServiceDeliveryPageSlice.isLoading);
    const blocks = useTypedSelector(state => state.customerServiceDeliveryPageSlice.blocks);

    useClientEffect((): void => {
        dispatch(fetchCustomerServiceDeliveryPage());
    }, []);

    return (
        <CustomerServiceDeliveryPageRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
