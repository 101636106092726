import { defaultToastProperties, ToastInterface, ToastState } from '../../entities/Toast/Toast';
import { AtLeast } from '../../types';
import { SliceGetter, TypedDispatch } from '../store';
import { setToastList } from './toastSlice';

export const addToast = (toast: ToastInterface) => (dispatch: TypedDispatch, getState: SliceGetter): void => {
    const { toastSlice } = getState();
    const currentToastList = toastSlice.toastList;

    const newToastList = [...currentToastList, toast];

    dispatch(setToastList(newToastList));
};

export const addPositiveToast = (toast: AtLeast<ToastInterface, 'title'>) => (dispatch: TypedDispatch): void => {
    dispatch(addToast({
        ...defaultToastProperties(),
        ...toast,
        state: ToastState.positive,
    }));
};

export const addNegativeToast = (toast: AtLeast<ToastInterface, 'title'>) => (dispatch: TypedDispatch): void => {
    dispatch(addToast({
        ...defaultToastProperties(),
        ...toast,
        state: ToastState.negative,
    }));
};

export const addWarningToast = (toast: AtLeast<ToastInterface, 'title'>) => (dispatch: TypedDispatch): void => {
    dispatch(addToast({
        ...defaultToastProperties(),
        ...toast,
        state: ToastState.warning,
    }));
};

export const removeToast = (toastId: string) => (dispatch: TypedDispatch, getState: SliceGetter): void => {
    const { toastSlice } = getState();
    const currentToastList = toastSlice.toastList;

    const newToastList = currentToastList.filter(toast => toast.id !== toastId);

    dispatch(setToastList(newToastList));
};
