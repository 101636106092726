import { FC, ReactElement } from 'react';

import { BrandHeader } from '../../compositions/BrandHeader/BrandHeader';
import {
    ConnectedBlogOverview,
    ConnectedForm,
    ConnectedNewsletterCallToAction,
    ConnectedServiceSection,
} from '../../connectors';
import { BlogOverviewBlockInterface } from '../../entities/@blocks/BlogOverview/BlogOverview';
import { BrandCarouselInterface } from '../../entities/@blocks/BrandCarousel/BrandCarousel';
import { BrandHeaderInterface } from '../../entities/@blocks/BrandHeader/BrandHeader';
import { CarouselInterface } from '../../entities/@blocks/Carousel/Carousel';
import { ContentColumnsInterface } from '../../entities/@blocks/ContentColumns/ContentColumns';
import { FaqDataInterface } from '../../entities/@blocks/Faq/Faq';
import { FeaturedProductsInterface } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { FormInterface } from '../../entities/@blocks/Form/Form';
import { HeaderInterface } from '../../entities/@blocks/Header/Header';
import { HighlightedBlogPostInterface } from '../../entities/@blocks/HighlightedBlogPost/HighlightedBlogPost';
import { IconColumnsInterface } from '../../entities/@blocks/IconColumns/IconColumns';
import { MediaGridInterface } from '../../entities/@blocks/MediaGrid/MediaGrid';
import { NewsletterCallToActionInterface } from '../../entities/@blocks/NewsletterCallToAction/NewsletterCallToAction';
import { ServiceSectionInterface } from '../../entities/@blocks/ServiceSection/ServiceSection';
import { UspSectionInterface } from '../../entities/@blocks/UspSection/UspSection';
import { Block, BlockType } from '../../entities/Block/Block';
import { Link } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import {
    BrandCarousel,
    Carousel,
    ContentColumns,
    Faq,
    FeaturedProducts,
    Header,
    HighlightedBlogPost,
    IconColumns,
    MediaGrid,
    UspSection,
} from '..';
import { BlockRendererSkeletons } from './skeletons';

import './BlockRenderer.scss';

interface BlockRendererProps {
    isLoading?: boolean;
    pageType?: AppRoute;
    blocks?: Block[];
    className?: string;
}

export const BlockRenderer: FC<BlockRendererProps> = ({
    isLoading,
    pageType,
    blocks = [],
    className = '',
}): ReactElement => {
    const trans = useTrans();

    if (isLoading) {
        return (
            <BlockRendererSkeletons className={className} />
        );
    }

    return (
        <div className={`block-renderer ${className}`}>
            {blocks.map(block => {
                if (block.type === BlockType.blogOverview) {
                    return (
                        <ConnectedBlogOverview
                            {...block as BlogOverviewBlockInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.brandCarousel) {
                    return (
                        <BrandCarousel
                            {...block as BrandCarouselInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.brandHeader) {
                    return (
                        <BrandHeader
                            {...block as BrandHeaderInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.carousel) {
                    return (
                        <Carousel
                            {...block as CarouselInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.contentColumns) {
                    return (
                        <ContentColumns
                            {...block as ContentColumnsInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.faqs) {
                    return (
                        <Faq
                            {...block as FaqDataInterface}
                            key={block.id}
                            hasFollowUpLink
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.featuredProducts) {
                    return (
                        <FeaturedProducts
                            {...block as FeaturedProductsInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.form) {
                    return (
                        <ConnectedForm
                            {...block as FormInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.header) {
                    let backLink: Link | undefined;

                    if (pageType === AppRoute.blogPost) {
                        backLink = {
                            href: trans(appRoutes[AppRoute.inspiration].path),
                            label: trans('containers.blockRenderer.backLink.blogPost'),
                        };
                    }

                    return (
                        <Header
                            {...block as HeaderInterface}
                            key={block.id}
                            backLink={backLink}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.highlightedBlogPost) {
                    return (
                        <HighlightedBlogPost
                            {...block as HighlightedBlogPostInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.iconColumns) {
                    return (
                        <IconColumns
                            {...block as IconColumnsInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.mediaGrid) {
                    return (
                        <MediaGrid
                            {...block as MediaGridInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.newsletterCallToAction) {
                    return (
                        <ConnectedNewsletterCallToAction
                            {...block as NewsletterCallToActionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.serviceSection) {
                    return (
                        <ConnectedServiceSection
                            {...block as ServiceSectionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.uspSection) {
                    return (
                        <UspSection
                            {...block as UspSectionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                return null;
            })}
        </div>
    );
};
