import { FC, ReactElement } from 'react';

import { Icon } from '../../../components';
import { Direction } from '../../../entities/Navigation/Navigation';
import { WayfindingType } from '../../../entities/Wayfinding/Wayfinding';
import { Button, ButtonProps, WayfindingWrapper } from '../..';

import './WayfindingDirectionButton.scss';

interface WayfindingDirectionButtonProps extends ButtonProps {
    id: string;
    direction: Direction;
    className?: string;
}

export const WayfindingDirectionButton: FC<WayfindingDirectionButtonProps> = ({
    id,
    text,
    direction,
    disabled,
    className = '',
    ...buttonProps
}): ReactElement => {
    const type = direction === Direction.prev
        ? WayfindingType.left
        : WayfindingType.right;

    const icon = direction === Direction.prev
        ? 'wayfinding-left'
        : 'wayfinding-right';

    return (
        <WayfindingWrapper
            isDisabled={disabled}
            id={`${id}-${direction}`}
            type={type}
            className={`wayfinding-direction-button ${className}`}
            iconPathClassName="wayfinding-direction-button__icon-path"
        >
            <Button
                {...buttonProps}
                text={text}
                disabled={disabled}
                className="wayfinding-direction-button__button"
                labelClassName="wayfinding-direction-button__button-label"
            >
                <Icon
                    name={icon}
                    className="wayfinding-direction-button__icon"
                    pathClassName="wayfinding-direction-button__icon-path"
                />
            </Button>
        </WayfindingWrapper>
    );
};
