import { FC, ReactElement } from 'react';

import { ConnectedChangePasswordForm } from '../../connectors';

import './AccountChangePasswordPage.scss';

export const AccountChangePasswordPage: FC = (): ReactElement => (
    <div className="account-change-password-page">
        <ConnectedChangePasswordForm className="account-change-password-page__change-password-form" />
    </div>
);
