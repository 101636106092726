import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServiceTermsPageRenderer } from '../../../connectors';

import './CustomerServiceTermsPage.scss';

export const CustomerServiceTermsPage: FC = (): ReactElement => (
    <Page className="customer-service-terms-page">
        <ConnectedCustomerServiceTermsPageRenderer />
    </Page>
);
