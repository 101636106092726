import { RegistrationFormData } from '../../entities/@forms/RegistrationForm/RegistrationForm';
import { TokenResource } from '../../entities/Api/Webshop';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { syliusFetch } from '../../entities/Sylius/SyliusService';
import trans from '../../helpers/trans';
import { TypedDispatch } from '../store';
import { addPositiveToast } from '../toast/toastActions';
import { setError, setIsLoading, setIsRegistered } from './registrationSlice';

export const registerUser = (formData: RegistrationFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsRegistered(false));

    try {
        const registerResponse = await syliusFetch<TokenResource>('/shop/customers', {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(registerResponse)) {
            dispatch(setError(registerResponse.error));
            return;
        }

        dispatch(setIsRegistered(true));

        dispatch(addPositiveToast({
            title: trans('redux.registration.successMessageTitle'),
            description: trans('redux.registration.successMessageDescription'),
        }));
    } catch (error) {
        console.error('[registerUser]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
