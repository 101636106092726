import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { AutoplayMediaOption } from '../../Media/Media';
import { transformToImage, transformToVideo } from '../../Media/MediaTransformers';
import { HeaderInterface, HeaderResource } from './Header';

export const transformToHeader = (resource: HeaderResource): HeaderInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const media = resource.autoplayMedia?.media;

    const image = media && media?.mime.startsWith('image')
        ? transformToImage(media)
        : undefined;

    const video = media && media?.mime.startsWith('video')
        ? transformToVideo(media)
        : undefined;

    const videoPlaysInline = !!video && resource.autoplayMedia?.playOption === AutoplayMediaOption.autoPlay;

    return {
        ...defaultBlock,
        type: BlockType.header,
        accentColour: resource.color,
        title: resource.title || '',
        subTitle: resource.subtitle || '',
        introHtml: resource.paragraph || '',
        image,
        video,
        videoPlaysInline,
    };
};
