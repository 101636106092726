import { CSSProperties, FC, ReactElement } from 'react';

import { Picture, Wrapper } from '../../../components';
import { LinkButton, ProductCard, SectionFooter } from '../../../compositions';
import { ProductCollectionInterface } from '../../../entities/ProductCollection/ProductCollection';

import './ProductCollection.scss';

export interface ProductCollectionProps extends ProductCollectionInterface {
    className?: string;
}

export const ProductCollection: FC<ProductCollectionProps> = ({
    title,
    titleColor,
    productCollection,
    products,
    footerLink,
    className = '',
}): ReactElement => {
    const cssVariables = {
        '--c-product-collection-title': titleColor,
    } as CSSProperties;

    return (
        <section style={cssVariables} className={`product-collection-section ${className}`}>
            <Wrapper>
                <header className="product-collection-section__header">
                    <h2 className="product-collection-section__header-title">
                        {title}
                    </h2>

                    <div className="product-collection-section__header-divider" />
                </header>

                <div className="product-collection-section__items">
                    {productCollection.map(item => {
                        const buttonsCssVariables = {
                            '--c-production-collection-button-text': item.textColor,
                            '--c-production-collection-button-background': item.backgroundColor,
                        } as CSSProperties;

                        return (
                            <div
                                key={item.id}
                                style={buttonsCssVariables}
                                className="product-collection-section__item"
                            >
                                <Picture
                                    {...item.image}
                                    className="product-collection-section__item-picture"
                                    imageClassName="product-collection-section__item-image"
                                />

                                <div style={buttonsCssVariables}>
                                    <LinkButton
                                        to={item.link.href}
                                        text={item.link.label}
                                        className="product-collection-section__item-link"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="product-collection-section__product-list">
                    {products.map(product => (
                        <ProductCard
                            key={product.id}
                            product={product}
                            className="product-collection-section__product-item"
                            pictureClassname="product-collection-section__product-item-picture"
                        />
                    ))}
                </div>

                {footerLink && (
                    <SectionFooter
                        link={footerLink}
                        className="product-collection-section__footer"
                    />
                )}
            </Wrapper>
        </section>
    );
};
