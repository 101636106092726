import { transformToImage } from '../Media/MediaTransformers';
import { Seo, SeoResource } from './Seo';

export const transformToSeo = (resource: SeoResource): Seo => {
    const image = resource.metaImage
        ? transformToImage(resource.metaImage)
        : undefined;

    const structuredData = resource.structuredData
        ? JSON.stringify(resource.structuredData)
        : undefined;

    return {
        title: resource.metaTitle,
        description: resource.metaDescription,
        image,
        keywords: resource.keywords || '',
        canonicalURL: resource.canonicalURL || '',
        structuredData,
    };
};
