import { FC, ReactElement } from 'react';

import { ServiceSection } from '../../../containers';
import { ServiceSectionInterface } from '../../../entities/@blocks/ServiceSection/ServiceSection';
import { MOCK_SERVICE_CONTACT_DETAILS } from '../../../mock/mock-data/contact';

export interface ConnectedServiceSectionProps extends ServiceSectionInterface {
    className?: string;
}

export const ConnectedServiceSection: FC<ConnectedServiceSectionProps> = ({
    className = '',
    ...serviceSectionProps
}): ReactElement => {
    // TODO: L5W-109: Retrieve this data from API
    const serviceContactDetails = MOCK_SERVICE_CONTACT_DETAILS;

    return (
        <ServiceSection
            {...serviceSectionProps}
            serviceContactDetails={serviceContactDetails}
            className={className}
        />
    );
};
