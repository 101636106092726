import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { PageResponse, PageType } from '../../../entities/Page/Page';
import { generatePageQuery } from '../../../entities/Page/PageRequests';
import { transformToPage } from '../../../entities/Page/PageTransformers';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlocks,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './customerServiceOrderingPageSlice';

export const fetchCustomerServiceOrderingPage: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generatePageQuery(PageType.customerServiceOrdering);

        const customerServiceOrderingPageResponse = await getStrapiApiCall<PageResponse>(`/pages?${query}`);

        if (!isFetchResultSuccessful(customerServiceOrderingPageResponse)) {
            dispatch(setError(customerServiceOrderingPageResponse.error));
            return;
        }

        const { data: customerServiceOrderingPageData } = customerServiceOrderingPageResponse.data;

        const customerServiceOrderingPageResource = customerServiceOrderingPageData
            ? customerServiceOrderingPageData[0]
            : undefined;

        const customerServiceOrderingPage = customerServiceOrderingPageResource
            ? transformToPage(customerServiceOrderingPageResource)
            : undefined;

        const blocks = customerServiceOrderingPage?.blocks || [];

        dispatch(setBlocks(blocks));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomerServiceOrderingPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
