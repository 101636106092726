import { FC, ReactElement } from 'react';

import { useTrans } from '../../../hooks';

import './ContactFormWidget.scss';

export interface ContactFormWidgetProps {
    className?: string;
}

export const ContactFormWidget: FC<ContactFormWidgetProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const widgetScript = 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js';
    const embedUrl = 'https://loods5help.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&widgetView=no';

    return (
        <div className={`contact-form-widget ${className}`}>
            <script type="text/javascript" src={widgetScript} />

            <iframe
                id="freshwidget-embedded-form"
                title={trans('compositions.contactFormWidget.title')}
                src={embedUrl}
                width="100%"
                height="673px"
                className="contact-form-widget__iframe"
            />
        </div>
    );
};
