import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { Wrapper } from '../../../components';
import { IconButton } from '../../../compositions';
import { UrgentBannerInterface } from '../../../entities/@blocks/UrgentBanner/UrgentBanner';
import { useElementSize, useTrans } from '../../../hooks';

import './UrgentBanner.scss';

interface UrgentBannerProps extends UrgentBannerInterface {
    className?: string;
}

export const UrgentBanner: FC<UrgentBannerProps> = ({
    id,
    text,
    link,
    backgroundColor,
    textColor,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const contentRef = useRef<HTMLDivElement>(null);
    const { height: bannerHeight } = useElementSize(contentRef);

    const [hasClosed, storeHasClosed] = useLocalStorage(`urgent-banner-${id}-is-closed`, false);
    const [shouldReveal, setShouldReveal] = useState<boolean>(false);

    useEffect((): void => {
        setShouldReveal(!hasClosed);
    }, []);

    const handleClose = (): void => {
        storeHasClosed(true);
    };

    const urgentBannerClassNames = classNames('urgent-banner', {
        'urgent-banner--is-revealed': shouldReveal && !hasClosed,
    }, className);

    const cssVariables = {
        '--urgent-banner-height': `${bannerHeight}px`,
        '--c-urgent-banner-text': textColor,
        '--c-urgent-banner-background': backgroundColor,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={urgentBannerClassNames}>
            <div ref={contentRef} className="urgent-banner__content-wrapper">
                <Wrapper className="urgent-banner__wrapper">
                    <p className="urgent-banner__description">
                        {text}
                    </p>

                    <Link
                        to={link.href}
                        target={link.target}
                        className="urgent-banner__link"
                    >
                        {link.label}
                    </Link>

                    <IconButton
                        text={trans('common.close')}
                        hideLabel
                        icon="cross"
                        onClick={handleClose}
                        className="urgent-banner__close-button"
                    />
                </Wrapper>
            </div>
        </div>
    );
};
