import { SliceGetter, TypedDispatch } from './store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReduxFetchAction = (...args: any[]) => (dispatch: TypedDispatch, getState: SliceGetter) => Promise<void>;

interface AsyncReduxProperties {
    isLoading: boolean;
    isSuccessful: boolean;
    hasFetched: boolean;
    error: string;
}

export type AsyncReduxState<T = unknown> = Readonly<{
    [P in keyof T]: T[P];
} & AsyncReduxProperties>;

export const initialAsyncReduxState: AsyncReduxState = {
    isLoading: false,
    isSuccessful: false,
    hasFetched: false,
    error: '',
};
