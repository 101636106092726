import { FC, ReactElement, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { Pagination } from '../../compositions';
import { ConnectedBrandProducts, ConnectedBrandRenderer, ConnectedNewsletterCallToAction } from '../../connectors';
import { MOCK_BRANDS } from '../../mock/mock-data';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

import './BrandDetailPage.scss';

interface BrandDetailProps {
    className?: string;
}

export const BrandDetailPage: FC<BrandDetailProps> = ({
    className = '',
}): ReactElement => {
    const { slug } = useParams<{ slug: string }>();
    const [paginationPage, setPaginationPage] = useState<number>(1);

    const mockBrand = MOCK_BRANDS.find(brand => brand.slug === slug);

    if (!mockBrand || !slug) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <Page className={`brand-detail-page ${className}`}>
            <ConnectedBrandRenderer slug={slug} />

            <Wrapper className="brand-detail-page__block">
                <ConnectedBrandProducts slug={slug} />

                <Pagination
                    numberOfPages={30}
                    currentPage={paginationPage}
                    onChange={setPaginationPage}
                    className="brand-detail-page__pagination"
                />
            </Wrapper>

            <ConnectedNewsletterCallToAction
                title="Wekelijks inspiratie in je inbox?"
                description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                textColor="#000000"
                className="brand-detail-page__block"
            />
        </Page>
    );
};
