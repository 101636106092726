/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconMap: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon className="icon__stroke" points="69.1 86.6 30.6 75.2 3.3 85.4 3.3 23.6 30.6 13.4 69.1 24.8 96.8 14.6 96.8 76.4 69.1 86.6" />
        <line className="icon__stroke" x1="30.6" y1="13.4" x2="30.6" y2="75.2" />
        <line className="icon__stroke" x1="69.1" y1="24.8" x2="69.1" y2="86.6" />
    </svg>
));
