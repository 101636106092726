import { FC, ReactElement } from 'react';

import { useToggle } from 'react-use';

import { Product } from '../../entities/Product/Product';
import { useTrans } from '../../hooks';
import { Button } from '../@buttons/Button/Button';
import { WishlistButton } from '../@buttons/WishlistButton/WishlistButton';

import './ProductCallToAction.scss';

interface ProductCallToActionProps {
    product: Product;
    className?: string;
}

export const ProductCallToAction: FC<ProductCallToActionProps> = ({
    product,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [wishlistState, toggleWishlistState] = useToggle(false);

    return (
        <div className={`product-call-to-action ${className}`}>
            <Button
                disabled={product.stock === 0}
                text={product.stock > 0 ? trans('common.addToCart') : trans('compositions.productCard.soldOut')}
                className="product-call-to-action__cart-button"
            />

            <WishlistButton
                text={trans('common.addToWishlist')}
                isActive={wishlistState}
                onClick={toggleWishlistState}
                className="product-call-to-action__wishlist-button"
            />
        </div>
    );
};
