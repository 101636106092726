/* eslint-disable max-len */
import { Product } from '../../entities/Product/Product';
import { MOCK_BRANDS } from './brand';
import {
    MOCK_PRODUCT_IMAGE_1,
    MOCK_PRODUCT_IMAGE_2,
    MOCK_PRODUCT_IMAGE_3,
    MOCK_PRODUCT_IMAGE_4,
    MOCK_PRODUCT_IMAGE_5,
    MOCK_PRODUCT_IMAGE_6,
    MOCK_PRODUCT_IMAGE_7,
    MOCK_VIDEO,
} from './media';

export const MOCK_PRODUCT_1 = {
    id: '1',
    slug: 'bijzettafel-mala-glossy',
    name: 'Bijzettafel Mala glossy',
    brand: MOCK_BRANDS[0],
    mediaItems: [
        { id: '1', image: MOCK_PRODUCT_IMAGE_1 },
        { id: '2', image: MOCK_PRODUCT_IMAGE_2 },
        { id: '3', image: MOCK_PRODUCT_IMAGE_3 },
        { id: '4', video: MOCK_VIDEO },
        { id: '5', image: MOCK_PRODUCT_IMAGE_5 },
        { id: '6', image: MOCK_PRODUCT_IMAGE_6 },
    ],
    mainImage: MOCK_PRODUCT_IMAGE_1,
    price: 125.00,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    attributes: [
        { label: 'Lichtbron', value: '2,2 Watt LED CRI &gt; 80 (inbegrepen)' },
        { label: 'Lichtkleur', value: '2200-2700-3000 K (warm wit)' },
        { label: 'Dimbaar', value: 'Ja, door middel van touch' },
        { label: 'Batterij', value: '9 uur op vol vermogen en minimaal 12 uur gedimd' },
        { label: 'Oplaadtijd', value: '4,5 uur' },
        { label: 'Oplaadstation', value: 'inbegrepen' },
        { value: 'IP 65 waterdicht' },
    ],
    specs: [
        { label: 'Productnummer', value: '123456' },
        { label: 'Extra verlichting', value: 'Inclusief lichtbron, dimbaar' },
        { label: 'Geschikt voor buiten?', value: 'Ja' },
        { label: 'Herkomst', value: 'Italië' },
        { label: 'Kleur', value: 'Roest' },
        { label: 'Materiaal', value: 'Aluminium' },
    ],
    dimensions: {
        width: '67 cm',
        height: '200 cm',
        diameter: '67 cm',
    },
    colors: [],
    variants: 2,
    stock: 24,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCT_2 = {
    id: '2',
    slug: 'kussen-maysa',
    name: 'Kussen Maysa',
    brand: MOCK_BRANDS[1],
    mediaItems: [
        { id: '2', image: MOCK_PRODUCT_IMAGE_2 },
    ],
    mainImage: MOCK_PRODUCT_IMAGE_2,
    colors: [
        '#FFA500',
        '#8f4903',
        '#d1301b',
        '#d5db25',
        '#baac9b',
        '#d1301b',
        '#FFA500',
        '#8f4903',
        '#d1301b',
    ],
    price: 24.95,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    variants: 1,
    stock: 59,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCT_3 = {
    id: '3',
    slug: 'salontafel-lilla',
    name: 'Salontafel Lilla',
    brand: MOCK_BRANDS[2],
    mediaItems: [
        { id: '3', image: MOCK_PRODUCT_IMAGE_3 },
    ],
    mainImage: MOCK_PRODUCT_IMAGE_3,
    colors: [],
    price: 125.00,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    variants: 1,
    stock: 0,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCT_4 = {
    id: '4',
    slug: 'vaas-loa-12x26x31cm',
    name: 'Vaas Loa 12x26x31cm',
    brand: MOCK_BRANDS[3],
    mediaItems: [
        { id: '4', image: MOCK_PRODUCT_IMAGE_4 },
    ],
    mainImage: MOCK_PRODUCT_IMAGE_4,
    colors: [],
    price: 29.95,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    variants: 1,
    stock: 42,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCT_5 = {
    id: '5',
    slug: 'bijzettafel-naomi',
    name: 'Bijzettafel Naomi',
    brand: MOCK_BRANDS[4],
    mediaItems: [
        { id: '5', image: MOCK_PRODUCT_IMAGE_5 },
    ],
    mainImage: MOCK_PRODUCT_IMAGE_5,
    colors: [],
    price: 135.00,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    variants: 2,
    stock: 24,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCT_6 = {
    id: '6',
    slug: 'poef-ollie',
    name: 'Poef Ollie',
    brand: MOCK_BRANDS[0],
    mediaItems: [
        { id: '6', image: MOCK_PRODUCT_IMAGE_6 },
    ],

    mainImage: MOCK_PRODUCT_IMAGE_6,
    colors: [],
    price: 89.95,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    variants: 1,
    stock: 14,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCT_7 = {
    id: '7',
    slug: '3-zitsbank-stand-alon-fat-joe-royal',
    name: '3-zitsbank stand alon Fat Joe royal',
    brand: MOCK_BRANDS[1],
    mediaItems: [
        { id: '7', image: MOCK_PRODUCT_IMAGE_7 },
    ],
    mainImage: MOCK_PRODUCT_IMAGE_7,
    colors: [],
    price: 1995.00,
    description: `
        <p>Deze tafellamp wordt gemaakt door DE Italiaanse fabrikant op dit gebied: Zafferano.</p>
        <p>De lamp kan zowel binnen als buiten worden toegepast en is zo een sfeermaker in huis, maar ook in de tuin, op de camping, op het balkon en in de boot. Door de handige dimfunctie creëer je altijd de sfeer die past bij het moment.</p>
        <p>Je kan de tafellamp Ofelia micro ook in Loods5 Amersfoort bekijken. Hier vind je de kleuren zachtgroen, donkergrijs. Online is de tafellamp Ofelia micro ook verkrijgbaar in de kleuren wit, zand en corten (roest).</p>    
    `,
    variants: 1,
    stock: 3,
    isNew: true,
    isOnlineOnly: true,
    isSale: true,
};

export const MOCK_PRODUCTS: Product[] = [
    MOCK_PRODUCT_1,
    MOCK_PRODUCT_2,
    MOCK_PRODUCT_3,
];
