/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconWayfindingCta: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={`icon__stroke ${pathClassName}`} d="M22.1,57.1v21.3h-12.5v-34.2s56.2,0,56.2,0h1l-.7-.7-21.5-21.5,8.8-8.8,37.1,37.1-37.1,37.1-8.8-8.8,21.1-21.1.7-.7H22.1v.4Z" />
    </svg>
));
