import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedCustomerServicePageRenderer } from '../../../connectors';

import './CustomerServicePage.scss';

export const CustomerServicePage: FC = (): ReactElement => (
    <Page className="customer-service-page">
        <ConnectedCustomerServicePageRenderer />
    </Page>
);
