import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Wrapper } from '../../../components';
import { VideoTheatre } from '../../../compositions';
import { MediaGridInterface } from '../../../entities/@blocks/MediaGrid/MediaGrid';

import './MediaGrid.scss';

interface MediaGridProps extends MediaGridInterface {
    className?: string;
}

export const MediaGrid: FC<MediaGridProps> = ({
    mediaItems,
    className = '',
}): ReactElement => {
    const mediaGridClassNames = classNames('media-grid', {
        [`media-grid--layout-${mediaItems.length}`]: mediaItems.length,
    }, className);

    return (
        <section className={mediaGridClassNames}>
            <Wrapper className="media-grid__wrapper">
                {mediaItems.map(mediaItem => (
                    <div key={mediaItem.id} className="media-grid__media-wrapper">
                        {mediaItem.image && !mediaItem.video && (
                            <Picture
                                {...mediaItem.image}
                                className="media-grid__picture"
                                imageClassName="media-grid__image"
                            />
                        )}

                        {mediaItem.video && (
                            <VideoTheatre
                                {...mediaItem.video}
                                playsInline={mediaItem.videoPlaysInline}
                                poster={mediaItem.image}
                                className="media-grid__video-theatre"
                            />
                        )}
                    </div>
                ))}
            </Wrapper>
        </section>
    );
};
