import { FC, ReactElement } from 'react';

import { FaqItemInterface } from '../../../entities/@blocks/Faq/Faq';
import { Block } from '../../../entities/Block/Block';
import { BlockRenderer, Faq } from '../..';

import './CustomerServiceFaqPageRenderer.scss';

interface CustomerServiceFaqPageRendererProps {
    isLoadingFaqItems: boolean;
    isLoadingBlocks: boolean;
    faqItems: FaqItemInterface[];
    blocks: Block[];
    className?: string;
}

export const CustomerServiceFaqPageRenderer: FC<CustomerServiceFaqPageRendererProps> = ({
    isLoadingFaqItems,
    isLoadingBlocks,
    faqItems,
    blocks,
    className = '',
}): ReactElement => (
    <div className={`customer-service-faq-page-renderer ${className}`}>
        <Faq
            isLoading={isLoadingFaqItems}
            faqItems={faqItems}
        />

        <BlockRenderer
            isLoading={isLoadingBlocks}
            blocks={blocks}
            className="customer-service-faq-page-renderer__block-renderer"
        />
    </div>
);
