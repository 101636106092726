import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { LogicalAlignment } from '../../entities/Alignment/Alignment';

import './SplitWrapper.scss';

interface SplitWrapperProps extends PropsWithChildren {
    alignment: LogicalAlignment;
    className?: string;
}

export const SplitWrapper: FC<SplitWrapperProps> = ({
    alignment,
    className = '',
    children,
}): ReactElement => {
    const splitWrapperClassNames = classNames('split-wrapper', {
        [`split-wrapper--is-${alignment}`]: alignment,
    }, className);

    return (
        <div className={splitWrapperClassNames}>
            {children}
        </div>
    );
};
