/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconTikTok: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 14 24" className={`icon ${className}`}>
        <path
            d="M14 10.5625H13.9688C12.5312 10.5625 11.2188 10.125 10.1562 9.34375V14.9375C10.1562 17.75 7.875 20 5.0625 20C2.25 20 0 17.75 0 14.9375C0 12.125 2.25 9.84375 5.0625 9.84375C5.3125 9.84375 5.53125 9.875 5.78125 9.90625V12.7188C5.53125 12.625 5.3125 12.5938 5.0625 12.5938C3.78125 12.5938 2.71875 13.6562 2.71875 14.9375C2.71875 16.2188 3.78125 17.2812 5.0625 17.2812C6.34375 17.2812 7.40625 16.2188 7.40625 14.9375V4H10.1562C10.1562 4.03125 10.1562 4.03125 10.1562 4.0625C10.1562 4.28125 10.1562 4.5 10.2188 4.71875C10.4062 5.75 11.0312 6.65625 11.9062 7.21875C12.5 7.625 13.2188 7.84375 13.9688 7.84375C13.9688 7.84375 13.9688 7.84375 14 7.84375V10.5625Z"
        />
    </svg>
));
