import { FC, ReactElement } from 'react';

import { SearchableOption } from '../../../../../entities/Form/Form';
import { generateQueryHighlight } from '../../../../../helpers/string';
import { Button } from '../../../../@buttons/Button/Button';

import './SearchableValueInputOption.scss';

interface SearchableValueInputOptionProps {
    option: SearchableOption;
    query?: string;
    onSelect: (fieldOfStudy: SearchableOption) => void;
    className?: string;
}

export const SearchableValueInputOption: FC<SearchableValueInputOptionProps> = ({
    option,
    query,
    onSelect,
    className = '',
}): ReactElement => {
    const [labelStart, labelHighlight, labelEnd] = generateQueryHighlight(option.label, query);

    const handleClick = (): void => onSelect(option);

    return (
        <li className={`searchable-value-input-option ${className}`}>
            <Button
                text={option.label}
                onClick={handleClick}
                className="searchable-value-input-option__button"
            >
                {query ? (
                    <p className="searchable-value-input-option__label">
                        {labelStart}
                        <span className="searchable-value-input-option__query">
                            {labelHighlight}
                        </span>
                        {labelEnd}
                    </p>
                ) : (
                    <p className="searchable-value-input-option__label">
                        {option.label}
                    </p>
                )}

                {option.secondaryLabel && (
                    <span className="searchable-value-input-option__secondary-label">
                        {option.secondaryLabel}
                    </span>
                )}
            </Button>
        </li>
    );
};
