import { IconName } from '../../components';
import { DateResource } from '../Date/Date';

export const isSilentLoop = {
    autoPlay: true,
    muted: true,
    loop: true,
    playsInline: true,
};

export interface MediaFormatResource {
    hash: string;
    name: string;
    mime: string;
    ext?: string;
    size: number;
    width?: number;
    height?: number;
    url: string;
}

export enum ImageFormatSize {
    original = 'original',
    large = 'large',
    medium = 'medium',
    small = 'small',
    thumbnail = 'thumbnail',
}

export interface MediaResource {
    name: string;
    alternativeText?: string;
    caption?: string;
    width?: number;
    height?: number;
    formats?: Partial<Record<ImageFormatSize, MediaFormatResource>>;
    hash: string;
    ext?: string;
    mime: string;
    size: number;
    url: string;
    previewUrl?: string;
    provider_metadata?: unknown;
    related?: unknown;
    createdAt?: DateResource;
    updatedAt?: DateResource;
}

export enum AutoplayMediaOption {
    none = 'none',
    autoPlay = 'autoplay',
    theatrePlay = 'theater play',
}

export interface MediaItemResource {
    id: number;
    playOption: AutoplayMediaOption;
    media: MediaResource;
}

export interface ImageFormat {
    srcSet: string;
    type: string;
    media: string;
}

export interface Image {
    src: string;
    formats: Partial<Record<ImageFormatSize, ImageFormat>>;
    alt: string;
}

export interface Video {
    src: string;
    type: string;
}

export interface MediaItem {
    id: string;
    image?: Image;
    video?: Video;
    videoPlaysInline?: boolean;
}

export interface IconResource {
    id: number;
    icon: IconName;
}
