import { useEffect } from 'react';

import { setHasServerData } from '../redux/app/appSlice';
import { useTypedDispatch, useTypedSelector } from '../redux/store';

export const useClientEffect = (callback: () => void, deps: unknown[] = []): void => {
    const dispatch = useTypedDispatch();
    const hasServerData = useTypedSelector(state => state.appSlice.hasServerData);

    useEffect((): void => {
        if (hasServerData) {
            dispatch(setHasServerData(false));
            return;
        }

        callback();
    }, deps);
};
