import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../../components';
import { BrandLogo } from '../../../../../entities/@blocks/BrandCarousel/BrandCarousel';
import { useTrans } from '../../../../../hooks';

import './BrandCarouselItems.scss';

interface BrandCarouselItemsProps {
    logos: BrandLogo[];
    className?: string;
}

export const BrandCarouselItems: FC<BrandCarouselItemsProps> = ({
    logos,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`brand-carousel-items ${className}`}>
            {logos.map(logo => (
                <Link
                    key={logo.id}
                    to={logo.link.href}
                    target={logo.link.target}
                    title={logo.name}
                    className="brand-carousel-items__link"
                >
                    <Picture
                        src={logo.image.src}
                        alt={trans('containers.brandCarousel.logoAlt', { name: logo.name })}
                        className="brand-carousel-items__picture"
                    />
                </Link>
            ))}
        </div>
    );
};
