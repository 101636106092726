import { FC, ReactElement } from 'react';

import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { Link } from '../../entities/Link/Link';
import { LinkIconButton } from '..';

import './SectionFooter.scss';

interface SectionFooterProps {
    link: Link;
    className?: string;
}

export const SectionFooter: FC<SectionFooterProps> = ({
    link,
    className = '',
}): ReactElement => (
    <footer className={`section-footer ${className}`}>
        <div className="section-footer__divider" />

        <LinkIconButton
            hasAnimation
            icon="arrow-right"
            iconPos={HorizontalAlignment.right}
            to={link.href}
            text={link.label}
            className="section-footer__link"
        />
    </footer>
);
