import { FC, ReactElement } from 'react';

import { convertNumberToCurrency } from '../../helpers/number';

import './Price.scss';

export interface PriceProps {
    amount: number;
    includeCurrencySymbol?: boolean;
    className?: string;
}

export const Price: FC<PriceProps> = ({
    amount,
    includeCurrencySymbol = false,
    className = '',
}): ReactElement => {
    const formattedAmount = convertNumberToCurrency(
        amount,
        includeCurrencySymbol,
        'nl-NL',
    );

    return (
        <p className={`price ${className}`}>
            {formattedAmount}
        </p>
    );
};
